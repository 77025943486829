import { Language } from '../../../common/types'
import en from './locales/en.json'
import nl from './locales/nl.json'

const translations = {
  [Language.en]: {
    NFTsWidget: en,
  },
  [Language.nl]: {
    NFTsWidget: nl,
  },
}

export default translations
