import { Button } from '@stakenow/design-system'
import React, { FC } from 'react'

import { DISCORD_URL } from '../../../common/constants/constants'
import { useTranslation } from '../../../common/i18n'
import { Size } from '../../../common/types'

const Join: FC = () => {
  const { t } = useTranslation('Join')

  return (
    <section className="py-20 px-6 md:px-8 bg-white dark:bg-gray-800 relative z-10">
      <div className="container mx-auto text-center">
        <div className="mx-auto md:w-3/4">
          <h1 className="text-4xl md:text-6xl pb-8 font-bold">{t('[Heading] join our discord')}</h1>
          <p className="leading-loose tracking-wide text-lg font-light text-gray-500 dark:text-gray-400 sm:mt-5 sm:text-xl sm:mx-auto mb-8 md:text-2xl">
            {t('[Content] join our discord')}
          </p>
          <Button size={Size.large} onClick={() => window.open(DISCORD_URL, '_blank')}>
            {t('[Button] join our discord')}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Join
