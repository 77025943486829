import router, { NextRouter } from 'next/router'
import { assoc, dissoc, evolve, has, ifElse, prop } from 'ramda'

import { Obj } from '../types'
import { formatQueryString } from '../utils'

export const routerPush =
  (nextRouter: NextRouter) =>
  (url: string, as: string | undefined = undefined) => {
    nextRouter.push(url, as, { shallow: true })
  }

export const goToUrl = routerPush(router)

export const routerPushSearchParams =
  (nextRouter: NextRouter) => (pathname: string, searchQuery: (x: Obj) => string) => {
    nextRouter.push(
      {
        pathname,
        search: formatQueryString(searchQuery(prop('query')(nextRouter))),
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

export const goToSearchUrl = routerPushSearchParams(router)

export const addToSearchQuery = (attribute: string, value: string | number | string[]) =>
  ifElse(has(attribute), evolve({ [attribute]: () => value }), assoc(attribute, value))

export const removeFromSearchQuery = (attribute: string) => dissoc(attribute)
