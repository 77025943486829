import { useReactiveVar } from '@apollo/client'
import { Card, LoadingIndicator } from '@stakenow/design-system'
import { equals, isEmpty, map, path, prop } from 'ramda'
import React, { FC } from 'react'

import { makeAccountVar } from '../../../common/graphql'
import { useTranslation } from '../../../common/i18n'
import { useInfiniteLoading } from '../../../common/infiniteLoading'
import { Operation, OperationType, Size } from '../../../common/types'
import { Operation as OperationRow } from '../Operation'
import { GET_OPERATIONS } from './OperationsList.gql'

const OperationsOverview: FC = () => {
  const { pkh } = useReactiveVar(makeAccountVar)
  const { items, isLoading, ref } = useInfiniteLoading(GET_OPERATIONS)
  const { t } = useTranslation('OperationsList')

  const operations =
    isEmpty(items) && !isLoading ? (
      <div className="col-span-full text-center border border-gray-200 dark:border-gray-600 p-5">
        <p>{t('[Heading] no operations found')}</p>
      </div>
    ) : (
      <>
        <ul>
          {map((operation: Operation) =>
            equals(OperationType.transaction)(path(['operations', 0, 'type'])(operation)) ? (
              <li className="border-b border-gray-200 dark:border-gray-800" key={prop('hash')(operation)}>
                <OperationRow pkh={pkh as string} operation={operation} />
              </li>
            ) : null,
          )(items)}
        </ul>
      </>
    )

  return (
    <Card>
      <div className="flex justify-between">
        <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[Heading] operations')}</h4>
        {/* <button type="button" className="text-white rounded-full bg-orange text-xs px-5 py-1" onClick={() => {}}>
          {t('[Button] download tax report')}
        </button> */}
      </div>
      {operations}
      {isLoading ? <LoadingIndicator size={Size.large} /> : <div ref={ref} />}
    </Card>
  )
}

export default OperationsOverview
