import {
  Card,
  GridRow,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  formatUSD,
} from '@stakenow/design-system'
import { isEmpty, isNil, map } from 'ramda'
import React, { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'
import { Columns } from '../../../../common/types'
import PoolOverviewData from './PoolOverview.data.json'
import { ArrowDownIcon } from './assets/ArrowDownIcon'

const PoolOverview: FC = () => {
  const { poolList, totals } = PoolOverviewData
  const { invested, today, pools } = totals
  const { t } = useTranslation('PoolOverview')

  const Pool = (thumbnail: string, name: string, value: number, invested: number, tokens: number, pools: any[]) => (
    <TableRow key={name}>
      <TableCell>
        <div className="flex items-center">
          <img src={thumbnail} alt={name} className="w-8 h-8" />
          <p className="pl-4 font-bold">{name}</p>
        </div>
      </TableCell>
      <TableCell extraClasses="invisible md:visible hidden md:table-cell">{formatUSD(invested)}</TableCell>
      <TableCell extraClasses="invisible sm:visible hidden sm:table-cell">{formatUSD(value)}</TableCell>
      <TableCell extraClasses="invisible lg:visible hidden lg:table-cell">{formatUSD(tokens)}</TableCell>
      <TableCell extraClasses="text-right pr-4">
        {isNil(pools) ? (
          <p className="text-orange text-xs underline">{t('[PoolOverview] view')}</p>
        ) : (
          <div className="block w-6 h-6 text-gray-600 dark:text-white ml-auto">{ArrowDownIcon}</div>
        )}
      </TableCell>
    </TableRow>
  )

  const Pools = (
    <>
      <TableHeader>
        <TableRow>
          <TableCell extraClasses="pb-0">
            <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[PoolOverview] pools')}</h4>
          </TableCell>
          <TableCell extraClasses="pb-0 invisible md:visible hidden md:table-cell">
            <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[PoolOverview] invested')}</h4>
          </TableCell>
          <TableCell extraClasses="pb-0 invisible sm:visible hidden sm:table-cell">
            <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[PoolOverview] current value')}</h4>
          </TableCell>
          <TableCell colspan={2} extraClasses="pb-0 invisible lg:visible hidden lg:table-cell">
            <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[PoolOverview] value pool')}</h4>
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isEmpty(poolList) ? (
          <TableRow>
            <TableCell colspan={5}>{t('[PoolOverview] no pools found')}</TableCell>
          </TableRow>
        ) : (
          map(
            ({
              thumbnail,
              name,
              value,
              invested,
              tokens,
              pools,
            }: {
              thumbnail: string
              name: string
              value: number
              invested: number
              tokens: number
              pools: any[]
            }) => Pool(thumbnail, name, value, invested, tokens, pools),
          )(poolList as any)
        )}
      </TableBody>
    </>
  )

  return (
    <GridRow columns={Columns.three}>
      <div className="md:col-span-1">
        <Card>
          <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[PoolOverview] invested')}</h4>
          <div className="flex items-end justify-between">
            <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(invested)}</h2>
          </div>
        </Card>
      </div>
      <div className="md:col-span-1">
        <Card>
          <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[PoolOverview] current value')}</h4>
          <div className="flex items-end justify-between">
            <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(today)}</h2>
          </div>
        </Card>
      </div>
      <div className="md:col-span-1">
        <Card>
          <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[PoolOverview] value pool')}</h4>
          <div className="flex items-end justify-between">
            <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(pools)}</h2>
          </div>
        </Card>
      </div>
      <div className="col-span-1 sm:col-span-2 md:col-span-3">
        <Card>
          <Heading importance="h2">{t('[PoolOverview] liquidity pools')}</Heading>
          <Table>{Pools}</Table>
        </Card>
      </div>
    </GridRow>
  )
}

export default PoolOverview
