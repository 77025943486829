import gql from 'graphql-tag'

export const SET_DELEGATE = gql`
  query setDelegate($pkh: String!) {
    getAccount(pkh: $pkh) {
      delegate {
        alias
        address
      }
    }
  }
`
