import { DAppClient, RequestSignPayloadInput, TezosOperationType } from '@airgap/beacon-sdk'
import { useState } from 'react'

import { dAppClient, network } from './beaconClient'

export const beaconWallet = (client: DAppClient) => () => {
  const [error, setError] = useState<string>('')

  const connect = () =>
    client.requestPermissions({
      network,
    })

  const disconnect = () => client.clearActiveAccount()

  const delegate = (pkh: string, onSuccess: () => void) =>
    client
      .requestOperation({
        operationDetails: [
          {
            kind: TezosOperationType.DELEGATION,
            delegate: pkh,
          },
        ],
      })
      .then(() => onSuccess())
      .catch(({ message }) => setError(message))

  const requestSignPayload = (payload: RequestSignPayloadInput) => client.requestSignPayload(payload)

  return { error, delegate, disconnect, connect, requestSignPayload }
}

export const useBeaconWallet = beaconWallet(dAppClient)
