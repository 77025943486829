import { useQuery } from '@apollo/client'
import { GridRow, LoadingIndicator } from '@stakenow/design-system'
import { isNil, pathOr } from 'ramda'
import React, { FC } from 'react'

import { useTranslation } from '../../../common/i18n'
// import { Modal } from '../../../common/modal'
// import { useModal } from '../../Modal'
import { Columns } from '../../../common/types'
import { GET_VAULT } from './Vault.gql'
import { getDelegator } from './Vault.utils'
import {
  CardBorrow,
  CardCollateral,
  CardDelegate,
  CardDetails,
  CardNetValue,
  CardPlatform,
  CardUtilization,
  EmptyVault,
  OverviewButton,
} from './components'

interface VaultProps {
  platformRouter: string
  ovenAddress: string
}

const Vault: FC<VaultProps> = ({ platformRouter = 'platfrom', ovenAddress = 'PKH' }) => {
  const { loading: isLoading, data } = useQuery(GET_VAULT, { variables: { ovenAddress } })
  const {
    platform,
    apy,
    baker,
    target,
    symbol,
    netValue,
    utilization,
    stabilityFees,
    liquidationPrice,
    borrowedTokens,
    borrowedTokensValue,
    balance,
    collateralValue,
    minimumCollateralRatio,
    collateralRatio,
    quote,
  } = pathOr({}, ['getVault'])(data)
  const delegator = getDelegator(baker)(data)
  const { t } = useTranslation('Vault')

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <OverviewButton text={t('[Button] return to overview')} />
      <div className="flex items-end mb-6">
        <h2 className="text-2xl lg:text-2xl xl:text-4xl font-bold">{platform}</h2>
        {/* <button
          type="button"
          className="text-orange text-xs underline tracking-wide pb-1 ml-4"
          onClick={() => openModal({ modal: Modal.default, data: {} })}
        >
          {t('[Button] open vault')}
        </button> */}
      </div>
      {isNil(data) ? (
        <EmptyVault />
      ) : (
        <>
          <div className="mb-6">
            <GridRow columns={Columns.three}>
              <CardPlatform ovenAddress={ovenAddress} />
              <CardNetValue netValue={netValue} />
              <CardDelegate delegator={delegator} />
            </GridRow>
          </div>
          <GridRow columns={Columns.three}>
            <CardDetails
              utilization={utilization}
              apy={apy}
              stabilityFees={stabilityFees}
              symbol={symbol}
              liquidationPrice={liquidationPrice}
              quote={quote}
            />
            <div className="sm:col-span-2">
              <CardUtilization
                utilization={utilization}
                collateralRatio={collateralRatio}
                minimumCollateralRatio={minimumCollateralRatio}
                target={target}
              />
              <div className="grid sm:grid-cols-2 mt-6 gap-6">
                <CardBorrow borrowedTokens={borrowedTokens} symbol={symbol} borrowedTokensValue={borrowedTokensValue} />
                <CardCollateral balance={balance} collateralValue={collateralValue} />
              </div>
            </div>
          </GridRow>
        </>
      )}
    </>
  )
}

export default Vault
