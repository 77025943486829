import { always, cond, equals, flip, gt, head, isNil, pipe, prop } from 'ramda'

import { formatArtistName } from '../../../common/utils'

export const getNFTArtists = (creators: string) =>
  !isNil(JSON.parse(creators))
    ? pipe(
        JSON.parse,
        prop('length'),
        cond([
          [equals(0), always('…')],
          [equals(1), always(pipe(JSON.parse, head)(creators))],
          [flip(gt)(1), (amount: number) => `${amount} artists`],
        ]),
        formatArtistName,
      )(creators)
    : '…'
