import {
  Button,
  Card,
  GridRow,
  Heading,
  HeadingWithTooltip,
  PoolTableRow,
  Symbol,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  Tooltip,
  UpDown,
  calculateProfitPercentage,
  formatPercentage,
  formatUSD,
} from '@stakenow/design-system'
import React, { FC } from 'react'

import { useAnalytics } from '../../common/analytics'
import { ACCESS_NFTS_URL } from '../../common/constants'
import { useTranslation } from '../../common/i18n'
import { Columns, Size } from '../../common/types'
import { noop } from '../../common/utils'

const NoAccess: FC = () => {
  const { t } = useTranslation('NoAccess')
  const { trackGoal } = useAnalytics()

  const onGetNFTButtonClick = () => {
    process.env.NEXT_PUBLIC_FATHOM_GTO_EVENT_ID && trackGoal(process.env.NEXT_PUBLIC_FATHOM_GTO_EVENT_ID, 0)
    window.open(ACCESS_NFTS_URL, '_blank')
  }

  const PremiumDemoContent: FC = () => (
    <GridRow columns={Columns.four}>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] tooltip')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] heading')}</Heading>}
        />
        <div className="flex items-end gap-x-4 gap-y-1 flex-wrap">
          <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(1000)}</h2>
          <UpDown value={calculateProfitPercentage(500)(1000)} />
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] tooltip')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] heading')}</Heading>}
        />
        <div className="flex items-end gap-x-4 gap-y-1 flex-wrap">
          <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(750)}</h2>
          <UpDown value={calculateProfitPercentage(500)(750)} />
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] tooltip')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] heading')}</Heading>}
        />
        <div>
          <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(500)}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] tooltip')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] heading')}</Heading>}
        />
        <div>
          <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatPercentage(0.295)}</h2>
        </div>
      </Card>
      <div className="col-span-full">
        <Card>
          <div className="flex items-center gap-x-4 pb-4">
            <Symbol thumbnailUri={null} name={t('[Heading] premium feature')} size={Size.small} />
            <Heading importance="h3">{t('[Heading] premium feature')}</Heading>
          </div>
          <div className="-mx-3">
            <Table>
              <TableHeader>
                <tr className="hidden md:table-row">
                  <TableCell extraClasses="pb-2 pl-3 w-4/12">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] heading')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-1/12 invisible md:visible hidden md:table-cell text-right">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] heading')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-2/12 invisible md:visible hidden md:table-cell text-right">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] heading')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-1/12 invisible sm:visible hidden sm:table-cell">{` `}</TableCell>
                  <TableCell extraClasses="pb-2 w-1/12 invisible sm:visible hidden sm:table-cell text-right">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] heading')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-1/12 invisible sm:visible hidden sm:table-cell">{` `}</TableCell>
                  <TableCell extraClasses="pb-2 w-1/12 invisible lg:visible hidden lg:table-cell text-right">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] heading')}</h4>
                  </TableCell>
                </tr>
              </TableHeader>
              <TableBody>
                <PoolTableRow
                  key={1}
                  onClick={noop}
                  thumbnail={null}
                  pair="ASSET / ASSET"
                  subsidized
                  apy={0.295}
                  currentValue={1000}
                  hodlValue={750}
                  providedValue={500}
                />
                <PoolTableRow
                  key={2}
                  onClick={noop}
                  thumbnail={null}
                  pair="A / B"
                  subsidized={false}
                  apy={0.295}
                  currentValue={800}
                  hodlValue={350}
                  providedValue={500}
                />
                <PoolTableRow
                  key={3}
                  onClick={noop}
                  thumbnail={null}
                  pair="Left / Right"
                  subsidized={false}
                  apy={0.295}
                  currentValue={400}
                  hodlValue={750}
                  providedValue={500}
                />
              </TableBody>
            </Table>
          </div>
        </Card>
      </div>
    </GridRow>
  )

  return (
    <div className="relative">
      <div className="p-5">
        <PremiumDemoContent />
      </div>
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-white dark:bg-gray-800 backdrop-filter dark:bg-opacity-60 bg-opacity-60 backdrop-blur-md flex justify-center items-center">
        <div className="max-w-md bg-white dark:bg-gray-900  px-5 py-20 rounded-2xl text-center shadow-xl flex flex-col border border-gray-100 dark:border-gray-900">
          <Heading importance="h2">{t('[Heading] you discovered a premium feature')}</Heading>
          <div className="px-5 mt-0">
            <p>{t('[Heading] to use this feature you need to have premium access nft')}</p>
            <Button onClick={() => onGetNFTButtonClick()} extraClasses="mt-8">
              {t('[Button] get it here')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoAccess
