import { includes, propEq } from 'ramda'
import { useEffect, useState } from 'react'

import { MimeType } from '../types'

export const useMimeType = (uri: string) => {
  const [mimeType, setMimeType] = useState<MimeType>('' as MimeType)

  useEffect(() => {
    const fetchMimeType = async () => {
      try {
        const response = await fetch(uri, { method: 'HEAD', cache: 'no-cache' })
        const contentType = response.headers.get('content-type') as MimeType

        if (
          !includes(contentType, [undefined, 'text/plain', 'text/plain; charset=utf-8']) &&
          propEq('status', 200)(response)
        ) {
          setMimeType(contentType)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchMimeType()
  }, [])

  return { mimeType }
}
