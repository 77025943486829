import { useState } from 'react'

import { FilterAttribute, FilterOperator, Obj } from '../types'
import {
  addAttributeFilter,
  parseAttributeFilters,
  removeAttributeFilter,
  updateAttributeFilter,
} from './filters.utils'

export const useFilters = () => {
  const [filters, updateFilters] = useState([] as Record<string, any>[])

  const addFilter = (attribute: FilterAttribute, operator: FilterOperator, value: string | number) =>
    updateFilters(addAttributeFilter(attribute, operator, value))

  const removeFilter = (
    attribute: FilterAttribute,
    operator: FilterOperator,
    value: string | number | { min: number; max: number },
  ) => updateFilters(removeAttributeFilter(attribute, operator, value))

  const updateFilter = (
    attribute: FilterAttribute,
    operator: FilterOperator,
    value: string | number | { min: number; max: number },
  ) => updateFilters(updateAttributeFilter(attribute, operator, value))

  const parseFilters = (query: any) => parseAttributeFilters(addFilter)(query)

  return { filters, addFilter, removeFilter, updateFilter, parseFilters }
}
