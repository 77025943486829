import { useQuery } from '@apollo/client'
import { Card, Heading, LoadingIndicator, formatUSD } from '@stakenow/design-system'
import Router from 'next/router'
import { equals, forEach, pathOr, prop } from 'ramda'
import React, { FC } from 'react'

import { ROUTES } from '../../../common/constants'
import { useTranslation } from '../../../common/i18n'
import { GET_NFT_WIDGET } from './Widget.gql'

const NFTWidget: FC = () => {
  const { loading, data, error, refetch } = useQuery(GET_NFT_WIDGET)
  const { quantity, estimatedUSDValue } = pathOr({}, ['getNFTStatistics', 'summary'])(data)
  const { t } = useTranslation('NFTsWidget')

  if (error) {
    forEach(
      (message: string[]) => equals(prop('errorType')(message))('ExecutionTimeout') && refetch({ refetch: true }),
    )(prop('graphQLErrors')(error))
  }

  return quantity ? (
    <Card>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="flex justify-between items-end pb-2">
            <Heading importance="h4">{t('[Heading] nfts')}</Heading>
            <button
              type="button"
              className="text-orange text-xs underline"
              onClick={() => Router.push(ROUTES.DASHBOARD.NFTS_STATISTICS)}
            >
              {t('[Button] view all')}
            </button>
          </div>
          <div className="grid grid-cols-2 mt-2 gap-6">
            <div>
              <h4 className="text-gray-400 dark:text-gray-500 text-xs pb-2 leading-none tracking-wide">
                {t('[Heading] estimated value')}
              </h4>
              <h2 className="text-3xl font-bold">{formatUSD(estimatedUSDValue)}</h2>
            </div>
            <div>
              <h4 className="text-gray-400 dark:text-gray-500 text-xs pb-2 leading-none tracking-wide">
                {t('[Heading] amount of nfts')}
              </h4>
              <div className="flex items-center gap-x-4">
                <h2 className="text-3xl font-bold">{quantity}</h2>
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  ) : null
}

export default NFTWidget
