import gql from 'graphql-tag'

export const GET_BAKER_OVERVIEW = gql`
  query getBakerOverview($pkh: String!) {
    getActiveBakers {
      address
      estimatedRoi
      fee
      freeSpace
      logo
      name
    }
    getAccount(pkh: $pkh) {
      delegate {
        alias
        address
      }
      delegationTime
      balance {
        xtz
      }
    }
  }
`
