import gql from 'graphql-tag'

export const GET_POOLS_WIDGET = gql`
  query getPools($pkh: String!) {
    getPools(pkh: $pkh) {
      summary {
        currentValue
        providedValue
        hodlValue
        weightedAverageApy
      }
    }
  }
`
