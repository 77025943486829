import { AccountInfo, RequestSignPayloadInput, SignPayloadResponse } from '@airgap/beacon-sdk'
import { ReactiveVar } from '@apollo/client'
import { createMessagePayload } from '@stakenow/siwt'
import type { createMessagePayload as CreateMessagePayload } from '@stakenow/siwt'
import { pathOr, propOr } from 'ramda'
import { useState } from 'react'

import { useAnalytics } from '../analytics'
import { useBeaconWallet } from '../beaconWallet'
import { ROUTES } from '../constants'
import { graphqlClient, makeAccountVar } from '../graphql'
import { goToUrl } from '../router'
import { SIGNIN } from './signin.gql'
import { removeAccessToken, removeRefreshToken, setAccessToken, setRefreshToken } from './signin.utils'

interface SigninProps {
  goToUrl: (url: string) => void
  createMessagePayload: typeof CreateMessagePayload
  makeAccountVar: ReactiveVar<{ pkh: unknown }>
}

export const signin =
  ({ goToUrl, createMessagePayload, makeAccountVar }: SigninProps) =>
  () => {
    const [error, setError] = useState<string>('')
    let signinData: {
      address: string
      accountInfo: Partial<AccountInfo>
      messagePayload: {
        signingType: string
        payload: string
        sourceAddress: string
      }
    } = {
      address: '',
      accountInfo: {
        publicKey: '',
      },
      messagePayload: {
        signingType: '',
        payload: '',
        sourceAddress: '',
      },
    }
    const { connect, disconnect, requestSignPayload } = useBeaconWallet()
    const { trackGoal } = useAnalytics()

    const getMessagePayload = (address: string) =>
      createMessagePayload({
        dappUrl: 'stakenow.fi',
        pkh: address,
      })

    const getSignedPayload = ({
      address,
      accountInfo: { publicKey },
    }: {
      address: string
      accountInfo: AccountInfo
    }) => {
      const messagePayload = getMessagePayload(address)
      signinData = {
        address,
        accountInfo: {
          publicKey,
        },
        messagePayload,
      }
      return requestSignPayload(messagePayload as RequestSignPayloadInput)
    }

    const requestSignIn = ({ signature }: SignPayloadResponse) =>
      graphqlClient.mutate({
        mutation: SIGNIN,
        variables: {
          pk: pathOr('', ['accountInfo', 'publicKey'])(signinData),
          address: propOr('', 'address')(signinData),
          message: pathOr('', ['messagePayload', 'payload'])(signinData),
          signature,
        },
      })

    const onSignin = ({ data = {} }) => {
      const { accessToken, refreshToken } = propOr(null, 'signin')(data)
      goToUrl(ROUTES.DASHBOARD.HOME)
      makeAccountVar({ pkh: propOr('', 'address')(signinData) })
      setAccessToken(accessToken)
      setRefreshToken(refreshToken)
      process.env.NEXT_PUBLIC_FATHOM_SSI_EVENT_ID && trackGoal(process.env.NEXT_PUBLIC_FATHOM_SSI_EVENT_ID, 0)
    }

    const signin = () =>
      connect()
        .then(getSignedPayload)
        .then(requestSignIn)
        .then(onSignin)
        .catch(({ message }) => {
          process.env.NEXT_PUBLIC_FATHOM_FSI_EVENT_ID && trackGoal(process.env.NEXT_PUBLIC_FATHOM_FSI_EVENT_ID, 0)
          setError(message)
          disconnect()
          goToUrl(ROUTES.DASHBOARD.NO_ACCESS)
        })

    const signout = (onDisconnect: () => void) =>
      disconnect()
        .then(() => {
          goToUrl(ROUTES.HOME)
          makeAccountVar({ pkh: null })
          removeAccessToken()
          removeRefreshToken()
          graphqlClient.clearStore()
          onDisconnect()
        })
        .catch(({ message }) => {
          setError(message)
        })

    return { error, signin, signout }
  }

export const useSignin = signin({ goToUrl, createMessagePayload, makeAccountVar })
