import { useQuery } from '@apollo/client'
import { path } from 'ramda'

import { GET_BAKER_OVERVIEW } from './BakerOverview.gql'
import { getAvailableBakers, getDelegationTime, getDelegator } from './BakerOverview.utils'

export const useBakers = () => {
  const { loading: isLoading, data } = useQuery(GET_BAKER_OVERVIEW)

  const balance = path(['getAccount', 'balance', 'xtz'])(data)
  const delegationTime = getDelegationTime(data)
  const delegator = getDelegator(data)
  const bakerList = getAvailableBakers(data)

  return { isLoading, balance, bakerList, delegator, delegationTime }
}
