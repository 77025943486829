import gql from 'graphql-tag'

export const GET_POOL = gql`
  query getPool($pkh: String!, $exchangeId: String!, $tokenContract: String!) {
    getPool(pkh: $pkh, exchangeId: $exchangeId, tokenContract: $tokenContract) {
      dex {
        totalShares
        sharePxUsd
        name
        address
        tvl
        apy
        subsidized
        volume {
          weekly {
            xtz
            fees
            move
          }
        }
      }
      pair {
        sharePx
        decimals
        pool
        symbol
        tokenId
        shares
        quote
      }
      history {
        ... on PoolTransaction {
          entrypoint
          level
          timestamp
          usdValue
          poolTokens {
            amount
            price
            usdPrice
            usdValue
          }
          pair {
            amount
            price
            usdValue
          }
          balances {
            aggregatedTokens
            providedUsdValue
            aggregatedInvestment
            cogs
            realizedPl
          }
          performance {
            currentBaseShare
            unrealizedDivergenceLoss
            currentValue
            currentBaseValue
            unrealizedPl
            hodlValue
          }
        }
        ... on TZKTTokenTransfer {
          id
          level
          timestamp
          token {
            id
          }
          from {
            address
            alias
          }
          to {
            address
            alias
          }
          amount
          transactionId
        }
      }
      position {
        exchangeId
        id
        sharesQty
        tokenId
        traderId
        sharesUsd
        poolShare
      }
      metrics {
        current {
          unrealizedPl
          baseShares
          unrealizedDivergenceLoss
          value
          baseValue
          realizedPl
          totalPl
        }
        hodl {
          value
          unrealizedPl
        }
        provided {
          value
          shares
        }
      }
    }
  }
`
