import {
  T,
  all,
  always,
  append,
  complement,
  cond,
  contains,
  dropLast,
  equals,
  flip,
  gt,
  hasPath,
  head,
  isNil,
  join,
  last,
  length,
  map,
  path,
  pathOr,
  pipe,
  prop,
  reject,
  when,
} from 'ramda'

import { OperationInstruction, OperationStatus, Transaction } from '../../common/types'
import { formatMuTez, formatTokenAmount } from '../../common/utils'
import { HIDE_AMOUNTS_FOR_ENTRYPOINTS } from './constants'

export const isNotNil = complement(isNil)

export const getThumbnail = pipe(
  map(({ tokenData }: Transaction) =>
    isNotNil(prop('thumbnailUri')(tokenData))
      ? {
          uri: prop('thumbnailUri')(tokenData),
          name: prop('name')(tokenData),
        }
      : null,
  ),
  reject(isNil),
  head,
)

export const getFromTo = (transaction: Transaction) => (pkh: string) => (instruction: OperationInstruction) => {
  const InstructionsMap = {
    [OperationInstruction.incoming]: 'sender',
    [OperationInstruction.outgoing]: 'target',
    [OperationInstruction.transfer]: 'target',
  }

  return hasPath(['tokenData', 'to'])(transaction) && !equals(pkh, path(['tokenData', 'to'])(transaction))
    ? {
        alias: null,
        address: path(['tokenData', 'to'])(transaction),
      }
    : prop(InstructionsMap[instruction])(transaction)
}

export const isIncomingTransaction = (pkh: string) => (transaction: Transaction) => {
  const isTarget = equals(pkh, path(['target', 'address'])(transaction))
  const isIncomingToken =
    hasPath(['tokenData', 'to'])(transaction) && equals(pkh, path(['tokenData', 'to'])(transaction))

  return contains(true)([isTarget, isIncomingToken])
}

export const getActions = pipe(map(prop('entrypoint')), reject(isNil), join(', '))

const shouldShowAmount = complement(flip(contains))(HIDE_AMOUNTS_FOR_ENTRYPOINTS)

const hasAmount = (transaction: Transaction) =>
  contains(true, [isNotNil(path(['tokenData', 'amount'])(transaction)), gt(prop('usdValue')(transaction))(0)])

export const getAmounts = (pkh: string) => (operationStatus: OperationStatus) => (transactions: Transaction[]) =>
  pipe(
    map((transaction: Transaction) =>
      all(equals(true), [
        shouldShowAmount(prop('entrypoint')(transaction)),
        hasAmount(transaction),
        isIncomingTransaction(pkh)(transaction) || isNotNil(prop('entrypoint')(transaction)),
      ]) || equals(length(transactions))(1)
        ? {
            isIncoming: isIncomingTransaction(pkh)(transaction),
            amount: isNotNil(pathOr(null, ['tokenData', 'amount'])(transaction))
              ? formatTokenAmount(prop('tokenData')(transaction))
              : formatMuTez(prop('amount')(transaction)),
            usdValue: prop('usdValue')(transaction),
          }
        : null,
    ),
    reject(isNil),
    when(
      () => all(equals(true), [equals(OperationStatus.failed)(operationStatus), gt(prop('length'), 0)]),
      pipe(head, flip(append)([])),
    ),
  )(transactions)

export const hasIncomingTransactions = (pkh: string) => (transactions: Transaction[]) =>
  contains(true)(map((transaction: Transaction) => isIncomingTransaction(pkh)(transaction))(transactions))

export const isIncomingOperation = (pkh: string) => (transactions: Transaction[]) =>
  gt(length(transactions))(1)
    ? cond([
        [all(equals(true)), always(true)],
        [all(equals(false)), always(false)],
        [contains(true), always(true)],
        [T, always(false)],
      ])([isIncomingTransaction(pkh)(last(transactions)), hasIncomingTransactions(pkh)(dropLast(1)(transactions))])
    : isIncomingTransaction(pkh)(last(transactions))

export const getOperationInstruction = (pkh: string) => (transactions: Transaction[]) =>
  gt(length(transactions))(1)
    ? cond([
        [all(equals(true)), always(OperationInstruction.incoming)],
        [all(equals(false)), always(OperationInstruction.outgoing)],
        [T, always(OperationInstruction.transfer)],
      ])([isIncomingTransaction(pkh)(last(transactions)), hasIncomingTransactions(pkh)(dropLast(1)(transactions))])
    : cond([
        [equals(true), always(OperationInstruction.incoming)],
        [T, always(OperationInstruction.outgoing)],
      ])(isIncomingTransaction(pkh)(last(transactions)))

export const formatOperation = (pkh: string, transactions: Transaction[], status: OperationStatus) => {
  const instruction = getOperationInstruction(pkh)(transactions)
  const isIncoming = isIncomingOperation(pkh)(transactions)
  const fromTo = getFromTo(last(transactions))(pkh)(instruction)
  const actions = getActions(transactions)
  const thumbnail = getThumbnail(transactions) || null
  const amounts = getAmounts(pkh)(status)(transactions)

  return { isIncoming, fromTo, actions, thumbnail, amounts, instruction }
}
