import { useQuery } from '@apollo/client'
import { Card, LoadingIndicator, XTZ, formatUSD } from '@stakenow/design-system'
import { pathOr } from 'ramda'
import React, { FC } from 'react'

import { useTranslation } from '../../common/i18n'
import { formatAmount } from '../../common/utils'
import { GET_ACCOUNT_BALANCE } from './AccountBalance.gql'

const AccountBalance: FC = () => {
  const { loading, data } = useQuery(GET_ACCOUNT_BALANCE)
  const { xtz, usd } = pathOr({}, ['getAccount', 'balance'])(data)
  const { XTZ: xtzPrice } = pathOr({}, ['getHarbingerPrices'])(data)
  const { t } = useTranslation('AccountBalance')

  if (loading) {
    return (
      <Card>
        <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[Heading] account balance')}</h4>
        <LoadingIndicator />
      </Card>
    )
  }

  return xtz ? (
    <Card>
      <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[Heading] account balance')}</h4>
      <div className="flex flex-wrap items-end gap-y-1 gap-x-3">
        <h2 className="text-3xl font-bold whitespace-nowrap">{formatUSD(usd)}</h2>
        <div className="text-base text-gray-600 dark:text-gray-400">
          <XTZ amount={formatAmount(parseFloat(xtz))} />
        </div>
      </div>
      <h4 className="text-sm text-gray-400 dark:text-gray-500 pt-4">
        {t('[Heading] current xtz usd price')}: {formatUSD(xtzPrice)}
      </h4>
    </Card>
  ) : null
}

export default AccountBalance
