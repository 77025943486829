import { useQuery } from '@apollo/client'
import {
  Card,
  GridRow,
  Heading,
  HeadingWithTooltip,
  LoadingIndicator,
  NFTsStatisticsRow,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  Tooltip,
  formatUSD,
} from '@stakenow/design-system'
import Router from 'next/router'
import { equals, forEach, isEmpty, map, pathOr, prop } from 'ramda'
import React, { FC } from 'react'

import { ROUTES } from '../../../common/constants'
import { useTranslation } from '../../../common/i18n'
import { Columns } from '../../../common/types'
import { formatMuTez, formatQueryString } from '../../../common/utils'
import { GET_NFT_STATISTICS } from './Statistics.gql'

interface StatisticsTotalsProps {
  quantity: number
  estimatedValue: string
  estimatedUSDValue: string
}

const Statistics: FC = () => {
  const { loading, data, error, refetch } = useQuery(GET_NFT_STATISTICS)
  const platforms = pathOr([], ['getNFTStatistics', 'platforms'])(data)
  const summary = pathOr({}, ['getNFTStatistics', 'summary'])(data)
  const { t } = useTranslation('NFTsStatistics')

  if (error) {
    forEach(
      (message: string[]) => equals(prop('errorType')(message))('ExecutionTimeout') && refetch({ refetch: true }),
    )(prop('graphQLErrors')(error))
  }

  if (loading) {
    return <LoadingIndicator />
  }

  const StatisticsTotals: FC<StatisticsTotalsProps> = ({ quantity, estimatedValue, estimatedUSDValue }) => (
    <>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] total nfts')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] total nfts')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{quantity}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] estimated value')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] estimated value')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{formatMuTez(estimatedValue)}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] estimated value (usd)')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] estimated value (usd)')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{formatUSD(estimatedUSDValue)}</h2>
        </div>
      </Card>
    </>
  )

  const PlatformList: FC = () => (
    <div className="col-span-full">
      <Card>
        <div className="flex items-center gap-x-4 pb-4">
          <Heading importance="h3">{t('[Heading] platforms')}</Heading>
          <div className="text-right">
            <span className="text-sm text-gray-600 dark:text-gray-400 font-light" />
          </div>
        </div>
        <div className="-mx-3">
          <Table>
            <TableHeader>
              <tr className="hidden md:table-row">
                <TableCell extraClasses="pb-2 pl-3 w-3/5">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] platform')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2 pl-0 text-right">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] nfts')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2 text-right">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] estimated value')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2 text-right">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] estimated value (usd)')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2 pr-0 text-right">
                  <></>
                </TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {map(
                ({
                  platform,
                  quantity,
                  estimatedValue,
                  estimatedUSDValue,
                }: {
                  platform: string
                  quantity: number
                  estimatedValue: string
                  estimatedUSDValue: string
                }) => (
                  <NFTsStatisticsRow
                    onClick={() =>
                      Router.push({ pathname: ROUTES.DASHBOARD.NFTS, search: formatQueryString({ platform }) })
                    }
                    thumbnail={null}
                    platformName={platform}
                    total={quantity}
                    value={formatMuTez(estimatedValue)}
                    usdValue={estimatedUSDValue}
                    key={platform}
                  />
                ),
              )(platforms)}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  )

  return (
    <GridRow columns={Columns.three}>
      {!isEmpty(summary) ? (
        <StatisticsTotals
          quantity={prop('quantity')(summary)}
          estimatedValue={prop('estimatedValue')(summary)}
          estimatedUSDValue={prop('estimatedUSDValue')(summary)}
        />
      ) : (
        <div className="col-span-full text-center border border-gray-200 dark:border-gray-600 p-5">
          <p>{t('[Heading] no nfts found')}</p>
        </div>
      )}
      {!isEmpty(platforms) ? <PlatformList /> : <></>}
    </GridRow>
  )
}

export default Statistics
