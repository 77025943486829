import { Card, Heading, HeadingWithTooltip, Tooltip, formatUSD } from '@stakenow/design-system'
import { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'

interface CardNetValueProps {
  netValue: number
}

const CardNetValue: FC<CardNetValueProps> = ({ netValue }) => {
  const { t } = useTranslation('Vault')
  return (
    <Card>
      <HeadingWithTooltip
        tooltip={<Tooltip>{t('[Tooltip] net value heading')}</Tooltip>}
        heading={<Heading importance="h4">{t('[Heading] net value')}</Heading>}
      />
      <div className="flex items-end justify-between">
        <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{formatUSD(netValue)}</h2>
      </div>
    </Card>
  )
}

export default CardNetValue
