import Router from 'next/router'
import { FC } from 'react'

import { ROUTES } from '../../../../common/constants'
import { ArrowLeftIcon } from '../assets/ArrowLeftIcon'

interface OverviewButtonProps {
  text: string
}

const OverviewButton: FC<OverviewButtonProps> = ({ text }) => (
  <div className="flex justify-between mb-6">
    <button
      type="button"
      onClick={() => Router.push(ROUTES.DASHBOARD.VAULTS)}
      className="text-gray-400 text-sm leading-none tracking-wide flex items-center"
    >
      {ArrowLeftIcon}
      {text}
    </button>
  </div>
)

export default OverviewButton
