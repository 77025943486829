import { gql } from '@apollo/client'

export const GET_NFT_HISTORY = gql`
  query getNFTHistory($tokenId: Int!, $limit: Int, $nextToken: Int, $pkh: String!) {
    getNFTHistory(tokenId: $tokenId, limit: $limit, nextToken: $nextToken, pkh: $pkh) {
      items {
        id
        tokenId
        price
        quantity
        timestamp
        platform
        from
        to
        event
        eventName
      }
      nextToken
    }
  }
`
