import { Card, CopyToClipboard, Heading, HeadingWithTooltip, Tooltip, truncatePKH } from '@stakenow/design-system'
import { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'

interface CardPlatformProps {
  ovenAddress: string
}

const CardPlatform: FC<CardPlatformProps> = ({ ovenAddress }) => {
  const { t } = useTranslation('Vault')
  return (
    <Card>
      <HeadingWithTooltip
        tooltip={<Tooltip>{t('[Tooltip] vault heading')}</Tooltip>}
        heading={<Heading importance="h4">{t('[Heading] vault')}</Heading>}
      />
      <div className="flex items-end justify-between">
        <CopyToClipboard textToCopy={ovenAddress}>
          <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{truncatePKH(ovenAddress)}</h2>
        </CopyToClipboard>
        {/* <button
          type="button"
          className="text-orange text-xs underline tracking-wide"
          onClick={() => openModal({ modal: Modal.default, data: {} })}
        >
          {t('[Button] close vault')}
        </button> */}
      </div>
    </Card>
  )
}

export default CardPlatform
