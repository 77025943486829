import { useQuery } from '@apollo/client'
import { NFTAttribute } from '@stakenow/design-system'
import { isEmpty, map, pathOr } from 'ramda'
import React, { FC } from 'react'

import { useTranslation } from '../../../common/i18n'
import { GET_NFT_ATTRIBUTES } from './Attributes.gql'

interface AttributeProps {
  id: number
}

const Attributes: FC<AttributeProps> = ({ id }) => {
  const { data } = useQuery(GET_NFT_ATTRIBUTES, { variables: { tokenId: id } })
  const attributes = pathOr([], ['getNFTAttributes'])(data)
  const { t } = useTranslation('NFT')

  return !isEmpty(attributes) ? (
    <div className="w-full">
      <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm">{t('[Heading] attributes')}</h4>
      <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pt-4 pb-4">
        {map(({ title, value, percentage = undefined }: { title: string; value: string; percentage?: number }) => (
          <div className="snap-start hover:snap-start" key={value}>
            <div className="w-36">
              <NFTAttribute name={title} value={value} percentage={percentage} />
            </div>
          </div>
        ))(attributes)}
      </div>
    </div>
  ) : null
}

export default Attributes
