export const setLocalStorage = (key: string) => (value: string) => localStorage.setItem(key, value)

export const getLocalStorage = (key: string) => localStorage.getItem(key)

export const removeLocalStorage = (key: string) => localStorage.removeItem(key)

export const setAccessToken = setLocalStorage('accessToken')

export const getAccessToken = () => getLocalStorage('accessToken')

export const removeAccessToken = () => removeLocalStorage('accessToken')

export const setRefreshToken = setLocalStorage('refreshToken')

export const getRefreshToken = () => getLocalStorage('refreshToken')

export const removeRefreshToken = () => removeLocalStorage('refreshToken')
