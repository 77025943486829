import {
  append,
  compose,
  descend,
  filter,
  find,
  flatten,
  flip,
  includes,
  partition,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  sort,
} from 'ramda'

import { ALLOWED_BAKERS } from '../../common/constants'

export const delegator = compose(propOr(null, 'address'), pathOr(null, ['getAccount', 'delegate']))

export const getDelegator = (data: string) =>
  compose(find(propEq('address', delegator(data))), pathOr([], ['getActiveBakers']))(data)

export const getDelegationTime = pathOr(null, ['getAccount', 'delegationTime'])

export const getAvailableBakers = (data: string | null) =>
  pipe(
    pathOr([], ['getActiveBakers']),
    filter(compose(flip(includes)(append(delegator(data), ALLOWED_BAKERS)), prop('address'))),
    sort(descend(propOr(null, 'estimatedRoi'))),
    partition(propEq('name', 'StakeNow')),
    flatten,
  )(data)
