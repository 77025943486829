import { useQuery } from '@apollo/client'
import { pathOr } from 'ramda'

import { GET_ESTIMATED_VALUE } from './EstimatedValue.gql'

export const useEstimatedValue = (tokenId: number) => {
  const { loading, data } = useQuery(GET_ESTIMATED_VALUE, { variables: { tokenId } })
  const estimatedValue = pathOr([], ['getNFTEstimatedValue', 'estimatedValue'])(data)

  return { loading, estimatedValue }
}
