import { useQuery } from '@apollo/client'
import { GridRow, LoadingIndicator } from '@stakenow/design-system'
import { map, pathOr } from 'ramda'
import React, { FC } from 'react'

import { Columns } from '../../../common/types'
import Platform from './Platform'
import { GET_VAULTS } from './Vaults.gql'
import VaultsTotals from './VaultsTotals'
import { summaryWithDefaults, vaultsWithDefaults } from './utils'

const Vaults: FC = () => {
  const { loading: isLoading, data } = useQuery(GET_VAULTS)
  const vaults = pathOr([], ['getVaults', 'vaults'])(data)
  const summary = pathOr([], ['getVaults', 'summary'])(data)
  const { outstandingTokensValue, collateralValue, netValue, highestUtilization } = summaryWithDefaults(summary)

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <GridRow columns={Columns.four}>
      <VaultsTotals
        outstandingTokensValue={outstandingTokensValue}
        collateralValue={collateralValue}
        netValue={netValue}
        highestUtilization={highestUtilization}
      />
      {map(({ platform, vaults }: any) => (
        <Platform key={platform} platform={platform} vaults={vaultsWithDefaults(vaults)} />
      ))(vaults)}
    </GridRow>
  )
}

export default Vaults
