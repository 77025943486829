import { Language } from '../../common/types'
import en from './locales/en.json'
import nl from './locales/nl.json'

const translations = {
  [Language.en]: {
    AccountBalance: en,
  },
  [Language.nl]: {
    AccountBalance: nl,
  },
}

export default translations
