import { BigNumber, formatFixed } from '@ethersproject/bignumber'
import { formatPercentage, formatXTZ, truncatePKH } from '@stakenow/design-system'
import { format, parseISO, subDays, subMonths, subWeeks } from 'date-fns/fp'
import {
  __,
  always,
  anyPass,
  compose,
  divide,
  equals,
  flip,
  gt,
  has,
  ifElse,
  join,
  lt,
  map,
  multiply,
  pipe,
  prop,
  propOr,
  replace,
  split,
  startsWith,
  when,
} from 'ramda'

import { MUTEZ, MUTEZ_DECIMALS } from '../constants'
import { NFTPlatform, TezToolsPrice } from '../types'
import { NftImageType, PKH_PREFIXES, PLATFORM_ROYALTIES_DECIMALS, nftImageSize } from './utils.contants'

export const isServer = () => typeof window === 'undefined'

export const noop = () => {} // eslint-disable-line @typescript-eslint/no-empty-function

export const ipfsToHttps = replace('ipfs://', process.env.NEXT_PUBLIC_APP_IPFS_URI || 'https://ipfs.io/ipfs/')

export const nftIpfsToHttps = (size: NftImageType) =>
  when(startsWith('ipfs'), (url: string) =>
    pipe(
      ifElse(
        equals(NftImageType.FULLSIZE),
        always(nftImageSize[NftImageType.FULLSIZE]),
        always(nftImageSize[NftImageType.THUMBNAIL]),
      ),
      (x: string) => replace('ipfs://')(x)(url),
    )(size),
  )

export const muTezToTez = pipe(parseFloat, divide(__, MUTEZ))
export const tezToMuTez = pipe(parseFloat, multiply(MUTEZ))

export const muTezToTezBn = (x: BigNumber) => formatFixed(x, MUTEZ_DECIMALS)

export const formatMuTez = pipe(muTezToTez, formatXTZ)

export const formatAmount = (amount: number) =>
  new Intl.NumberFormat(
    'en-US',
    gt(amount)(1) || lt(amount)(-1)
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }
      : {
          minimumFractionDigits: 2,
          maximumSignificantDigits: 2,
        },
  ).format(amount)

export const formatBn = pipe(Number, formatAmount)

export const formatRoyaltiesPercentage = (platform: NFTPlatform) =>
  compose(formatPercentage, divide(__, PLATFORM_ROYALTIES_DECIMALS[platform]))

export const denominate = (token: TezToolsPrice) => flip(divide)(10 ** prop('decimals')(token))

export const denominateAmount = (decimals: number) => flip(divide)(10 ** decimals)

export const formatTokenAmount = (x: any) =>
  `${formatAmount(denominate(x)(prop('amount')(x)))} ${propOr('Token(s)', 'symbol')(x)}`

export const prependExplorerUrl = (path: string) => `${process.env.TZKT_EXPLORER_URL || 'https://tzkt.io'}/${path}`

export const formatIsoDate = format('yyyy-MM-dd')

export const getTodaysDate = () => formatIsoDate(new Date())

export const getWeekBeforeStartDateFrom = pipe(parseISO, subWeeks(1), formatIsoDate)

export const getYesterdaysDate = pipe(parseISO, subDays(1), formatIsoDate)

export const getLastWeeksDateFrom = pipe(subWeeks(1), formatIsoDate)

export const getLastMonthsDateFrom = pipe(subMonths(1), formatIsoDate)

export const getLastWeeksDate = () => getLastWeeksDateFrom(new Date())

export const getLastMonthsDate = () => getLastMonthsDateFrom(new Date())

export const replaceDynamicProperties = (urlSchema: string, properties: Record<string, string | number>) =>
  pipe(
    split('/'),
    map(
      pipe(
        replace(/\[|\]/g, ''),
        when(flip(has)(properties), (x: string) => `${prop(x)(properties)}`),
      ),
    ),
    join('/'),
  )(urlSchema)

export const formatQueryString = (params: string | string[][] | Record<string, string> | URLSearchParams) =>
  new URLSearchParams(params).toString()

export const verifiedValidAddress = equals(3)

export const textStartsWith = (values: string[]) => anyPass(map(startsWith)(values))

export const formatArtistName = (artist: string) =>
  ifElse(textStartsWith(PKH_PREFIXES), truncatePKH, always(artist))(artist)
