import gql from 'graphql-tag'

export const GET_ACCOUNT_BALANCE = gql`
  query GetBalance($pkh: String!) {
    getAccount(pkh: $pkh) {
      balance {
        xtz
        usd
      }
    }
    getHarbingerPrices {
      XTZ
    }
  }
`
