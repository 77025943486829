import { OperationRow as OperationItem } from '@stakenow/design-system'
import { prop } from 'ramda'
import React, { FC } from 'react'

import { TZKT_URL } from '../../../common/constants/constants'
import { Operation } from '../../../common/types'
import { formatOperation } from '../Operations.utils'

interface OperationProps {
  pkh: string
  operation: Operation
}

const OperationRow: FC<OperationProps> = ({
  pkh,
  operation: { timestamp, status, operations: transactions, hash },
}) => {
  const { isIncoming, fromTo, actions, thumbnail, amounts, instruction } = formatOperation(pkh, transactions, status)

  return (
    <OperationItem
      isIncoming={isIncoming}
      status={status}
      instruction={instruction}
      address={prop('address')(fromTo)}
      alias={prop('alias')(fromTo)}
      timestamp={timestamp}
      actions={actions}
      thumbnail={thumbnail}
      amounts={amounts}
      hash={hash}
      url={`${TZKT_URL}${hash}`}
    />
  )
}

export default OperationRow
