import { useReactiveVar } from '@apollo/client'
import {
  Heading,
  LoadingIndicator,
  NFTHistoryRow,
  Table,
  TableBody,
  TableHeader,
  TableRow,
} from '@stakenow/design-system'
import { contains, map } from 'ramda'
import React, { FC } from 'react'

import { makeAccountVar } from '../../../common/graphql'
import { useTranslation } from '../../../common/i18n'
import { useInfiniteLoading } from '../../../common/infiniteLoading'
import { HistoryEvent, HistoryEventName, Size } from '../../../common/types'
import { muTezToTez } from '../../../common/utils'
import { GET_NFT_HISTORY } from './History.gql'

interface HistoryProps {
  id: number
}

const History: FC<HistoryProps> = ({ id }) => {
  const { pkh } = useReactiveVar(makeAccountVar)
  const { items, isLoading, ref } = useInfiniteLoading(GET_NFT_HISTORY, 10, id)

  const { t } = useTranslation('NFT')

  return (
    <>
      {items ? (
        <div className="col-span-full order-last -mx-3">
          <Table>
            <TableHeader>
              <TableRow>
                <td className="pl-3 pt-0 pb-3 hidden md:table-cell">
                  <Heading importance="h4">{t('[Heading] event')}</Heading>
                </td>
                <td className="pt-0 pb-3 hidden md:table-cell">
                  <Heading importance="h4">{t('[Heading] from')}</Heading>
                </td>
                <td className="pt-0 pb-3 hidden md:table-cell" />
                <td className="pt-0 pb-3 hidden md:table-cell">
                  <Heading importance="h4">{t('[Heading] to')}</Heading>
                </td>
                <td className="pt-0 pb-3 hidden md:table-cell">
                  <Heading importance="h4">{t('[Heading] amount')}</Heading>
                </td>
                <td className="pt-0 pb-3 hidden md:table-cell">
                  <Heading importance="h4">{t('[Heading] price')}</Heading>
                </td>
                <td className="pr-3 pt-0 pb-3 w-1/5 hidden md:table-cell">
                  <Heading importance="h4">{t('[Heading] time')}</Heading>
                </td>
              </TableRow>
            </TableHeader>
            <TableBody>
              {map(
                ({
                  id,
                  price,
                  quantity,
                  timestamp,
                  from,
                  to,
                  event,
                  eventName,
                }: {
                  id: number
                  price: number
                  quantity: string | null
                  timestamp: string
                  platform: string
                  from: string | null
                  to: string | null
                  event: HistoryEvent
                  eventName: HistoryEventName
                }) => (
                  <NFTHistoryRow
                    key={id}
                    isPKHInvolved={contains(pkh, [from, to])}
                    price={muTezToTez(price)}
                    quantity={quantity}
                    timestamp={timestamp}
                    from={from}
                    to={to}
                    event={event}
                    eventName={eventName}
                  />
                ),
              )(items)}
            </TableBody>
          </Table>
        </div>
      ) : null}
      {isLoading ? <LoadingIndicator size={Size.large} /> : <div ref={ref} />}
    </>
  )
}

export default History
