import {
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconType,
  RemoveFilter,
  SelectMenu,
  SelectMenuItem,
  SelectRange,
} from '@stakenow/design-system'
import { equals, find, findIndex, has, head, isEmpty, keys, map, path, pathOr, pipe, prop, propEq, values } from 'ramda'
import React, { FC } from 'react'
import { match } from 'ts-pattern'

import { useTranslation } from '../../../common/i18n'
import { FilterAttribute, FilterOperator } from '../../../common/types'
import { muTezToTez } from '../../../common/utils'
import { NFT_LIST_FILTERS } from './constants'

interface FilterProps {
  filters: any
  handleFilter: (
    attribute: FilterAttribute,
    type: FilterOperator,
  ) => (value: string | number | { min: number; max: number }) => void
  addFilter: (attribute: FilterAttribute, type: FilterOperator, value: string | number) => void
  removeFilter: (attribute: FilterAttribute, operator: FilterOperator, value: string | number) => void
}

export const NFTFilters: FC<FilterProps> = ({ filters, handleFilter, addFilter, removeFilter }) => {
  const { t } = useTranslation('NFTGrid')

  return (
    <>
      {map((x: any) => {
        const {
          name: attributeName,
          attribute,
          type,
          options,
        } = find(propEq('attribute', head(keys(x))))(NFT_LIST_FILTERS)

        return (
          <RemoveFilter onClick={() => removeFilter(attribute, type, path([attribute, type])(x))} key={attribute}>
            {match(type)
              .with(FilterOperator.in, FilterOperator.eq, () => (
                <SelectMenu
                  selected={findIndex((y: string | number) => equals(prop('value')(y))(path([attribute, type])(x)))(
                    options,
                  )}
                  key={attribute}
                >
                  {map(({ value, name }: { value: number | string; name: string }) => (
                    <SelectMenuItem
                      onClick={() => handleFilter(attribute, type)(value)}
                      label={name}
                      key={`${attribute}_${value}`}
                    >
                      {name}
                    </SelectMenuItem>
                  ))(options)}
                </SelectMenu>
              ))
              .with(FilterOperator.between, () => (
                <SelectRange
                  key={attribute}
                  label={attributeName}
                  range={[0, 1000]}
                  selected={
                    !isEmpty(path([attribute, type])(x))
                      ? pipe(pathOr({ min: 0, max: 1000 }, [attribute, type]), values, map(muTezToTez))(x)
                      : undefined
                  }
                  onClick={handleFilter(attribute, type)}
                />
              ))
              .otherwise(() => (
                <></>
              ))}
          </RemoveFilter>
        )
      })(filters)}
      <DropdownMenu
        heading={
          <span className="flex items-center space-x-3">
            <span className="w-4 h-4 mr-1.5">
              <Icon icon={IconType.plus} />
            </span>
            {t('[Heading] add filter')}
          </span>
        }
      >
        {map(
          ({
            name,
            attribute,
            type,
            value,
          }: {
            name: string
            attribute: FilterAttribute
            type: FilterOperator
            value: string | number
          }) =>
            !find(has(attribute))(filters) ? (
              <DropdownMenuItem
                key={name}
                onClick={() => {
                  addFilter(attribute, type, value)
                }}
                label={name}
              >
                {name}
              </DropdownMenuItem>
            ) : (
              <></>
            ),
        )(NFT_LIST_FILTERS)}
      </DropdownMenu>
    </>
  )
}
