import gql from 'graphql-tag'

export const GET_OPERATIONS = gql`
  query getOperations($pkh: String!, $start: String, $end: String) {
    getOperations(pkh: $pkh, start: $start, end: $end) {
      items {
        hash
        status
        timestamp
        operations {
          id
          amount
          block
          counter
          entrypoint
          hash
          initiator {
            alias
            address
          }
          sender {
            alias
            address
          }
          status
          target {
            alias
            address
          }
          timestamp
          tokenData {
            contract
            tokenId
            symbol
            name
            decimals
            amount
            thumbnailUri
            artifactUri
            to
          }
          type
          quote {
            usd
          }
          usdValue
        }
      }
      nextToken
    }
  }
`
