import { Card, formatUSD } from '@stakenow/design-system'
import React, { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'
import { formatAmount } from '../../../../common/utils'
import TransferData from './Transfer.data.json'
import { ArrowDownIcon } from './assets/ArrowDownIcon'

const Transfer: FC = () => {
  const { tokens } = TransferData
  const { t } = useTranslation('Manage')

  return (
    <div className="flex flex-col items-center w-full mx-auto">
      <Card>
        <div className="flex justify-center mb-8">
          <div className="bg-orange text-white py-2 px-4 rounded-lg">{t('[Transfer] token')}</div>
          <div className="text-gray-600 dark:text-gray-500 py-2 px-4">{t('[Transfer] nft')}</div>
        </div>

        <div className="flex flex-wrap justify-stretch items-center text-black bg-gray-100 dark:text-white dark:bg-gray-800 p-4 rounded-2xl relative">
          <div className="w-full flex justify-between">
            <p className="text-sm text-gray-400">{t('[Transfer] recipient')}</p>
          </div>

          <div className="w-full">
            <input
              type="text"
              name="address"
              id="address"
              className="focus:ring-0 focus:border-none block w-full px-0 py-0 border-none rounded-md bg-gray-100 dark:bg-gray-800 text-left text-2xl font-bold"
              placeholder="tz... or alice.tez"
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-stretch items-center text-black bg-gray-100 dark:text-white dark:bg-gray-800 p-4 rounded-2xl relative mt-4">
          <div className="w-full flex justify-between">
            <p className="text-sm text-gray-400">{t('[Transfer] token')}</p>
            <p className="text-sm text-gray-400">
              {t('[Transfer] balance')}:{' '}
              <span className="pl-1 dark:text-gray-400">{formatAmount(tokens[0].balance)}</span>
            </p>
          </div>

          <div className="mt-3 relative rounded-md w-full">
            <div className="flex items-center">
              <div className="flex flex-1 items-center focus:ring-0 focus:border-none h-full py-0 px-0 border-none bg-gray-100 dark:bg-gray-800 dark:text-white text-xl">
                <img src={tokens[0].thumbnailUri} alt="StakeNow - Tezos" className="h-10 w-10 rounded-full mr-3" />
                {tokens[0].name}
                <div className="block w-4 h-4 text-gray-500 ml-auto">{ArrowDownIcon}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <div className="mr-2">
            <div className="flex flex-col flex-1 justify-stretch text-black bg-gray-100 dark:text-white dark:bg-gray-800 p-4 rounded-2xl relative mb-2">
              <p className="text-sm text-gray-400">{tokens[0].symbol}</p>
              <input
                type="text"
                name="price"
                id="xtz-price"
                className="focus:ring-0 focus:border-none block w-full px-0 py-0 border-none rounded-md bg-gray-100 dark:bg-gray-800 text-left text-2xl font-bold"
                placeholder="0.00"
                defaultValue={tokens[0].balance}
              />
            </div>
          </div>
          <div className="ml-2">
            <div className="flex flex-col flex-1 justify-stretch text-black bg-gray-100 dark:text-white dark:bg-gray-800 p-4 rounded-2xl relative mb-2">
              <p className="text-sm text-gray-400">{t('[Transfer] usd')}</p>
              <input
                type="text"
                name="price"
                id="usd-price"
                className="focus:ring-0 focus:border-none block w-full px-0 py-0 border-none rounded-md bg-gray-100 dark:bg-gray-800 text-left text-2xl font-bold"
                placeholder="0.00"
                defaultValue={formatUSD(tokens[0].balance * tokens[0].usdValue)}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between px-0 mt-4">
          <p className="text-sm text-gray-600 dark:text-gray-400">{t('[Transfer] network fee')}</p>
          <p className="text-sm text-orange underline">{t('[Transfer] fee')}</p>
        </div>
        <div className="flex justify-stretch mt-8">
          <div className="bg-orange text-white py-4 px-8 text-sm sm:text-base disabled:opacity-50 font-bold leading-none transition duration-300 ease-in-out rounded-full block w-full text-center">
            {t('[Transfer] send')}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Transfer
