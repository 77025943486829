import {
  Button,
  Date,
  Heading,
  LoadingIndicator,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  formatPercentage,
  truncatePKH,
} from '@stakenow/design-system'
import { equals, gt, isEmpty, map, prop } from 'ramda'
import React, { FC } from 'react'

import { useBeaconWallet } from '../../common/beaconWallet'
import { graphqlClient } from '../../common/graphql'
import { useTranslation } from '../../common/i18n'
import { Size } from '../../common/types'
import { useModal } from '../Modal'
import { SET_DELEGATE } from './SetDelegate.gql'
import { useBakers } from './useBakers.hook'

const BakerOverview: FC = () => {
  const { isLoading, balance, bakerList, delegator, delegationTime } = useBakers()
  const { closeModal } = useModal()
  const { delegate } = useBeaconWallet()
  const { t } = useTranslation('BakerOverview')

  const bakersOverview = (
    <>
      <TableHeader>
        <TableRow>
          <TableCell extraClasses="pb-0">
            <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[BakerOverview] baker')}</h4>
          </TableCell>
          <TableCell extraClasses="hidden md:table-cell text-right pb-0">
            <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[BakerOverview] fee')}</h4>
          </TableCell>
          <TableCell extraClasses="hidden md:table-cell text-right pb-0">
            <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[BakerOverview] nominal yield')}</h4>
          </TableCell>
          <TableCell> </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isEmpty(bakerList) ? (
          <TableRow>
            <TableCell colspan={5}>{t('[BakerOverview] no bakers found')}</TableCell>
          </TableRow>
        ) : (
          map(
            ({
              logo,
              name,
              fee,
              freeSpace,
              estimatedRoi,
              address,
            }: {
              logo: string
              name: string
              fee: number
              freeSpace: number
              estimatedRoi: number
              address: string
            }) =>
              !equals(address, prop('address', delegator)) ? (
                <TableRow key={address}>
                  <TableCell>
                    <div className="flex items-center">
                      <img src={logo} alt={name} className="w-8 h-8" />
                      <div className="flex-1">
                        <p className="pl-2 md:pl-4 font-bold text-ellipsis overflow-hidden">{name}</p>
                        <div className="block md:hidden flex pl-2">
                          <div className="flex space-x-1 text-xs w-2/5">
                            <h4 className="text-gray-400 dark:text-gray-500">{t('[BakerOverview] fee')}:</h4>
                            <p>{formatPercentage(fee)}</p>
                          </div>
                          <div className="flex space-x-1 text-xs">
                            <h4 className="text-gray-400 dark:text-gray-500">{t('[BakerOverview] nominal yield')}:</h4>
                            <p>{formatPercentage(estimatedRoi)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell extraClasses="hidden md:table-cell text-right">{formatPercentage(fee)}</TableCell>
                  <TableCell extraClasses="hidden md:table-cell text-right">{formatPercentage(estimatedRoi)}</TableCell>
                  <TableCell extraClasses="text-right">
                    {gt(balance)(freeSpace) ? (
                      <p>{t('[BakerOverview] overdelegated')}</p>
                    ) : (
                      <Button
                        size={Size.small}
                        onClick={() =>
                          delegate(address, () => {
                            closeModal()
                            graphqlClient.writeQuery({
                              query: SET_DELEGATE,
                              data: {
                                getAccount: {
                                  delegate: {
                                    alias: name,
                                    address,
                                  },
                                },
                              },
                            })
                          })
                        }
                      >
                        {t('[BakerOverview] stake')}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ) : null,
          )(bakerList)
        )}
      </TableBody>
    </>
  )

  const isDelegated = ({
    logo,
    name,
    address,
    fee,
    estimatedRoi,
  }: {
    logo: string
    name: string
    address: string
    fee: number
    estimatedRoi: number
  }) => (
    <>
      <Heading importance="h3">{t('[BakerOverview] your wallet is delegated to')}</Heading>
      <div className="flex flex-col md:flex-row gap-y-4 items-start gap-x-20 justify-between border-b border-gray-200 dark:border-gray-600 pb-6 mb-8 mt-4">
        <div>
          <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[BakerOverview] name')}</h4>
          <div className="flex items-center gap-x-2">
            <img src={logo} alt={name} className="w-12 h-12" />
            <h3 className="text-2xl pb-1 font-bold flex flex-col">
              {name}
              <span className="text-xs font-normal text-gray-400 dark:text-gray-500">({truncatePKH(address)})</span>
            </h3>
          </div>
        </div>
        <div>
          <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[BakerOverview] since')}</h4>
          <Heading importance="h3">{delegationTime ? <Date date={delegationTime} /> : null}</Heading>
        </div>
        <div className="flex gap-x-20">
          <div>
            <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[BakerOverview] nominal yield')}</h4>
            <Heading importance="h3">{formatPercentage(estimatedRoi)}</Heading>
          </div>
          <div>
            <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[BakerOverview] fee')}</h4>
            <Heading importance="h3">{formatPercentage(fee)}</Heading>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <div className="overflow-scroll max-h-screen-60">
      {delegator ? isDelegated(delegator) : null}
      {isLoading ? <LoadingIndicator /> : <Table>{bakersOverview}</Table>}
    </div>
  )
}

export default BakerOverview
