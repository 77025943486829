import { NFTOwnedCard } from '@stakenow/design-system'
import { equals } from 'ramda'
import React, { FC } from 'react'
import { match } from 'ts-pattern'

import { useMimeType } from '../../../common/mimeType'
import { MimeType, NFTPlatform, Platform } from '../../../common/types'
import { ipfsToHttps, muTezToTez, nftIpfsToHttps } from '../../../common/utils/utils'
import { NftImageType } from '../../../common/utils/utils.contants'
import { NFTRenderer } from '../../NFTRenderer'
import { useEstimatedValue } from '../useEstimatedValue.hook'

interface ItemProps {
  id: number
  artifactUri: string
  title: string
  description: string
  thumbnailUri: string
  creatorId: string
  quantity: number
  supply: string
  price: string | number
  platform: NFTPlatform
  changePercentage: number
  artifactContent: string
  mime: MimeType
  onClick: () => void
}

export const GridItem: FC<ItemProps> = ({
  id,
  artifactUri,
  title,
  description,
  thumbnailUri,
  creatorId,
  quantity,
  supply,
  price,
  platform,
  artifactContent,
  mime,
  onClick,
}) => {
  const platformArtifactMap = {
    [Platform.HEN]: artifactUri,
    [Platform.OBJKT]: artifactUri,
    [Platform.PFP]: artifactUri,
    [Platform.FxHash]: thumbnailUri,
    [Platform.Versum]: artifactUri,
    [Platform.InterpopComics]: artifactUri,
    [Platform.EightBidou]: artifactContent,
    [Platform.TezosDomains]: artifactUri,
    [Platform.Kalamint]: artifactUri,
    [Platform.Rarible]: artifactUri,
    [Platform.TypedArt]: artifactContent,
    [Platform.ManchesterUnited]: artifactUri,
  }

  const { estimatedValue } = useEstimatedValue(id)

  return (
    <div onClick={onClick} aria-hidden="true" role="button">
      <NFTOwnedCard
        title={equals(Platform.TypedArt)(platform) ? JSON.parse(title) : title}
        artifact={match(platform)
          .with(Platform.TypedArt, () => (
            <NFTRenderer
              artifactContent={artifactContent}
              artifactUri={platformArtifactMap[platform]}
              mime={mime}
              title={title}
              description={description}
              platform={platform}
            />
          ))
          .otherwise(() => {
            const url = nftIpfsToHttps(NftImageType.THUMBNAIL)(platformArtifactMap[platform])
            const { mimeType: externalMimeType } = useMimeType(url)
            return (
              <NFTRenderer
                artifactContent={artifactContent}
                artifactUri={externalMimeType ? url : ipfsToHttps(platformArtifactMap[platform])}
                mime={externalMimeType || mime}
                title={title}
                description={description}
                platform={platform}
              />
            )
          })}
        creatorAddress={creatorId}
        quantity={quantity}
        supply={supply}
        estimatedValue={muTezToTez(estimatedValue)}
        price={price ? muTezToTez(price) : '—'}
        platform={platform}
      />
    </div>
  )
}
