import { Operation } from '@apollo/client'
import { compose, concat, head, mergeWithKey, split, uniq } from 'ramda'

export const queryRequiresVariable = ({ variableName, operation }: { variableName: string; operation: Operation }) =>
  operation.query.definitions?.some(({ variableDefinitions }: any) =>
    variableDefinitions?.some(({ variable }: any) => variable.name.value === variableName),
  )

export const getTypenameFromCacheId: (x: string) => any = compose(head, split(':'))

export const mergeQueryResults = mergeWithKey((key: string, left: [], right: []) =>
  key === 'items' ? uniq(concat(left, right)) : right,
)
