import { useQuery } from '@apollo/client'
import { Card, LoadingIndicator } from '@stakenow/design-system'
import Router from 'next/router'
import { pathOr } from 'ramda'
import React, { FC } from 'react'

import { ROUTES } from '../../../common/constants'
import { useTranslation } from '../../../common/i18n'
import { ipfsToHttps } from '../../../common/utils'
import { NFTRenderer } from '../../NFTRenderer'
import { ArrowLeftIcon } from '../../Vaults/Vault/assets/ArrowLeftIcon'
import { Details } from '../Details'
import { History } from '../History'
import { GET_NFT } from './NFT.gql'

interface NFTProps {
  id: number
}

const NFT: FC<NFTProps> = ({ id }) => {
  const { loading, data } = useQuery(GET_NFT, { variables: { tokenId: id } })
  const {
    title,
    description,
    artifactUri,
    collection,
    collectionId,
    mime,
    royalties,
    supply,
    mintedOn,
    contractId,
    creators,
    platform,
    platformId,
    platformTokenId,
    artifactContent,
    symbol,
  } = pathOr({}, ['getNFT'])(data)

  const { quantity, boughtFor, estimatedValue } = pathOr({}, ['getUserNFT'])(data)

  const { t } = useTranslation('NFT')

  if (loading) {
    return <LoadingIndicator />
  }

  const overviewButton = (
    <div className="flex justify-between mb-6">
      <button
        type="button"
        onClick={() => Router.push(ROUTES.DASHBOARD.NFTS)}
        className="text-gray-400 text-sm leading-none tracking-wide flex items-center"
      >
        {ArrowLeftIcon}
        {t('[Button] return to overview')}
      </button>
    </div>
  )
  return (
    <>
      {overviewButton}
      <div className="flex flex-col-reverse lg:flex-row items-stretch mb-6 bg-white dark:bg-gray-900 rounded-b-2xl lg:rounded-b-none lg:rounded-l-2xl lg:rounded-r-2xl">
        <div className="border text-black bg-white dark:text-white dark:border-gray-900 dark:bg-gray-900 py-5 px-6 lg:px-8 lg:py-7 rounded-b-2xl lg:rounded-b-none lg:rounded-l-2xl lg:w-1/2 flex flex-col">
          <Details
            id={id}
            title={title}
            description={description}
            collection={collection}
            collectionId={collectionId}
            mime={mime}
            royalties={royalties}
            supply={supply}
            mintedOn={mintedOn}
            contractId={contractId}
            creators={JSON.parse(creators)}
            platform={platform}
            platformId={platformId}
            platformTokenId={platformTokenId}
            quantity={quantity}
            price={boughtFor}
            estimatedValue={estimatedValue}
            symbol={symbol}
          />
        </div>
        <div className="rounded-t-2xl lg:rounded-t-none lg:rounded-tr-2xl lg:rounded-r-2xl overflow-hidden lg:w-1/2 relative">
          <NFTRenderer
            artifactContent={artifactContent}
            artifactUri={artifactUri ? ipfsToHttps(artifactUri) : null}
            mime={mime}
            title={title}
            description={description}
            platform={platform}
          />
        </div>
      </div>
      <Card>
        <History id={id} />
      </Card>
    </>
  )
}

export default NFT
