import { DAppClient, Network, NetworkType } from '@airgap/beacon-sdk'
import { always } from 'ramda'
import { match } from 'ts-pattern'

import { isServer } from '../utils'
import { getActiveAccountPKH } from './beaconWallet.utils'

export const network: Network = { type: NetworkType.MAINNET }

export const dAppClient = match(isServer())
  .with(true, () => ({} as DAppClient))
  .with(
    false,
    () =>
      new DAppClient({
        name: process.env.NEXT_PUBLIC_APP_NAME || 'StakeNow.fi Local',
        preferredNetwork: network.type,
      }),
  )
  .exhaustive()

export const getActiveAccount = () =>
  match(isServer()).with(true, always(null)).with(false, getActiveAccountPKH).exhaustive()
