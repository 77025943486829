import { gql } from '@apollo/client'

export const GET_NFT = gql`
  query getNFT($pkh: String!, $tokenId: Int!) {
    getNFT(pkh: $pkh, tokenId: $tokenId) {
      id
      quantity
      holderId
      title
      description
      artifactUri
      displayUri
      thumbnailUri
      collection
      collectionId
      mime
      royalties
      supply
      mintedOn
      creators
      minterId
      buyer
      contractId
      platform
      platformId
      platformTokenId
      artifactContent
      symbol
    }
    getUserNFT(pkh: $pkh, tokenId: $tokenId) {
      quantity
      boughtFor
      estimatedValue
    }
    getNFTTags(pkh: $pkh, tokenId: $tokenId) {
      tag
    }
  }
`
