import gql from 'graphql-tag'

export const GET_VAULTS_WIDGET = gql`
  query getVaults($pkh: String!) {
    getVaults(pkh: $pkh) {
      summary {
        outstandingTokensValue
        collateralValue
        netValue
        highestUtilization
      }
    }
  }
`
