import gql from 'graphql-tag'

export const GET_VAULT = gql`
  query getVault($pkh: String!, $ovenAddress: String!) {
    getVault(pkh: $pkh, ovenAddress: $ovenAddress) {
      ovenAddress
      ovenOwner
      baker
      balance
      borrowedTokens
      stabilityFees
      isLiquidated
      outstandingTokens
      utilization
      collateralValue
      liquidationPrice
      borrowedTokensValue
      stabilityFeesValue
      outstandingTokensValue
      netValue
      minimumCollateralRatio
      collateralRatio
      platform
      symbol
      apy
      quote
    }
    getActiveBakers {
      address
      logo
      name
    }
  }
`
