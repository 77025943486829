import { useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'
import { complement, includes, isNil } from 'ramda'

import { PROTECTED_ROUTES, ROUTES } from '../constants'
import { makeAccountVar } from '../graphql'
import { isServer } from '../utils'

export const useConnectedUser = () => {
  if (isServer()) {
    return { redirecting: false }
  }
  const { push: goTo, asPath, pathname } = useRouter()
  const { pkh } = useReactiveVar(makeAccountVar)

  if (isNil(pkh) && includes(pathname)(PROTECTED_ROUTES)) {
    goTo(ROUTES.HOME)
    return { redirecting: true }
  }

  if (complement(isNil)(pkh) && asPath === ROUTES.HOME) {
    goTo(ROUTES.DASHBOARD.HOME)
    return { redirecting: true }
  }

  return { redirecting: false }
}
