import { PreferencesContext } from '@stakenow/design-system'
import { compose, mergeDeepRight, pathOr, propOr, reduce } from 'ramda'
import { useContext } from 'react'

import { translations as AccountBalanceTranslations } from '../../modules/AccountBalance'
import { ArtifactTranslations } from '../../modules/Artifact'
import { translations as AssetsOverviewTranslations } from '../../modules/AssetsOverview'
import { translations as BakerOverviewTranslations } from '../../modules/BakerOverview'
import { translations as DelegateTranslations } from '../../modules/Delegate'
import { LandingPageTranslations } from '../../modules/LandingPage/common/i18n'
import { LegalNoticeTranslation } from '../../modules/LegalNotice'
import { NFTCollectionSubnavigationTranslations } from '../../modules/NFTCollection/Subnavigation'
import { NFTGridTranslation } from '../../modules/NFTs/Grid'
import { NFTTranslation } from '../../modules/NFTs/NFT'
import { NFTsStatisticsTranslations } from '../../modules/NFTs/Statistics'
import { NFTsSubnavigationTranslations } from '../../modules/NFTs/Subnavigation'
import { NFTsWidgetTranslations } from '../../modules/NFTs/Widget'
import { translations as NavigationTranslations } from '../../modules/Navigation'
import { NoAccessTranslation } from '../../modules/NoAccess'
import { OperationsListTranslation, OperationsWidgetTranslation } from '../../modules/Operations'
import { PoolTranslation, PoolsTranslation, PoolsWidgetTranslation } from '../../modules/Pools'
import { VaultTranslations, VaultsListTranslations, VaultsWidgetTranslations } from '../../modules/Vaults'
import { WalletTranslation } from '../../modules/Wallet/Modal'
import { Language } from '../types'
import { TranslationsMap } from '../types/types'

const getTranslationsForLanguage = (namespace: string) => (locale: Language) => pathOr({}, [locale, namespace])

const mergeTranslations = reduce(mergeDeepRight, { en: {}, nl: {} })

const translationObject = mergeTranslations([
  NavigationTranslations,
  AccountBalanceTranslations,
  DelegateTranslations,
  BakerOverviewTranslations,
  AssetsOverviewTranslations,
  VaultsListTranslations,
  VaultTranslations,
  VaultsWidgetTranslations,
  OperationsListTranslation,
  OperationsWidgetTranslation,
  PoolsTranslation,
  PoolsWidgetTranslation,
  PoolTranslation,
  NoAccessTranslation,
  LandingPageTranslations,
  LegalNoticeTranslation,
  NFTCollectionSubnavigationTranslations,
  NFTsWidgetTranslations,
  NFTGridTranslation,
  NFTTranslation,
  NFTsStatisticsTranslations,
  NFTsSubnavigationTranslations,
  WalletTranslation,
  ArtifactTranslations,
])

export const translation = (translations: TranslationsMap) => (namespace: string) => {
  const { locale } = useContext(PreferencesContext)
  const t = (key: string) => compose(propOr('', key), getTranslationsForLanguage(namespace)(locale))(translations)

  return { t }
}

export const useTranslation = translation(translationObject)
