import { useQuery, useReactiveVar } from '@apollo/client'
import { Card, Heading, LoadingIndicator } from '@stakenow/design-system'
import Router from 'next/router'
import { equals, isEmpty, map, path, pathOr, prop } from 'ramda'
import React, { FC } from 'react'

import { ROUTES } from '../../../common/constants'
import { makeAccountVar } from '../../../common/graphql'
import { useTranslation } from '../../../common/i18n'
import { Operation, OperationType, Size } from '../../../common/types'
import { getLastMonthsDate, getTodaysDate } from '../../../common/utils'
import { Operation as OperationRow } from '../Operation'
import { GET_OPERATIONS } from './OperationsWidget.gql'

const OperationsWidget: FC = () => {
  const { pkh } = useReactiveVar(makeAccountVar)
  const { loading: isLoading, data } = useQuery(GET_OPERATIONS, {
    variables: {
      start: getLastMonthsDate(),
      end: getTodaysDate(),
    },
  })
  const items = pathOr([], ['getOperations', 'items'])(data)
  const { t } = useTranslation('OperationsWidget')

  const operations =
    isEmpty(items) && !isLoading ? (
      <div className="col-span-full text-center border border-gray-200 dark:border-gray-600 p-5 mt-8">
        <p>{t('[Heading] no operations found')}</p>
      </div>
    ) : (
      <ul>
        {map((operation: Operation) =>
          equals(OperationType.transaction)(path(['operations', 0, 'type'])(operation)) ? (
            <li className="border-b border-gray-200 dark:border-gray-800" key={prop('hash')(operation)}>
              <OperationRow pkh={pkh as string} operation={operation} />
            </li>
          ) : null,
        )(items)}
      </ul>
    )

  return (
    <Card>
      <div className="flex justify-between items-end pb-2">
        <Heading importance="h4">{t('[Heading] latest operations')}</Heading>
        <button
          type="button"
          className="text-orange text-xs underline"
          onClick={() => Router.push(ROUTES.DASHBOARD.OPERATIONS)}
        >
          {t('[Button] view all')}
        </button>
      </div>
      {isLoading ? (
        <LoadingIndicator size={Size.large} />
      ) : (
        <div className="relative">
          <div className="h-[26.625rem] max-h-40 relative pt-1.5 overflow-auto scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded dark:scrollbar-track:!bg-slate-500/[0.16] dark:scrollbar-thumb:!bg-slate-500/50 supports-scrollbars:pr-2">
            {operations}
          </div>
        </div>
      )}
    </Card>
  )
}

export default OperationsWidget
