import gql from 'graphql-tag'

export const GET_ASSETS_OVERVIEW = gql`
  query getFungibleTokens($pkh: String!, $exclude: String) {
    getFungibleTokens(pkh: $pkh, exclude: $exclude) {
      balances {
        name
        currentPrice
        thumbnailUri
        contract {
          address
        }
        balance
        balanceValue
        symbol
        usdValue
        decimals
      }
      totals {
        xtz
        usd
      }
    }
  }
`
