import { pathOr, prop } from 'ramda'
import { match } from 'ts-pattern'

import { BakerOverview } from '../../modules/BakerOverview'
import { ModalContent } from '../../modules/Modal/types'
import { NFTModal } from '../../modules/NFTs'
import { WalletModal } from '../../modules/Wallet'
import { Action } from '../types'
import { Modal } from './constants'

export const reducer = (state: ModalContent, action: Action<Modal>) =>
  match(prop('type')(action) as Modal)
    .with(Modal.NFT, () => <NFTModal id={pathOr('', ['data', 'id'])(action)} />)
    .with(Modal.BakerOverview, () => <BakerOverview />)
    .with(Modal.Wallet, () => <WalletModal />)
    .with(Modal.default, () => <></>)
    .exhaustive()
