import gql from 'graphql-tag'

export const GET_DELEGATE = gql`
  query GetDelegate($pkh: String!) {
    getAccount(pkh: $pkh) {
      delegate {
        alias
        address
      }
      delegationTime
    }
  }
`
