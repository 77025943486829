import {
  always,
  anyPass,
  append,
  cond,
  equals,
  evolve,
  find,
  has,
  head,
  ifElse,
  includes,
  join,
  keys,
  last,
  map,
  mergeDeepRight,
  pipe,
  prop,
  split,
  values,
  without,
} from 'ramda'

import { Filter, FilterAttribute, FilterOperator, Obj } from '../types'
import { tezToMuTez } from '../utils'

export const formatFilterObject = (
  attribute: FilterAttribute,
  operator: FilterOperator,
  value: string | number | { min: number; max: number },
) => {
  const formattedValue =
    equals(FilterAttribute.price)(attribute) && equals(FilterOperator.between)(operator)
      ? evolve({
          min: tezToMuTez,
          max: tezToMuTez,
        })(value)
      : value

  return {
    [attribute]: {
      [operator]: formattedValue,
    },
  }
}

export const addAttributeFilter =
  (attribute: FilterAttribute, operator: FilterOperator, value: string | number) =>
  (filters: Record<string, Obj>[]) => {
    const formattedFilter = formatFilterObject(attribute, operator, value)

    return ifElse(includes(formattedFilter), always(filters), append(formattedFilter))(filters)
  }

export const removeAttributeFilter =
  (attribute: FilterAttribute, operator: FilterOperator, value: string | number | { min: number; max: number }) =>
  (filters: Record<string, Obj>[]) => {
    const formattedFilter = {
      [attribute]: {
        [operator]: value,
      },
    }

    return ifElse(includes(formattedFilter), without([formattedFilter]), always(filters))(filters)
  }

export const updateAttributeFilter = (
  attribute: FilterAttribute,
  operator: FilterOperator,
  value: string | number | { min: number; max: number },
) =>
  map((item: Record<FilterAttribute, FilterOperator>) =>
    equals(attribute, head(keys(item))) ? mergeDeepRight(item)(formatFilterObject(attribute, operator, value)) : item,
  )

export const extractValue = (query: Obj, attribute: FilterAttribute) =>
  pipe(
    find((x: any) => equals(prop('name')(x))(prop(attribute)(query))),
    prop('value'),
  )

export const extractInBetweenValue = (attribute: FilterAttribute) =>
  pipe(prop(attribute), split('|'), (range: string[]) => ({
    min: parseFloat(head(range)),
    max: parseFloat(last(range)),
  }))

export const parseAttributeFilters =
  (addFilter: (attribute: FilterAttribute, type: FilterOperator, value: string | number) => void) => (query: Obj) =>
    pipe(
      map(({ attribute, type, options }: { attribute: FilterAttribute; type: FilterOperator; options: [] }) =>
        has(attribute)(query)
          ? addFilter(
              attribute,
              type,
              equals(type)(FilterOperator.between)
                ? extractInBetweenValue(attribute)(query)
                : extractValue(query, attribute)(options),
            )
          : null,
      ),
    )

export const filterOptionName =
  (filters: Filter[]) =>
  (attribute: FilterAttribute, value: string | number | { min: number; max: number } | string[] | number[]) =>
    pipe(
      (attr: string) => find((y: string) => equals(attr, prop('attribute')(y)))(filters),
      (x: any) =>
        cond([
          [equals(FilterOperator.between), () => pipe(values, join('|'))(value)],
          [
            anyPass([equals(FilterOperator.eq), equals(FilterOperator.in)]),
            () =>
              pipe(
                prop('options'),
                find((option: string | number) => equals(prop('value')(option))(value)),
                prop('name'),
              )(x),
          ],
        ])(prop('type')(x)),
    )(attribute)
