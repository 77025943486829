import { DocumentNode, useApolloClient } from '@apollo/client'
import { compose } from 'ramda'

import { getTypenameFromCacheId } from './utils'

export const useCachedData = (id: string, getFragment: (typename: string) => DocumentNode) => {
  const client = useApolloClient()
  const fragment = compose(getFragment, getTypenameFromCacheId)(id)
  return client.readFragment({ id, fragment })
}
