import { ROUTES } from '../../../common/constants'

export const NFT_COLLECTION_SUBNAVIGATION = [
  {
    href: ROUTES.DASHBOARD.NFTS_COLLECTION,
    name: '[Navigation] items',
  },
  {
    href: ROUTES.DASHBOARD.NFTS_COLLECTION_ACTIVITY,
    name: '[Navigation] activity',
  },
]
