import React, { FC } from 'react'

import { useCachedData } from '../../../common/graphql/useCachedData'
import { ipfsToHttps } from '../../../common/utils'
import { NFTRenderer } from '../../NFTRenderer'
import { Details } from '../Details'
import { History } from '../History'
import { getNFTDetailFragment } from './NFTModal.gql'

interface NFTModalProps {
  id: string
}

const NFTModal: FC<NFTModalProps> = ({ id: objectID }) => {
  const {
    id,
    quantity,
    supply,
    collection,
    collectionId,
    title,
    description,
    artifactUri,
    artifactContent,
    thumbnailUri,
    mime,
    royalties,
    mintedOn,
    creators,
    buyer,
    contractId,
    boughtFor,
    estimatedValue,
    platform,
    platformId,
    platformTokenId,
    symbol,
    sellOffer,
    royaltiesSplit,
    minterId,
    version,
  } = useCachedData(objectID, getNFTDetailFragment)

  return (
    <div className="w-full md:max-w-4xl lg:max-w-7xl">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-12">
        <div className="order-1 md:order-0 md:col-start-1 md:col-end-6 flex flex-col mt-6 sm:mt-0">
          <Details
            id={id}
            title={title}
            description={description}
            collection={collection}
            collectionId={collectionId}
            mime={mime}
            royalties={royalties}
            supply={supply}
            mintedOn={mintedOn}
            contractId={contractId}
            creators={JSON.parse(creators)}
            platform={platform}
            quantity={quantity}
            price={boughtFor}
            estimatedValue={estimatedValue}
            platformId={platformId}
            platformTokenId={platformTokenId}
            symbol={symbol}
          />
        </div>
        <div className="order-0 md:order-1 md:col-start-7 md:col-end-13 flex flex-col">
          <div className="rounded-2xl overflow-hidden grow">
            <NFTRenderer
              artifactContent={artifactContent}
              artifactUri={artifactUri ? ipfsToHttps(artifactUri) : null}
              mime={mime}
              title={title}
              description={description}
              platform={platform}
            />
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 dark:border-gray-700 pt-8 mt-8">
        <History id={id} />
      </div>
    </div>
  )
}

export default NFTModal
