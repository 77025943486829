export enum GraphQlOperationName {
  none = '',
  getCollection = 'getCollection',
  getCollectionHistory = 'getCollectionHistory',
  getOwned = 'getOwned',
  getNFTHistory = 'getNFTHistory',
  getOperations = 'getOperations',
}

export enum Status {
  idle = 'idle',
  pending = 'pending',
  success = 'success',
  failed = 'failed',
}

export enum HistoryEvent {
  mint = 'mint',
  reveal = 'reveal',
  burn = 'burn',
  transfer = 'transfer',
  createListing = 'create_listing',
  collectListing = 'collect_listing',
  cancelListing = 'cancel_listing',
  createOffer = 'create_offer',
  cancelOffer = 'cancel_offer',
  acceptOffer = 'accept_offer',
  createFloorOffer = 'create_floor_offer',
  cancelFloorOffer = 'cancel_floor_offer',
  acceptFloorOffer = 'accept_floor_offer',
  bidEnglish = 'bid_english',
  createEnglishAuction = 'create_english_auction',
  cancelEnglishAuction = 'cancel_english_auction',
  concludeEnglishAuction = 'conclude_english_auction',
  createDutchAuction = 'create_dutch_auction',
  cancelDutchAuction = 'cancel_dutch_auction',
  concludeDutchAuction = 'conclude_dutch_auction',
  acceptDutch = 'accept_dutch',
}

export enum HistoryEventName {
  bidPlaced = 'Bid Placed',
  burn = 'Burn',
  buy = 'Buy',
  dutchAuctionCancelled = 'Dutch Auction Cancelled',
  dutchAuctionCreated = 'Dutch Auction Created',
  englishAuctionCancelled = 'English Auction Cancelled',
  englishAuctionCreated = 'English Auction Created',
  list = 'List',
  listingCancelled = 'Listing Cancelled',
  mint = 'Mint',
  noSale = 'No Sale',
  offer = 'Offer',
  offerCancelled = 'Offer Cancelled',
  revealed = 'Revealed',
  sale = 'Sale',
  sell = 'Sell',
  transferred = 'Transferred',
}

export enum PoolEvent {
  add = 'addLiquidity',
  remove = 'removeLiquidity',
}

export enum Language {
  nl = 'nl',
  en = 'en',
}

export enum OrderBy {
  default = '',
  acquiredAsc = 'acquired_asc',
  acquiredDesc = 'acquired_desc',
  priceAsc = 'price_asc',
  priceDesc = 'price_desc',
}

export interface TranslationsMap {
  [Language.en]: { [key: string]: { [key: string]: string } }
  [Language.nl]: { [key: string]: { [key: string]: string } }
}

export enum Columns {
  two = 'two',
  three = 'three',
  four = 'four',
  five = 'five',
}

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum TooltipType {
  info = 'info',
  warning = 'warning',
}

export enum Holdings {
  xtz = 'xtz',
  nfts = 'nfts',
  pools = 'pools',
  vaults = 'vaults',
  assets = 'assets',
}

export enum MimeType {
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  png = 'image/png',
  bmp = 'image/bmp',
  svg = 'image/svg+xml',
  tiff = 'image/tiff',
  mp4 = 'video/mp4',
  mpegVideo = 'video/mpeg',
  webm = 'video/webm',
  quicktime = 'video/quicktime',
  mp3 = 'audio/mp3',
  mpeg = 'audio/mpeg',
  wav = 'audio/wav',
  glb = 'model/gltf-binary',
  gltf = 'model/gltf+json',
  pdf = 'application/pdf',
  html = 'text/html',
  text = 'text/plain',
  folder = 'application/x-directory',
  zip = 'application/zip',
  md = 'text/markdown',
}

export enum ColorScheme {
  light = 'light',
  dark = 'dark',
}

export type Obj = { [key: string]: string | number }

export type Action<T> = {
  type: T
  data?: Obj
}

export enum Layout {
  connected = 'connected',
  notConnected = 'notConnected',
}

export type Platforms = PoolPlatform
export type Summary = PoolSummary

interface PoolPlatform {
  platform: string
  icon: string
  pairs?: Pair[]
}

interface PoolSummary {
  currentValue: number
  hodlValue: number
  providedValue: number
  weightedAverageApy: number
}

export interface Pair {
  contract: string
  thumbnail: string
  pair: string
  subsidized: boolean
  apy: number
  currentValue: number
  hodlValue: number
  providedValue: number
}

export enum OperationStatus {
  failed = 'failed',
  backtracked = 'backtracked',
  applied = 'applied',
  skipped = 'skipped',
}

export enum OperationType {
  transaction = 'transaction',
  delegation = 'delegation',
  orgination = 'orgination',
}

export enum OperationInstruction {
  incoming = 'incoming',
  outgoing = 'outgoing',
  transfer = 'transfer',
}

export interface Operation {
  hash: string
  status: OperationStatus
  timestamp: string
  operations: Transaction[]
}

export interface Transaction {
  id: number
  amount: number
  block: string
  counter: number
  entrypoint: string
  hash: string
  sender: {
    alias: string
    address: string
  }
  status: string
  target: {
    alias: string
    address: string
  }
  timestamp: string
  tokenData: TokenData | null
  type: string
  quote: {
    usd: number
  }
  usdValue: number
}

export interface TokenData {
  contract: string
  tokenId: number
  symbol: string
  name: string
  amount: number
  decimals: number
}

export interface TezToolsPrice {
  symbol: string
  tokenAddress: string
  decimals: number
  name?: string
  shouldPreferSymbol?: boolean
  factoryIndex?: number
  address?: string
  ratio?: number
  tezPool?: number
  tokenPool?: number
  currentPrice: number
  lastPrice?: number
  buyPrice?: number
  sellPrice?: number
  precision?: number
  type?: TokenStandards
  bakerValidator?: string
  currentCandidate?: string
  currentDelegated?: string
  lastUpdateTime?: string
  lastVeto?: string
  periodFinish?: string
  reward?: number
  rewardPaid?: number
  rewardPerSec?: number
  totalReward?: number
  totalSupply?: number
  qptTokenSupply?: number
  totalVotes?: number
  usdValue?: number
  tags?: string
  websiteLink?: string
  telegramLink?: string
  twitterLink?: string
  discordLink?: string
  thumbnailUri?: string
  timestamp?: string
  block?: string
  pairs?: TezToolsCurrencyPair[]
}

export enum TokenStandards {
  'FA1.2' = 'fa1.2',
  'FA2' = 'fa2',
}

export interface TezToolsCurrencyPair {
  address: string
  dex: string
  symbols: string
  tvl: number
  lptSupply: number
  sides: {
    symbol?: string
    pool: number
    price: number
    usdvalue?: number
  }[]
}

export enum Platform {
  HEN = 'HEN/Teia',
  OBJKT = 'objkt.com',
  PFP = 'PFP',
  FxHash = 'fxhash',
  Versum = 'versum',
  InterpopComics = 'InterPop',
  EightBidou = '8Bidou',
  TezosDomains = 'Tezos Domains',
  Kalamint = 'Kalamint',
  Rarible = 'Rarible',
  Kolibri = 'Kolibri',
  TypedArt = 'typed.art',
  ManchesterUnited = 'Manchester United',
}

export enum PFPContract {
  GOGOS = 'KT1SyPgtiXTaEfBuMZKviWGNHqVrBBEjvtfQ',
  NEONZ = 'KT1MsdyBSAMQwzvDH4jt2mxUKJvBSWZuPoRJ',
  PRJKTNEON = 'KT1VbHpQmtkA3D4uEbbju26zS8C42M5AGNjZ',
  KUMULUS = 'KT1W89JFPhSEph7S1SbgjwTXibdk5t8GYX5p',
  COMMON_SKELES = 'KT1HZVd9Cjc2CMe3sQvXgbxhpJkdena21pih',
  TEZZARDS = 'KT1LHHLso8zQWQWg1HUukajdxxbkGfNoHjh6',
  DISTRIBUTED_CONSCIOUSNESS = 'KT1S9VbCtVZUgAG4Q3VArvY5stu96q4CiPHZ',
  OTTEZ = 'KT1L7GvUxZH5tfa6cgZKnH6vpp2uVxnFVHKu',
  OTTEZ_EVOLUTION = 'KT1CJUHK9hkf24AMJfWFS6KJZmY5jb9GYfCS',
  OTTEZ_EXTRA = 'KT1RiFVovoQbYmYuDPyrEcXjoEKmcoyeSQGh',
  OTTEZ_OYSTERS = 'KT1QYVvQb1NCMWLLSAT7YcYtmmuEiKtTHmwd',
  ZIGGURATS = 'KT1PNcZQkJXMQ2Mg92HG1kyrcu3auFX5pfd8',
  BIG_FOOT_MAFIA_CLUB = 'KT1BY2HcwQBx9mwLDvMvrFH7M9M1HHq5xHSn',
  FROGGOS = 'KT1QqTVamPvqEHMCKkzvYN8mxsxCCYjQKsdD',
  TEZ_APE_GANG = 'KT1FReMp4U1KipyH53xXUnnjtdRQZaLnQpUj',
  CHOP_SUMO = 'KT1Wm4Cegd7wW6MYxEhg6AH5iFX48y65nWvv',
  PAZLAND = 'KT1Qx1776ckPd3DNwXqtYtHqaFd37vMbUUCn',
  CYBER_GECKO_GANG = 'KT1CwSgYmZewFazZsW348RAQYn1nthiGP3Qa',
  TEZOS_NINJA_TROOPERS = 'KT1UJBiHoRPPjhELmP65ndXUTtveF25YGgyh',
  CYBER_KIDS_CLUB = 'KT19VQfPZhmAw9FN3hs2da3CmezrdP2ubQCc',
  PIXEL_PANDA = 'KT1AFxcFeTyrgmULfBwvo9oCSkTwi5TZb2hm',
}

export type VaultPlatform = Platform.Kolibri

export type NFTPlatform =
  | Platform.HEN
  | Platform.OBJKT
  | Platform.PFP
  | Platform.FxHash
  | Platform.Versum
  | Platform.InterpopComics
  | Platform.EightBidou
  | Platform.TezosDomains
  | Platform.Kalamint
  | Platform.Rarible
  | Platform.TypedArt
  | Platform.ManchesterUnited

export enum FilterAttribute {
  price = 'price',
  supply = 'supply',
  platform = 'platform',
  mimeType = 'mimeType',
}

export enum FilterOperator {
  eq = 'eq',
  in = 'in',
  ge = 'ge',
  gt = 'gt',
  le = 'le',
  lt = 'lt',
  between = 'between',
}

export interface Filter {
  name: string
  attribute: FilterAttribute
  type: FilterOperator
  value: null | [] | never[]
  options?: {
    value: null | string | number | string[]
    name: string
  }[]
}
