import { useQuery } from '@apollo/client'
import {
  Card,
  LoadingIndicator,
  Symbol,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  XTZ,
  formatUSD,
} from '@stakenow/design-system'
import { descend, gte, isEmpty, map, pathOr, prop, sort } from 'ramda'
import React, { FC } from 'react'

import { FUNGIBLE_TOKEN_CONTRACTS_TO_EXCLUDE } from '../../common/constants'
import { useTranslation } from '../../common/i18n'
import { Size } from '../../common/types'
import { denominateAmount, formatAmount } from '../../common/utils/utils'
import { GET_ASSETS_OVERVIEW } from './AssetsOverview.gql'

interface TokenRowProps {
  name: string
  currentPrice: number
  thumbnailUri: string
  balance: number
  balanceValue: number
  symbol: string
  usdValue: number
}

const TokenRow: FC<TokenRowProps> = ({ name, currentPrice, thumbnailUri, balance, balanceValue, symbol, usdValue }) =>
  gte(balanceValue)(0.01) ? (
    <TableRow>
      <TableCell extraClasses="pl-0">
        <div className="flex gap-x-4 items-center">
          <Symbol thumbnailUri={thumbnailUri} name={name} size={Size.medium} />
          <div>
            <h3 className="text-black dark:text-white font-medium">{symbol}</h3>
            <h4 className="hidden md:block text-gray-400 dark:text-gray-500 text-sm">{name}</h4>
            <h3 className="block md:hidden text-gray-400 dark:text-gray-500 text-xs">{formatUSD(usdValue)}</h3>
          </div>
        </div>
      </TableCell>
      <TableCell extraClasses="text-right hidden md:table-cell">
        <h3 className="text-black dark:text-white font-medium">{formatUSD(usdValue)}</h3>
        <h4 className="hidden md:flex justify-end text-gray-400 dark:text-gray-500 text-sm">
          <XTZ amount={formatAmount(currentPrice)} />
        </h4>
      </TableCell>
      <TableCell extraClasses="text-right">
        <h3 className="text-black dark:text-white font-medium">
          {formatAmount(balance)} {symbol}
        </h3>
        <h4 className="text-gray-400 dark:text-gray-500 text-xs md:text-sm">≈ {formatUSD(balanceValue)}</h4>
      </TableCell>
    </TableRow>
  ) : null

const AssetsOverview: FC = () => {
  const { loading: isLoading, data } = useQuery(GET_ASSETS_OVERVIEW, {
    variables: { exclude: FUNGIBLE_TOKEN_CONTRACTS_TO_EXCLUDE },
  })
  const fungibleTokens = pathOr([], ['getFungibleTokens', 'balances'])(data)
  const { usd } = pathOr({}, ['getFungibleTokens', 'totals'])(data)
  const { t } = useTranslation('AssetsOverview')

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      {isEmpty(fungibleTokens) && !isLoading ? (
        <div className="col-span-full text-center border border-gray-200 dark:border-gray-600 p-5">
          <p>{t('[Heading] no assets found')}</p>
        </div>
      ) : (
        <Card>
          <div className="flex items-center justify-between">
            <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[Heading] assets')}</h4>
            <div className="text-right">
              <h2 className="text-3xl font-bold">{formatUSD(usd)}</h2>
            </div>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell extraClasses="pb-0 pl-0">
                  <h4 className="text-gray-400 dark:text-gray-500 text-xs leading-none tracking-wide">
                    {t('[Heading] name')}
                  </h4>
                </TableCell>
                <TableCell extraClasses="pb-0 text-right hidden md:table-cell">
                  <h4 className="text-gray-400 dark:text-gray-500 text-xs leading-none tracking-wide">
                    {t('[Heading] price')}
                  </h4>
                </TableCell>
                <TableCell extraClasses="pb-0 text-right">
                  <h4 className="text-gray-400 dark:text-gray-500 text-xs leading-none tracking-wide">
                    {t('[Heading] balance')}
                  </h4>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {map(
                ({
                  name,
                  currentPrice,
                  thumbnailUri,
                  balance,
                  balanceValue,
                  symbol,
                  decimals,
                  usdValue,
                }: {
                  name: string
                  currentPrice: number
                  thumbnailUri: string
                  balance: number
                  balanceValue: number
                  symbol: string
                  decimals: number
                  usdValue: number
                }) => (
                  <TokenRow
                    name={name}
                    currentPrice={currentPrice}
                    thumbnailUri={thumbnailUri}
                    balance={denominateAmount(decimals)(balance)}
                    balanceValue={balanceValue}
                    symbol={symbol}
                    usdValue={usdValue}
                    key={symbol}
                  />
                ),
              )(sort(descend(prop('balanceValue')), fungibleTokens))}
            </TableBody>
          </Table>
        </Card>
      )}
    </>
  )
}

export default AssetsOverview
