import {
  Card,
  Heading,
  HeadingWithTooltip,
  RiskLabel,
  Tooltip,
  formatPercentage,
  formatUSD,
} from '@stakenow/design-system'
import { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'
import { formatAmount } from '../../../../common/utils'

interface CardDetailsProps {
  utilization: number
  apy: number
  stabilityFees: number
  symbol: string
  liquidationPrice: number
  quote: number
}

const CardDetails: FC<CardDetailsProps> = ({ utilization, apy, stabilityFees, symbol, liquidationPrice, quote }) => {
  const { t } = useTranslation('Vault')
  return (
    <Card>
      <div>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] risk heading')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] risk')}</Heading>}
        />
        <div className="flex items-center justify-start mt-1">
          <RiskLabel percentage={utilization} />
        </div>
      </div>
      <div className="mt-5">
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] borrow apy heading')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] borrow apy')}</Heading>}
        />
        <div className="flex items-center justify-start">
          <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">{formatPercentage(apy)}</h2>
        </div>
      </div>
      <div className="mt-5">
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] accrued interest heading')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] accrued interest')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">
            {formatAmount(stabilityFees)} {symbol}
          </h2>
        </div>
      </div>
      <div className="mt-5">
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] liquidation price heading')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] liquidation price')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">{formatUSD(liquidationPrice)}</h2>
        </div>
      </div>
      <div className="mt-5">
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] quote heading')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] quote')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">{formatUSD(quote)}</h2>
        </div>
      </div>
    </Card>
  )
}

export default CardDetails
