import { always, applySpec, identity, ifElse, isEmpty, propOr } from 'ramda'

export const vaultsWithDefaults = ifElse(
  isEmpty,
  always([
    {
      collateralValue: 0,
      outstandingTokensValue: 0,
      ovenAddress: '-',
      utilization: 0,
    },
  ]),
  identity,
)

export const summaryWithDefaults = applySpec({
  outstandingTokensValue: propOr(0, 'outstandingTokensValue'),
  collateralValue: propOr(0, 'collateralValue'),
  netValue: propOr(0, 'netValue'),
  highestUtilization: propOr(0, 'highestUtilization'),
})
