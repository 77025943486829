import { Symbol } from '@stakenow/design-system'
import dynamic from 'next/dynamic'
import React, { FC } from 'react'
import { match } from 'ts-pattern'

import { useTranslation } from '../../common/i18n'
import { useMimeType } from '../../common/mimeType'
import { MimeType, Size } from '../../common/types'

interface ModelViewerProps {
  src: string
}

interface ArtifactProps {
  src: string
  title: string
  description: string
  mimeType: MimeType
}

const ModelViewer = dynamic<ModelViewerProps>(
  () => import('./ModelViewer').then(x => x.ModelViewer).catch(err => console.log(err)) as any,
  {
    ssr: false,
  },
)

const Artifact: FC<ArtifactProps> = ({ src, title, description, mimeType }) => {
  const { t } = useTranslation('Artifact')
  return match(mimeType)
    .with(MimeType.gif, MimeType.jpeg, MimeType.png, MimeType.bmp, () => (
      <img src={src} alt={title} className="w-full h-full object-center object-cover" />
    ))
    .with(MimeType.mp4, MimeType.webm, MimeType.quicktime, () => (
      <video className="w-full h-full object-center object-cover" muted autoPlay>
        <source src={src} type={mimeType} />
        Your browser does not support the video tag.
      </video>
    ))
    .with(MimeType.mpegVideo, () => {
      const { mimeType: externalMimeType } = useMimeType(src)

      return (
        <video className="w-full h-full object-center object-cover" muted autoPlay>
          <source src={src} type={externalMimeType} />
          Your browser does not support the video tag.
        </video>
      )
    })
    .with(MimeType.mp3, MimeType.wav, MimeType.mpeg, () => (
      <audio controls loop muted>
        <source src={src} type={mimeType} />
        Your browser does not support the audio element.
      </audio>
    ))
    .with(MimeType.glb, MimeType.gltf, () => <ModelViewer src={src} />)
    .with(MimeType.folder, MimeType.html, MimeType.zip, MimeType.md, MimeType.svg, () => (
      <iframe
        src={src}
        className="w-full h-full"
        allow="accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;"
        title={title}
      />
    ))
    .with(MimeType.pdf, () => (
      <object type={mimeType} data={src} className="w-full h-full min-h-full block">
        {title}
      </object>
    ))
    .with(MimeType.tiff, () => (
      <div className="w-full h-full dark:bg-gray-900 dark:text-gray-700 flex flex-col gap-3 justify-center items-center">
        <Symbol thumbnailUri={null} size={Size.large} name="Default" />
        <p className="text-center"> {t('[Text] tiff not supported')}</p>
      </div>
    ))
    .with(MimeType.text, () => (
      <div className="w-full h-full dark:bg-black bg-gray-200 dark:text-white text-black flex flex-col gap-3 justify-center items-center p-5">
        <p className="text-xl text-center whitespace-pre-wrap">{description}</p>
      </div>
    ))
    .otherwise(() => (
      <div className="w-full h-full dark:bg-gray-900 dark:text-gray-700 flex justify-center items-center">
        <Symbol thumbnailUri={null} size={Size.large} name="Default" />
      </div>
    ))
}

export default Artifact
