import { CopyToClipboard, ExternalLink, Pill, ReadMoreLess, formatShortDate } from '@stakenow/design-system'
import Router from 'next/router'
import { equals, is, isNil, map, pipe } from 'ramda'
import React, { FC } from 'react'

import { ROUTES } from '../../../common/constants'
import { useTranslation } from '../../../common/i18n'
import { NFTPlatform, Platform, Size } from '../../../common/types'
import {
  formatArtistName,
  formatMuTez,
  formatRoyaltiesPercentage,
  replaceDynamicProperties,
} from '../../../common/utils'
import { useModal } from '../../Modal'
import { Attributes } from '../Attributes'
import { Tags } from '../Tags'
import { useEstimatedValue } from '../useEstimatedValue.hook'
import { getExternalNFTLink } from './Details.utils'

interface NFTProps {
  id: number
  title: string
  description: string
  collection: string
  collectionId: number
  mime: string
  royalties: number
  supply: number
  mintedOn: string
  contractId: string
  creators: string
  platform: NFTPlatform
  platformId: number
  platformTokenId: number
  quantity: number
  price: number | string
  estimatedValue: number | string
  symbol: string
}

const NFT: FC<NFTProps> = ({
  id,
  title,
  description,
  collection,
  collectionId,
  mime,
  royalties,
  supply,
  mintedOn,
  contractId,
  creators,
  platform,
  platformTokenId,
  quantity,
  price,
  symbol,
}) => {
  const { t } = useTranslation('NFT')
  const { estimatedValue } = useEstimatedValue(id)
  const { closeModal } = useModal()

  return (
    <>
      <h1 className="text-2xl md:text-4xl uppercase font-semibold">
        {equals(Platform.TypedArt)(platform) ? JSON.parse(title) : title}
      </h1>
      <div className="flex flex-wrap space-x-4 mt-4 items-center">
        {collection ? (
          <>
            <button
              type="button"
              onClick={() => {
                Router.push(replaceDynamicProperties(ROUTES.DASHBOARD.NFTS_COLLECTION, { id: collectionId }))
                closeModal()
              }}
            >
              <Pill size={Size.medium}>{collection}</Pill>
            </button>
          </>
        ) : (
          <></>
        )}
        <Pill size={Size.medium}> {platform}</Pill>
      </div>
      <div className="pt-4 md:pt-6">
        <ReadMoreLess content={equals(Platform.TypedArt)(platform) ? JSON.parse(description) : description} />
      </div>
      <div className="flex flex-wrap gap-1 mt-5">
        <Tags id={id} />
      </div>
      <div className="flex flex-wrap gap-y-8 pt-6 md:pt-10 mt-auto">
        <div className="w-6/12 md:w-4/12">
          <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm pb-1 sm:pb-2">
            {t('[Heading] purchase price')}
          </h4>
          <span className="text-3xl sm:text-4xl font-semibold">{!isNil(price) ? formatMuTez(price) : '-'}</span>
        </div>
        <div className="w-6/12 md:w-6/12">
          <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm pb-1 sm:pb-2">
            {t('[Heading] est. value')}
          </h4>
          <div className="flex items-end">
            <span className="text-3xl sm:text-4xl font-bold mr-3">{formatMuTez(estimatedValue)}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-y-4 sm:gap-y-8 pt-6 md:pt-8 mt-6 md:mt-8 border-t border-gray-200 dark:border-gray-700">
        <div className="w-4/12">
          <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm pb-1 sm:pb-2">
            {t('[Heading] editions')}
          </h4>
          <span className="text-sm sm:text-xl font-semibold">{`${!isNil(quantity) ? quantity : 0}/${supply}`}</span>
        </div>
        <div className="w-4/12">
          <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm pb-1 sm:pb-2">
            {t('[Heading] royalties')}
          </h4>
          <span className="text-sm sm:text-xl font-semibold">{formatRoyaltiesPercentage(platform)(royalties)}</span>
        </div>
        <div className="w-4/12">
          <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm pb-1 sm:pb-2">
            {t('[Heading] minted on')}
          </h4>
          <span className="text-sm sm:text-xl font-semibold">{formatShortDate(mintedOn)}</span>
        </div>
        <div className="w-4/12">
          <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm pb-1 sm:pb-2">
            {symbol || t('[Heading] artifact')}
          </h4>
          <ExternalLink
            label={<span className="text-sm sm:text-xl font-semibold dark:text-white truncate">{platformTokenId}</span>}
            url={getExternalNFTLink(contractId, platformTokenId)(platform)}
            size={Size.medium}
          />
        </div>
        <div className="w-4/12">
          <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm pb-1 sm:pb-2">{t('[Heading] mime')}</h4>
          <span className="text-sm sm:text-xl font-semibold">{mime}</span>
        </div>
        <div className="w-4/12">
          <h4 className="text-gray-400 dark:text-gray-500 text-xs sm:text-sm pb-1 sm:pb-2">{t('[Heading] artist')}</h4>
          <span className="text-sm sm:text-xl font-semibold">
            {is(Array, creators) ? (
              pipe(
                map((creator: string) => (
                  <CopyToClipboard textToCopy={creator}>{formatArtistName(creator)}</CopyToClipboard>
                )),
              )(creators)
            ) : (
              <></>
            )}
          </span>
        </div>
        <Attributes id={id} />
      </div>
    </>
  )
}

export default NFT
