import { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'

const EmptyVault: FC = () => {
  const { t } = useTranslation('Vault')
  return (
    <div className="col-span-full text-center border border-gray-200 dark:border-gray-600 p-5">
      <p className="flex justify-center">{t('[Heading] no vaults available')}</p>
    </div>
  )
}

export default EmptyVault
