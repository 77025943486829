import { GridRow, LoadingIndicator, SelectMenu, SelectMenuItem } from '@stakenow/design-system'
import { useRouter } from 'next/router'
import { equals, isEmpty, map } from 'ramda'
import React, { FC, useEffect, useState } from 'react'

import { ROUTES } from '../../../common/constants'
import { filterOptionName } from '../../../common/filters/filters.utils'
import { useFilters } from '../../../common/filters/useFilters.hook'
import { clearOperationCache } from '../../../common/graphql/cache'
import { useInfiniteLoading } from '../../../common/infiniteLoading'
import { Modal } from '../../../common/modal'
import { addToSearchQuery, goToSearchUrl, goToUrl, removeFromSearchQuery } from '../../../common/router'
import {
  Columns,
  FilterAttribute,
  FilterOperator,
  GraphQlOperationName,
  MimeType,
  NFTPlatform,
  OrderBy,
  Size,
} from '../../../common/types'
import { replaceDynamicProperties } from '../../../common/utils'
import { useModal } from '../../Modal'
import { EmptyGrid } from './EmptyGrid'
import { GridItem } from './GridItem'
import { NFTFilters } from './NFTFilters'
import { GET_OWNED } from './NFTGrid.gql'
import { getNFTArtists } from './NFTGrid.utils'
import { NFT_LIST_FILTERS, NFT_LIST_SORTINGS } from './constants'

const NFTGrid: FC<{ columns: Columns }> = ({ columns = Columns.five }) => {
  const [orderBy, setOrderBy] = useState(OrderBy.default)
  const { filters, addFilter, removeFilter, updateFilter, parseFilters } = useFilters()
  const { query } = useRouter()
  const getFilterOptionName = filterOptionName(NFT_LIST_FILTERS)

  useEffect(() => {
    if (!isEmpty(query)) {
      clearOperationCache(GraphQlOperationName.getOwned)
    }
    parseFilters(query)(NFT_LIST_FILTERS)
  }, [query])

  const { items, isLoading, ref } = useInfiniteLoading(GET_OWNED, 4, undefined, orderBy, filters)

  const { openModal } = useModal()
  const nftGrid = (
    <GridRow columns={columns}>
      {isEmpty(items) && !isLoading ? (
        <EmptyGrid />
      ) : (
        map(
          ({
            id,
            quantity,
            title,
            description,
            artifactUri,
            thumbnailUri,
            platform,
            boughtFor,
            supply,
            creators,
            artifactContent,
            mime,
          }: {
            id: number
            quantity: number
            title: string
            description: string
            artifactUri: string
            thumbnailUri: string
            mime: MimeType
            platform: NFTPlatform
            boughtFor: string
            supply: string
            creators: string
            artifactContent: string
          }) => {
            return (
              <GridItem
                key={id}
                id={id}
                quantity={quantity}
                title={title}
                description={description}
                artifactUri={artifactUri}
                thumbnailUri={thumbnailUri}
                mime={mime}
                price={boughtFor}
                platform={platform}
                supply={supply}
                creatorId={getNFTArtists(creators)}
                changePercentage={0}
                artifactContent={artifactContent}
                onClick={() => {
                  openModal({
                    modal: Modal.NFT,
                    data: { id: `NFT:${id}` },
                    onOpen: () =>
                      goToUrl(ROUTES.DASHBOARD.NFTS, replaceDynamicProperties(ROUTES.DASHBOARD.NFT, { id })),
                    onClose: () => goToUrl(ROUTES.DASHBOARD.NFTS),
                  })
                }}
              />
            )
          },
        )(items)
      )}
    </GridRow>
  )

  const handleOrderBy = (value: OrderBy) => {
    clearOperationCache(GraphQlOperationName.getOwned)
    setOrderBy(x => (equals(x)(value) ? OrderBy.default : value))
  }

  const handleFilter =
    (attribute: FilterAttribute, type: FilterOperator) => (value: string | number | { min: number; max: number }) => {
      goToSearchUrl(ROUTES.DASHBOARD.NFTS, addToSearchQuery(attribute, getFilterOptionName(attribute, value)))
      updateFilter(attribute, type, value)
      clearOperationCache(GraphQlOperationName.getOwned)
    }

  const handleRemoveFilter = (
    attribute: FilterAttribute,
    type: FilterOperator,
    value: string | number | { min: number; max: number },
  ) => {
    goToSearchUrl(ROUTES.DASHBOARD.NFTS, removeFromSearchQuery(attribute))
    removeFilter(attribute, type, value)
    clearOperationCache(GraphQlOperationName.getOwned)
  }

  const topBar = (
    <div className="lg:flex lg:items-center lg:justify-between mb-3">
      <div className="flex space-x-3">
        <NFTFilters
          filters={filters}
          handleFilter={handleFilter}
          addFilter={addFilter}
          removeFilter={handleRemoveFilter}
        />
      </div>
      <div className="flex space-x-3">
        <SelectMenu>
          {map(({ value, label }: { value: OrderBy; label: string }) => (
            <SelectMenuItem onClick={() => handleOrderBy(value)} label={label} key={value}>
              <h3>{label}</h3>
            </SelectMenuItem>
          ))(NFT_LIST_SORTINGS)}
        </SelectMenu>
      </div>
    </div>
  )

  return (
    <>
      {topBar}
      {nftGrid}
      {isLoading ? <LoadingIndicator size={Size.large} /> : <div ref={ref} />}
    </>
  )
}

export default NFTGrid
