import { Tab, Tabs } from '@stakenow/design-system'
import Link from 'next/link'
import { map } from 'ramda'
import React, { FC } from 'react'

import { useActiveLink } from '../../../common/activeLink'
import { useTranslation } from '../../../common/i18n'
import { replaceDynamicProperties } from '../../../common/utils'
import { NFT_COLLECTION_SUBNAVIGATION } from './constants'

interface TabProps {
  href: string
  name: string
}

const NFTCollectionSubnavigation: FC<{ id: number }> = ({ id }) => {
  const TabLink: FC<TabProps> = ({ href, name }) => {
    const { t } = useTranslation('NFTCollectionSubnavigation')
    const { isChildActive } = useActiveLink(href)

    return (
      <Tab active={isChildActive}>
        <Link href={replaceDynamicProperties(href, { id })}>{t(name)}</Link>
      </Tab>
    )
  }

  return (
    <div className="mb-5">
      <Tabs>
        {map(({ name, href }: { name: string; href: string }) => <TabLink key={href} href={href} name={name} />)(
          NFT_COLLECTION_SUBNAVIGATION,
        )}
      </Tabs>
    </div>
  )
}

export default NFTCollectionSubnavigation
