import {
  Card,
  CollateralRatioBar,
  Heading,
  HeadingWithTooltip,
  ProgressBar,
  Tooltip,
  formatPercentage,
} from '@stakenow/design-system'
import { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'
import { Size } from '../../../../common/types'

interface CardUtilizationProps {
  utilization: number
  collateralRatio: number
  minimumCollateralRatio: number
  target?: number
}

const CardUtilization: FC<CardUtilizationProps> = ({
  utilization,
  collateralRatio,
  minimumCollateralRatio,
  target,
}) => {
  const { t } = useTranslation('Vault')
  return (
    <Card>
      <div className="mb-4">
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] utilization heading')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] utilization')}</Heading>}
        />
        <div className="pt-2">
          <ProgressBar size={Size.small} percentage={utilization} label />
        </div>
      </div>
      <div className="pt-3">
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] collateral ratio heading')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] collateral ratio')}</Heading>}
        />
        <div className="pt-2">
          <CollateralRatioBar
            size={Size.small}
            percentage={collateralRatio}
            ratio={minimumCollateralRatio}
            target={null}
            label
          />
        </div>
      </div>
      <div className="flex justify-start pt-4 gap-8">
        <h4 className="text-gray-500 dark:text-gray-400 text-xs leading-none tracking-wide">
          {t('[Heading] minimum collateral ratio')}: {` `}
          <span className="font-bold">{formatPercentage(minimumCollateralRatio)}</span>
        </h4>
        {target && (
          <h4 className="text-gray-300 dark:text-gray-400 text-xs leading-none tracking-wide">
            {t('[Heading] target')}: {` `}
            <span className="font-bold">{formatPercentage(target)}</span>
          </h4>
        )}
      </div>
    </Card>
  )
}

export default CardUtilization
