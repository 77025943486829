import { NFTPlatform, Platform } from '../types'

export enum NftImageType {
  THUMBNAIL = 'thumbnail',
  FULLSIZE = 'fullSize',
}

export const nftImageSize = {
  [NftImageType.FULLSIZE]:
    process.env.NEXT_PUBLIC_IPFS_NFT_FULLSIZE_IMAGE_URI_PREFIX || 'https://static.tcinfra.net/ipfs/',
  [NftImageType.THUMBNAIL]:
    process.env.NEXT_PUBLIC_IPFS_NFT_THUMBNAIL_IMAGE_URI_PREFIX || 'https://static.tcinfra.net/media/small/ipfs/',
}

export const PLATFORM_ROYALTIES_DECIMALS: Record<NFTPlatform, number> = {
  [Platform.FxHash]: 10000,
  [Platform.HEN]: 10000,
  [Platform.OBJKT]: 1000,
  [Platform.PFP]: 1000,
  [Platform.Versum]: 10000,
  [Platform.InterpopComics]: 1000,
  [Platform.EightBidou]: 10000,
  [Platform.TezosDomains]: 1000,
  [Platform.Kalamint]: 1000,
  [Platform.Rarible]: 10000,
  [Platform.TypedArt]: 10000,
  [Platform.ManchesterUnited]: 1000,
}

export const PKH_PREFIXES = ['tz1', 'tz2', 'tz3']
