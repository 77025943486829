import { useRouter } from 'next/router'
import { equals } from 'ramda'

import { isParentItemActive } from './activeLink.utils'

const getCurrentPath = () => useRouter().pathname

export const useActiveLink = (x: string) => {
  const isParentActive = isParentItemActive(getCurrentPath())(x)

  const isChildActive = equals(getCurrentPath())(x)

  return { isParentActive, isChildActive }
}
