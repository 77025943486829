import { Card, XTZ, formatUSD } from '@stakenow/design-system'
import React, { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'
import { formatAmount } from '../../../../common/utils'
import SwapData from './Swap.data.json'
import { ArrowDownIcon } from './assets/ArrowDownIcon'
import { SettingIcon } from './assets/SettingIcon'
import { SwitchIcon } from './assets/SwitchIcon'

const Swap: FC = () => {
  const { tokens } = SwapData
  const { t } = useTranslation('Manage')

  return (
    <div className="flex flex-col items-center w-full mx-auto">
      <Card>
        <div className="flex justify-center mb-8 relative">
          <div className="bg-orange text-white py-2 px-4 rounded-lg">{t('[Swap] swap')}</div>
          <div className="text-gray-600 dark:text-gray-500 py-2 px-4">{t('[Swap] limit')}</div>
          <div className="w-6 h-6 absolute right-2 top-2 text-gray-700 dark:text-gray-400">{SettingIcon}</div>
        </div>
        <div className="flex flex-wrap justify-stretch items-center text-black bg-gray-100 dark:text-white dark:bg-gray-800 p-4 rounded-2xl relative">
          <div className="w-full flex justify-between">
            <p className="text-sm text-gray-400">{t('[Swap] from')}</p>
            <p className="text-sm text-gray-400">
              {t('[Swap] balance')}: <span className="pl-1 dark:text-gray-400">{formatAmount(442.23452345)}</span>
            </p>
          </div>

          <div className="my-5 relative rounded-md w-full">
            <div className="absolute inset-y-0 left-0 flex items-center">
              <div className="flex items-center focus:ring-0 focus:border-none h-full py-0 pl-0 pr-10 border-none bg-gray-100 dark:bg-gray-800 dark:text-white text-xl">
                <img src={tokens[0].thumbnailUri} alt="StakeNow - Swap" className="h-10 w-10 rounded-full mr-3" />
                {tokens[0].symbol}
                <div className="block w-4 h-4 text-gray-500 ml-2">{ArrowDownIcon}</div>
              </div>
            </div>
            <input
              type="text"
              name="price"
              id="token-price"
              className="focus:ring-0 focus:border-none block w-full pl-12 pr-0 border-none rounded-md bg-gray-100 dark:bg-gray-800 text-right text-2xl font-bold"
              placeholder="0.00"
              defaultValue="20"
            />
          </div>
          <div className="w-full flex justify-between">
            <p className="text-sm text-gray-400 dark:text-gray-500">Tezos</p>
            <div className="w-48">
              <p className="text-sm text-gray-500 dark:text-gray-400 text-right">~ {formatUSD(95.2)}</p>
            </div>
          </div>
        </div>

        <div className="block w-16 h-16 bg-white dark:bg-gray-900 border-4 border-white dark:border-gray-900 rounded-full text-gray-600 dark:text-white p-4 -mt-4 relative z-10 mx-auto">
          {SwitchIcon}
        </div>

        <div className="flex flex-wrap justify-stretch items-center text-black bg-gray-100 dark:text-white dark:bg-gray-800 p-4 rounded-2xl relative -mt-4">
          <div className="w-full flex justify-between">
            <p className="text-sm text-gray-400">{t('[Swap] to')}</p>
            <p className="text-sm text-gray-400">
              {t('[Swap] balance')}: <span className="pl-1 dark:text-gray-400">{formatAmount(14.2348)}</span>
            </p>
          </div>
          <div className="mt-5 rounded-md w-full">
            <div className="inset-y-0 flex items-center">
              <div className="flex items-center h-full py-0 pl-0 pr-10 border-none bg-gray-100 dark:bg-gray-800 dark:text-white text-xl">
                <img src={tokens[1].thumbnailUri} alt="" className="h-10 w-10 rounded-full mr-3" />
                {tokens[1].symbol}
                <div className="block w-4 h-4 text-gray-500 ml-2">{ArrowDownIcon}</div>
              </div>
            </div>
          </div>
          <div className="w-full mt-5">
            <div className="w-full bg-white dark:bg-gray-900 p-4 rounded-2xl border border-orange flex items-stretch justify-between mt-3 relative text-black dark:text-white">
              <span className="bg-orange text-white absolute -top-3 left-3 text-xs py-1 px-3 rounded-xl tracking-wide">
                Popular
              </span>
              <div className="flex flex-col justify-between text-left">
                <p className="text-lg">Plenty</p>
                <div className="pt-2">
                  <div className="text-xs text-gray-500 dark:text-gray-500 flex space-x-1 gap-1">
                    {t('[Swap] fee')} <XTZ amount={0.02} /> (~ {formatUSD(0.1)})
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between text-right">
                <p className="text-xl font-bold tracking-wide">{formatAmount(56.246511012636)}</p>
                <div className="pt-2">
                  <p className="text-xs text-gray-500 dark:text-gray-500">~ {formatUSD(95.2)}</p>
                </div>
              </div>
            </div>
            <div className="w-full dark:bg-gray-800 p-4 rounded-2xl border border-gray-300 dark:border-gray-600 flex justify-between mt-4 text-gray-500 dark:text-gray-500">
              <div className="flex flex-col justify-between text-left">
                <p className="text-lg">QuipuSwap</p>
                <div className="pt-2">
                  <div className="text-xs text-gray-500 dark:text-gray-500 flex space-x-1 gap-1">
                    {t('[Swap] fee')} <XTZ amount={0.02} /> (~ {formatUSD(0.1)})
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between text-right">
                <p className="text-xl font-bold tracking-wide">{formatAmount(56.246511012636)}</p>
                <div className="pt-2">
                  <p className="text-xs text-gray-500 dark:text-gray-500">~ {formatUSD(95.2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-stretch mt-8">
          <div className="bg-orange text-white py-4 px-8 text-sm sm:text-base disabled:opacity-50 font-bold leading-none transition duration-300 ease-in-out rounded-full block w-full text-center">
            {t('[Swap] swap')}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Swap
