import React, { FC } from 'react'

import { useTranslation } from '../../common/i18n'

const LegalNotice: FC = () => {
  const { t } = useTranslation('LegalNotice')

  return (
    <section className="overflow-hidden sm:overflow-visible max-w-screen relative z-10 px-6 sm:px-6 lg:px-8 py-20">
      <div className="container mx-auto my-auto relative z-10">
        <div className="max-w-5xl mx-auto">
          <h1 className="text-3xl tracking-wide font-extrabold text-gray-900 dark:text-white sm:text-4xl md:text-6xl mb-8">
            {t('[Title] legal notice')}
          </h1>
          <h4 className="font-bold text-lg">{t('[Heading] copyrights')}</h4>
          <p className="mb-5 leading-loose tracking-wide text-base text-gray-500 dark:text-gray-400">
            {t('[Content] copyrights')}
          </p>

          <h4 className="font-bold text-lg">{t('[Heading] liability')}</h4>
          <p className="mb-5 leading-loose tracking-wide text-base text-gray-500 dark:text-gray-400">
            {t('[Content] liability')}
          </p>

          <h4 className="font-bold text-lg">{t('[Heading] others')}</h4>
          <p className="leading-loose tracking-wide text-base text-gray-500 dark:text-gray-400">
            {t('[Content] others')}&nbsp;
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer" className="text-orange">
              http://ec.europa.eu/consumers/odr/
            </a>
          </p>

          <p className="mt-4">
            <strong className="font-bold mb-1">This website is operated by:</strong>
            <address>
              <a href="https://vdl.digital" target="_blank">
                vDL Digital Ventures GmbH
              </a>
              <br />
              Jägerweg 10
              <br />
              85609 Aschheim
              <br />
              Germany
              <br />
              E-mail: <a href="mailto:info@vdl.digital">info@vdl.digital</a>
              <br />
              <br />
              Commercial Register at the Amtsgericht Munich: HRB 245760
              <br />
              Managing Directors: Daniel Liebert, Carlo van Driesten
              <br />
              VAT Registration number: DE322014796
            </address>
          </p>

          <h4 className="font-bold text-lg mt-5">Dependencies</h4>
          <ul>
            <li>
              <a href="https://github.com/airgap-it/beacon-sdk/blob/master/LICENCE" target="_blank" rel="noreferrer">
                Beacon Wallet
              </a>
            </li>
            <li>
              <a href="https://baking-bad.org/docs/api" target="_blank" rel="noreferrer">
                Baking Bad
              </a>
            </li>
            <li>
              <a href="https://api.tzkt.io/#section/Terms-of-Use" target="_blank" rel="noreferrer">
                TzKT
              </a>
            </li>
            <li>
              <a href="https://dipdup.net/docs/index.html" target="_blank" rel="noreferrer">
                Dip Dup
              </a>
            </li>
            <li>
              <a href="https://kolibri.finance/docs/components/oracle" target="_blank" rel="noreferrer">
                Harbinger
              </a>
            </li>
            <li>
              <a href="https://kolibri.finance/docs/general/intro" target="_blank" rel="noreferrer">
                Kolibri
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default LegalNotice
