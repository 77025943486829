import { FC, ReactNode, createContext, useEffect, useReducer, useRef, useState } from 'react'

import { Modal, modalReducer } from '../../common/modal'
import { Obj } from '../../common/types'
import { noop } from '../../common/utils'
import { ModalContent, ModalContext } from './types'

interface ModalProviderProps {
  children: ReactNode
}

const initialState: ModalContent = <></>

const initialContext: ModalContext = {
  content: <></>,
  isOpen: false,
  openModal: noop,
  closeModal: noop,
  ref: null,
}

export const ModalCtx = createContext(initialContext)

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const { Provider } = ModalCtx
  const [content, dispatch] = useReducer(modalReducer, initialState)
  const [isOpen, setIsOpen] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)
  const [context, setContext] = useState<ModalContext>(initialContext)
  const [localOnClose, setLocalOnClose] = useState<() => void>(() => noop)

  const openModal = ({
    modal,
    data,
    onOpen = noop,
    onClose = noop,
  }: {
    modal: Modal
    data: Obj
    onOpen?: () => void
    onClose?: () => void
  }) => {
    dispatch({ type: modal, data })
    setIsOpen(true)
    onOpen()
    setLocalOnClose(() => onClose)
  }

  const closeModal = () => {
    dispatch({ type: Modal.default })
    setIsOpen(false)
    localOnClose()
    setLocalOnClose(() => noop)
  }

  useEffect(() => {
    setContext({
      content,
      isOpen,
      openModal,
      closeModal,
      ref: modalRef.current,
    })
  }, [content, isOpen])

  return (
    <>
      <Provider value={context}>{children}</Provider>
      <div ref={modalRef} />
    </>
  )
}
