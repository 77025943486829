import React, { FC } from 'react'
import { useInView } from 'react-intersection-observer'

import { useTranslation } from '../../../common/i18n'
import { PoolOverview } from './PoolOverview'

const Pools = () => {
  const { t } = useTranslation('Pools')

  const { ref: refStart, inView: inViewStart } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const { ref: refEnd, inView: inViewEnd } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <section className="px-6 sm:px-6 lg:px-8 py-20 bg-white dark:bg-gray-800 relative z-10">
      <div ref={refStart} className="absolute h-full bottom-0 top-0 z-0" />
      <div className="container mx-auto my-auto relative">
        <div className="grid lg:grid-cols-10 relative z-10 items-center">
          <div
            className={`${
              inViewEnd ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-40'
            } order-last lg:order-first transition transform duration-500 lg:col-span-6`}
          >
            <PoolOverview />
          </div>
          <div
            className={`${
              inViewStart ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-40'
            } delay-200 order-first lg:order-last transition transform lg:col-span-3 lg:col-start-8 mb-10`}
          >
            <div className="sm:text-center lg:text-left sticky top-0">
              <h1 className="text-3xl tracking-wide font-extrabold text-gray-900 dark:text-white sm:text-4xl md:text-6xl">
                {t('[Heading] liquidity pools')}
              </h1>
              <p className="mt-3 leading-loose tracking-wide text-base text-gray-500 dark:text-gray-400 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {t('[Content] liquidity pools')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div ref={refEnd} className="absolute h-full bottom-0 top-0 md:top-auto z-0" />
    </section>
  )
}

export default Pools
