import { ApolloClient, ApolloLink, FetchResult, Observable, Operation, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import fetch from 'cross-fetch'
import { anyPass, equals, mergeRight, objOf, propEq } from 'ramda'
import { Subscription } from 'zen-observable-ts'

import { getAccessToken, logUserOut, refreshAccessToken } from '../signin'
import { GET_PKH, cache } from './cache'
import { queryRequiresVariable } from './utils'

export const getPKH = async (operation: Operation) => {
  const variableName = 'pkh'
  if (
    queryRequiresVariable({
      variableName,
      operation,
    })
  ) {
    return graphqlClient // eslint-disable-line
      .query({
        query: GET_PKH,
        fetchPolicy: 'cache-only',
      })
      .then(({ data }) => {
        const {
          account: { pkh },
        } = data
        operation.variables = mergeRight(operation.variables, objOf(variableName, pkh)) // eslint-disable-line
      }) // TODO: Catch error
  }

  return Promise.resolve()
}

export const injectPKHLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: Subscription
      getPKH(operation)
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))
      return () => {
        if (handle) handle.unsubscribe()
      }
    }),
)

let isRefreshingAccessToken = false
let tokenSubscribers: any[] = []
const subscribeTokenRefresh = (cb: any) => {
  tokenSubscribers.push(cb)
}
const onTokenRefreshCompleted = (err: Error | null) => {
  tokenSubscribers.map(cb => cb(err))
}

export const refreshAccessTokenLink = (
  accessToken: () => string | null,
  refreshAccessTokens: () => Promise<string>,
  logOut: () => void,
) =>
  onError(
    ({ graphQLErrors, operation, response, forward }) =>
      new Observable(observer => {
        if (graphQLErrors) {
          graphQLErrors.map(async (error, index) => {
            if (equals('refresh')(operation.operationName) && propEq('errorType', 'UnauthorizedException')(error)) {
              logOut()
            }

            if (anyPass([propEq('errorType', 'UnauthorizedException'), propEq('errorType', 'Unauthorized')])(error)) {
              const retryRequest = (token: string | null) => {
                operation.setContext({
                  headers: {
                    authorization: `Bearer ${token}`,
                  },
                })

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }

                return forward(operation).subscribe(subscriber)
              }

              if (!isRefreshingAccessToken) {
                isRefreshingAccessToken = true

                refreshAccessTokens()
                  .then((token: string) => {
                    isRefreshingAccessToken = false
                    onTokenRefreshCompleted(null)
                    tokenSubscribers = []

                    return retryRequest(token)
                  })
                  .catch(() => {
                    onTokenRefreshCompleted(new Error('Unable to refresh access token'))
                    tokenSubscribers = []
                    isRefreshingAccessToken = false
                    return observer.error(graphQLErrors[index])
                  })
              }

              return new Promise(resolve => {
                subscribeTokenRefresh((errRefreshing: any) => {
                  if (!errRefreshing) resolve(retryRequest(accessToken()))
                })
              })
            }

            return observer.next(response as FetchResult)
          })
        }
      }),
  )

export const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API,
  credentials: 'same-origin',
  fetch,
})

export const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': !getAccessToken() ? process.env.NEXT_PUBLIC_API_KEY : '',
      'authorization': getAccessToken() ? `Bearer ${getAccessToken()}` : '',
    },
  }
})

export const graphqlClient = new ApolloClient({
  link: ApolloLink.from([
    refreshAccessTokenLink(getAccessToken, refreshAccessToken, logUserOut),
    injectPKHLink,
    authLink.concat(httpLink),
  ]),
  cache,
})
