/* eslint-disable react/jsx-props-no-spreading */
import { ApolloProvider } from '@apollo/client'
import { PreferencesContext } from '@stakenow/design-system'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

import '../assets/styles/globals.css'
import { init } from '../common/analytics'
import { graphqlClient } from '../common/graphql'
import { Language } from '../common/types'
import { useConnectedUser } from '../common/user'
import { Modal, ModalProvider } from '../modules/Modal'

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { redirecting } = useConnectedUser()
  const router = useRouter()

  useEffect(() => init(router), [])

  if (redirecting) {
    return null
  }

  return (
    <PreferencesContext.Provider value={{ locale: Language.en }}>
      <ApolloProvider client={graphqlClient}>
        <ModalProvider>
          <Component {...pageProps} />
          <Modal />
        </ModalProvider>
      </ApolloProvider>
    </PreferencesContext.Provider>
  )
}
export default App
