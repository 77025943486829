import { useQuery } from '@apollo/client'
import {
  Card,
  GridRow,
  Heading,
  HeadingWithTooltip,
  LoadingIndicator,
  PoolTableRow,
  Symbol,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  Tooltip,
  formatPercentage,
  formatUSD,
} from '@stakenow/design-system'
import Router from 'next/router'
import { isEmpty, isNil, map, pathOr } from 'ramda'
import React, { FC } from 'react'

import { ROUTES } from '../../../common/constants'
import { useTranslation } from '../../../common/i18n'
import { Columns, Pair, Platforms, Size, Summary } from '../../../common/types'
import { replaceDynamicProperties } from '../../../common/utils/utils'
import { GET_POOLS } from './Pools.gql'

const Pools: FC = () => {
  const { loading: isLoading, data } = useQuery(GET_POOLS)
  const { pools, summary } = pathOr({}, ['getPools'])(data)
  const { t } = useTranslation('Pools')

  if (isLoading) {
    return <LoadingIndicator />
  }

  const PoolsTotals: FC<Summary> = ({ currentValue, hodlValue, providedValue, weightedAverageApy }) => (
    <>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] current value')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] current value')}</Heading>}
        />
        <div className="flex items-end gap-x-4 gap-y-1 flex-wrap">
          <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(currentValue)}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] hodl value')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] hodl value')}</Heading>}
        />
        <div className="flex items-end gap-x-4 gap-y-1 flex-wrap">
          <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(hodlValue)}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] provided value')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] provided value')}</Heading>}
        />
        <div>
          <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatUSD(providedValue)}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] weighted average apy')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] weighted average apy')}</Heading>}
        />
        <div>
          <h2 className="text-2xl md:text-xl lg:text-3xl font-bold">{formatPercentage(weightedAverageApy)}</h2>
        </div>
      </Card>
    </>
  )

  const Platform: FC<Platforms> = ({ platform, icon, pairs: pairsData }) =>
    !isNil(pairsData) ? (
      <div className="col-span-full">
        <Card>
          <div className="flex items-center gap-x-4 pb-4">
            <Symbol thumbnailUri={!isEmpty(icon) ? icon : null} name={platform} size={Size.small} />
            <Heading importance="h3">{platform}</Heading>
          </div>
          <div className="-mx-3">
            <Table>
              <TableHeader>
                <tr className="hidden md:table-row">
                  <TableCell extraClasses="pb-2 pl-3 w-4/12">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] pair')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-2/12 invisible md:visible hidden md:table-cell text-right">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] current value')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-2/12 invisible md:visible hidden md:table-cell text-right">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] hodl value')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-2/12 invisible sm:visible hidden sm:table-cell text-right">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] provided value')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-1/12 invisible lg:visible hidden lg:table-cell text-right">
                    <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] apy')}</h4>
                  </TableCell>
                  <TableCell extraClasses="pb-2 w-1/12 pr-0 text-right">
                    <></>
                  </TableCell>
                </tr>
              </TableHeader>
              <TableBody>
                {map(
                  ({
                    contract,
                    thumbnail,
                    pair,
                    apy,
                    subsidized,
                    currentValue: current,
                    hodlValue: hodl,
                    providedValue: provided,
                  }: Pair) => (
                    <PoolTableRow
                      key={contract}
                      onClick={() =>
                        Router.push(replaceDynamicProperties(ROUTES.DASHBOARD.POOL, { exchangeId: contract }))
                      }
                      thumbnail={thumbnail}
                      pair={pair}
                      subsidized={subsidized}
                      apy={apy}
                      currentValue={current}
                      hodlValue={hodl}
                      providedValue={provided}
                    />
                  ),
                )(pairsData)}
              </TableBody>
            </Table>
          </div>
        </Card>
      </div>
    ) : null

  return (
    <GridRow columns={Columns.four}>
      {!isEmpty(summary) ? PoolsTotals(summary) : null}
      {isEmpty(pools) ? (
        <div className="col-span-full text-center border border-gray-200 dark:border-gray-600 p-5">
          <p>{t('[Heading] no pools found')}</p>
        </div>
      ) : (
        map(({ platform, icon, pairs }: Platforms) => (
          <Platform key={platform} platform={platform} icon={icon} pairs={pairs} />
        ))(pools)
      )}
    </GridRow>
  )
}

export default Pools
