import { FilterAttribute, FilterOperator, MimeType, OrderBy, Platform } from '../../../common/types'

export const NFT_LIST_SORTINGS = [
  {
    label: 'Default',
    value: OrderBy.default,
  },
  {
    label: 'Acquisition Date: New to Old',
    value: OrderBy.acquiredDesc,
  },
  {
    label: 'Acquisition Date: Old to New',
    value: OrderBy.acquiredAsc,
  },
  {
    label: 'Purchase Price: High to Low',
    value: OrderBy.priceDesc,
  },
  {
    label: 'Purchase Price: Low to High',
    value: OrderBy.priceAsc,
  },
]

export const NFT_LIST_FILTERS = [
  {
    name: 'Price',
    attribute: FilterAttribute.price,
    type: FilterOperator.between,
    value: [],
  },
  {
    name: 'Platform',
    attribute: FilterAttribute.platform,
    type: FilterOperator.eq,
    value: null,
    options: [
      {
        value: null,
        name: 'All platforms',
      },
      {
        value: 4,
        name: Platform.FxHash,
      },
      {
        value: 1,
        name: Platform.HEN,
      },
      {
        value: 7,
        name: Platform.InterpopComics,
      },
      {
        value: 12,
        name: Platform.Kalamint,
      },
      {
        value: 13,
        name: Platform.ManchesterUnited,
      },
      {
        value: 2,
        name: Platform.OBJKT,
      },
      {
        value: 3,
        name: Platform.PFP,
      },
      {
        value: 11,
        name: Platform.Rarible,
      },
      {
        value: 10,
        name: Platform.TypedArt,
      },
      {
        value: 6,
        name: Platform.Versum,
      },
      {
        value: 8,
        name: Platform.EightBidou,
      },
    ],
  },
  {
    name: 'Type',
    attribute: FilterAttribute.mimeType,
    type: FilterOperator.in,
    value: null,
    options: [
      {
        name: 'All types',
        value: null,
      },
      {
        name: 'Image',
        value: [MimeType.gif, MimeType.jpeg, MimeType.png, MimeType.bmp, MimeType.svg, MimeType.tiff],
      },
      {
        name: 'Video',
        value: [MimeType.mp4, MimeType.webm, MimeType.quicktime],
      },
      {
        name: 'Audio',
        value: [MimeType.mp3, MimeType.mpeg, MimeType.wav],
      },
      {
        name: 'Interactive',
        value: [MimeType.gltf, MimeType.glb, MimeType.pdf, MimeType.html, MimeType.folder, MimeType.zip, MimeType.md],
      },
    ],
  },
  {
    name: 'Supply',
    attribute: FilterAttribute.supply,
    type: FilterOperator.between,
    value: [],
  },
]
