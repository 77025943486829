import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Card,
  DexTransactionRow,
  GridRow,
  Heading,
  HeadingWithTooltip,
  LoadingIndicator,
  Pill,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TokenTransferRow,
  Tooltip,
  UpDown,
  formatPercentage,
  formatUSD,
  formatXTZ,
} from '@stakenow/design-system'
import Router from 'next/router'
import { equals, head, last, map, path, pathOr, prop, reverse } from 'ramda'
import React, { FC } from 'react'
import { match } from 'ts-pattern'

import { ROUTES } from '../../../common/constants'
import { makeAccountVar } from '../../../common/graphql'
import { useTranslation } from '../../../common/i18n'
import { Columns, PoolEvent, Size, TooltipType } from '../../../common/types'
// import { receiveIcon } from './assets/receiveIcon'
// import { sendIcon } from './assets/sendIcon'
import { denominate, formatAmount } from '../../../common/utils/utils'
import { GET_POOL } from './Pool.gql'
import { hasTokenTransfers } from './Pool.utils'
import { ArrowLeftIcon } from './assets/ArrowLeftIcon'

interface PoolHistoryEventTransaction {
  entrypoint: PoolEvent
  level: number
  timestamp: string
  usdValue: number
  pair: {
    amount: number
  }[]
  poolTokens: {
    amount: number
  }
  balances: {
    tokens: number
    pair: number[]
  }
  __typename: string
}

interface PoolHistoryEventTokenTransfer {
  __typename: string
  id: number
  level: number
  timestamp: string
  token: {
    id: number
    contract: {
      alias: string
      address: string
    }
    tokenId: string
    standard: string
    metadata: {
      name: string
      symbol: string
      decimals: string
    }
  }
  from: {
    address: string
  }
  to: {
    alias: string
    address: string
  }
  amount: string
  transactionId: number
}

interface PoolProps {
  exchangeId: string
}

const Pool: FC<PoolProps> = ({ exchangeId = '' }) => {
  const { loading: isLoading, data } = useQuery(GET_POOL, {
    variables: { exchangeId, tokenContract: 'KT1AafHA1C1vk959wvHWBispY9Y2f3fxBUUo' },
  })
  const { totalShares, apy, name, tvl, volume, subsidized } = pathOr({}, ['getPool', 'dex'])(data)
  const { sharesQty, sharesUsd, poolShare } = pathOr({}, ['getPool', 'position'])(data)
  const { current, hodl, provided } = pathOr({}, ['getPool', 'metrics'])(data)
  const pair = pathOr({}, ['getPool', 'pair'])(data)
  const { history } = pathOr([], ['getPool'])(data)
  const { t } = useTranslation('Pool')
  const { pkh } = useReactiveVar(makeAccountVar)

  if (isLoading) {
    return <LoadingIndicator />
  }

  const overviewButton = (
    <div className="flex justify-between mb-6">
      <button
        type="button"
        onClick={() => Router.push(ROUTES.DASHBOARD.POOLS)}
        className="text-gray-400 text-sm leading-none tracking-wide flex items-center"
      >
        {ArrowLeftIcon}
        {t('[Button] return to overview')}
      </button>
    </div>
  )

  const compareTable = (
    <Card>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell extraClasses="pt-0 pb-1 w-1/2">
              <Heading importance="h4">{t('[Heading] current profit loss')}</Heading>
            </TableCell>
            <TableCell extraClasses="pt-0 pb-1 text-right">
              <div className="block md:hidden">
                <Heading importance="h4">{t('[Heading] vs hodl')}</Heading>
              </div>
              <div className="hidden md:block">
                <Heading importance="h4">{t('[Heading] vs hodl value')}</Heading>
              </div>
            </TableCell>
            <TableCell extraClasses="pt-0 pb-1 pl-4 text-left hidden md:table-cell">
              <Heading importance="h4">{t('[Heading] roi')}</Heading>
            </TableCell>
            <TableCell extraClasses="pt-0 pb-1 pl-4 text-right">
              <div className="block md:hidden">
                <Heading importance="h4">{t('[Heading] vs provided')}</Heading>
              </div>
              <div className="hidden md:block">
                <Heading importance="h4">{t('[Heading] vs provided value')}</Heading>
              </div>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell extraClasses="pt-2 pb-2 text-xs md:text-base">{t('[Heading] unrealized')}</TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base">
              {formatUSD(prop('unrealizedPl')(hodl))}
            </TableCell>
            <TableCell extraClasses="pt-2 pb-2 pl-4 hidden md:table-cell"> </TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base">
              {formatUSD(prop('unrealizedPl')(current))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell extraClasses="pt-2 pb-2 text-sm md:text-base">{t('[Heading] realized')}</TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base"> </TableCell>
            <TableCell extraClasses="pt-2 pb-2 pl-4 hidden md:table-cell"> </TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base">
              {formatUSD(prop('realizedPl')(current))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell extraClasses="pt-2 pb-2 text-sm md:text-base">{t('[Heading] total')}</TableCell>
            <TableCell extraClasses="pt-2 pb-2 pl-4 hidden md:table-cell"> </TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base"> </TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base">
              {formatUSD(prop('totalPl')(current))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell extraClasses="pt-2 pb-2 text-sm md:text-base" colspan={2}>
              <Heading importance="h4">{t('[Heading] performance metrics without subsidy and fees')}</Heading>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell extraClasses="pt-2 pb-2 text-sm md:text-base flex items-center gap-x-2">
              {t('[Heading] pool shares')}
            </TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base"> </TableCell>
          </TableRow>
          <TableRow>
            <TableCell extraClasses="pt-1 pb-1 text-sm md:text-base pl-6">{prop('symbol')(head(pair))}</TableCell>
            <TableCell extraClasses="pt-1 pb-1 text-right text-sm md:text-base">
              {formatAmount(denominate(head(pair))(head(prop('baseShares')(current))))} {prop('symbol')(head(pair))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell extraClasses="pt-1 pb-1 text-sm md:text-base pl-6">{prop('symbol')(last(pair))}</TableCell>
            <TableCell extraClasses="pt-1 pb-1 text-right text-sm md:text-base">
              {formatAmount(denominate(last(pair))(last(prop('baseShares')(current))))} {prop('symbol')(last(pair))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell extraClasses="pt-2 pb-2 text-sm md:text-base flex items-center gap-x-2">
              {t('[Heading] value')}
            </TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base">
              {formatUSD(prop('baseValue')(current))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell extraClasses="pt-2 pb-2 text-sm md:text-base flex items-center gap-x-2">
              {t('[Heading] unrealized divergence loss')}
            </TableCell>
            <TableCell extraClasses="pt-2 pb-2 text-right text-sm md:text-base">
              {formatUSD(prop('unrealizedDivergenceLoss')(current))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  )

  const HistoryTable = history ? (
    <Card>
      <Table>
        <TableBody>
          {map((event: PoolHistoryEventTransaction | PoolHistoryEventTokenTransfer) => {
            const { __typename } = event
            return match(__typename)
              .with('PoolTransaction', () => {
                const {
                  entrypoint,
                  pair: pairHistory,
                  level,
                  timestamp,
                  poolTokens,
                  usdValue,
                } = event as PoolHistoryEventTransaction
                return (
                  <DexTransactionRow
                    key={level}
                    entrypoint={entrypoint}
                    amountLeft={denominate(head(pair))(prop('amount')(head(pairHistory)))}
                    amountRight={denominate(last(pair))(prop('amount')(last(pairHistory)))}
                    symbolLeft={prop('symbol')(head(pair))}
                    symbolRight={prop('symbol')(last(pair))}
                    timestamp={timestamp}
                    tokens={prop('amount')(poolTokens)}
                    usdValue={usdValue}
                  />
                )
              })
              .with('TZKTTokenTransfer', () => {
                const { amount, timestamp, level, to, from } = event as PoolHistoryEventTokenTransfer
                const direction = equals(to?.address)(pkh) ? 'in' : 'out'
                return (
                  <TokenTransferRow
                    key={level}
                    amount={parseFloat(amount)}
                    timestamp={timestamp}
                    to={to}
                    from={from}
                    symbol="SIRS"
                    direction={direction}
                  />
                )
              })
              .otherwise(() => <></>)
          })(reverse(history))}
        </TableBody>
      </Table>
    </Card>
  ) : null

  const PoolDetails = (
    <Card>
      <div className="flex flex-row flex-wrap md:flex-col gap-5">
        <div>
          <Heading importance="h4">{t('[Heading] share of the pool')}</Heading>
          <div className="pt-1.5">
            <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">
              {formatPercentage(poolShare)}
              <span className="text-xs text-gray-400 ml-1.5">
                ({formatAmount(sharesQty)} / {formatAmount(totalShares)})
              </span>
            </h2>
          </div>
        </div>
        <div>
          <Heading importance="h4">{t('[Heading] tvl')}</Heading>
          <div className="pt-1.5">
            <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">{formatUSD(tvl)}</h2>
          </div>
        </div>
        <div>
          <Heading importance="h4">{t('[Heading] volume 7d')}</Heading>
          <div className="flex flex-wrap items-end gap-1 md:gap-3 pt-1.5">
            <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">{formatXTZ(path(['weekly', 'xtz'])(volume))}</h2>
            <UpDown value={path(['weekly', 'move'])(volume)} pill />
          </div>
        </div>
        <div>
          <Heading importance="h4">{t('[Heading] fees')}</Heading>
          <div className="pt-1.5">
            <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">{formatXTZ(path(['weekly', 'fees'])(volume))}</h2>
          </div>
        </div>
        <div>
          <Heading importance="h4">{t('[Heading] apy')}</Heading>
          <div className="pt-1.5 flex gap-x-4 items-center">
            <h2 className="text-2xl lg:text-2xl xl:text-xl font-bold">{formatPercentage(apy)}</h2>
            {subsidized ? <Pill>{t('[Heading] subsidy')}</Pill> : null}
          </div>
        </div>
      </div>
    </Card>
  )

  return (
    <>
      {overviewButton}
      <div className="flex flex-wrap justify-between items-end mb-6 gap-4">
        <h2 className="text-xl lg:text-2xl xl:text-4xl font-bold">
          {name} {path([0, 'symbol'])(pair)} / {path([1, 'symbol'])(pair)}
        </h2>
        <div className="flex gap-4">
          {/* <button
            type="button"
            className="flex items-center bg-orange hover:bg-orange-700 text-white py-2 px-3 md:py-2 md:px-4 text-xs sm:text-sm  disabled:opacity-50 font-bold leading-none transition duration-300 ease-in-out rounded-full tracking-wide"
            onClick={() => {}}
          >
            <div className="inline-block w-3.5 h-3.5 mr-1.5">{receiveIcon}</div>
            {t('[Button] deposit')}
          </button>
          <button
            type="button"
            className="flex items-center bg-orange hover:bg-orange-700 text-white py-2 px-3 md:py-2 md:px-4 text-xs sm:text-sm  disabled:opacity-50 font-bold leading-none transition duration-300 ease-in-out rounded-full tracking-wide"
            onClick={() => {}}
          >
            <div className="inline-block w-3.5 h-3.5 mr-1.5">{sendIcon}</div>
            {t('[Button] withdraw')}
          </button> */}
        </div>
      </div>
      <GridRow columns={Columns.three}>
        <Card>
          <HeadingWithTooltip
            tooltip={<Tooltip>{t('[Tooltip] current value')}</Tooltip>}
            heading={<Heading importance="h4">{t('[Heading] current value')}</Heading>}
          />
          <div className="flex items-end gap-2 md:gap-4">
            <h2 className="text-2xl md:text-3xl font-bold">{formatUSD(sharesUsd)}</h2>
            {hasTokenTransfers(history) ? (
              <div className="pb-0.5">
                <Tooltip type={TooltipType.warning} size={Size.large}>
                  {t('[Tooltip] in/outoging transactions')}
                </Tooltip>
              </div>
            ) : null}
            {/* <UpDown value={calculateProfitPercentage(prop('value')(provided))(sharesUsd)} pill /> */}
          </div>
          <div className="pt-2 md:pt-4 flex flex-col gap-y-1">
            <h4 className="text-gray-500 dark:text-gray-400 text-xs leading-none tracking-wide">
              <span className="font-bold">
                {formatAmount(denominate(head(pair))(prop('shares')(head(pair))))} {prop('symbol')(head(pair))}
              </span>
              {' / '}
              <span className="font-bold">
                {formatAmount(denominate(last(pair))(prop('shares')(last(pair))))} {prop('symbol')(last(pair))}
              </span>
            </h4>
          </div>
        </Card>
        <Card>
          <HeadingWithTooltip
            tooltip={<Tooltip>{t('[Tooltip] hodl value')}</Tooltip>}
            heading={<Heading importance="h4">{t('[Heading] hodl value')}</Heading>}
          />
          <div className="flex items-end gap-2 md:gap-4">
            <h2 className="text-2xl md:text-3xl font-bold">{formatUSD(prop('value')(hodl))}</h2>
            {/* <UpDown value={calculateProfitPercentage(prop('value')(provided))(prop('value')(hodl))} pill /> */}
          </div>
          <div className="pt-2 md:pt-4 flex flex-col gap-y-1">
            <h4 className="text-gray-500 dark:text-gray-400 text-xs leading-none tracking-wide">
              <span className="font-bold">
                {formatAmount(denominate(head(pair))(head(prop('shares')(provided))))} {prop('symbol')(head(pair))}
              </span>
              {' / '}
              <span className="font-bold">
                {formatAmount(denominate(last(pair))(last(prop('shares')(provided))))} {prop('symbol')(last(pair))}
              </span>
            </h4>
          </div>
        </Card>
        <Card>
          <HeadingWithTooltip
            tooltip={<Tooltip>{t('[Tooltip] provided value')}</Tooltip>}
            heading={<Heading importance="h4">{t('[Heading] provided value')}</Heading>}
          />
          <h2 className="text-2xl md:text-3xl font-bold">{formatUSD(prop('value')(provided))}</h2>
          <div className="pt-2 md:pt-4 flex flex-col gap-y-1">
            <h4 className="text-gray-500 dark:text-gray-400 text-xs leading-none tracking-wide">
              <span className="font-bold">
                {formatAmount(denominate(head(pair))(head(prop('shares')(provided))))} {prop('symbol')(head(pair))}
              </span>
              {' / '}
              <span className="font-bold">
                {formatAmount(denominate(last(pair))(last(prop('shares')(provided))))} {prop('symbol')(last(pair))}
              </span>
            </h4>
          </div>
        </Card>
        {PoolDetails}
        <div className="col-span-full md:col-span-2 flex items-stretch">{compareTable}</div>
        <div className="col-span-full">{HistoryTable}</div>
      </GridRow>
    </>
  )
}

export default Pool
