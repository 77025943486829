import { EightBidou } from '@stakenow/design-system'
import React, { FC } from 'react'
import { match } from 'ts-pattern'

import { MimeType, Platform } from '../../common/types'
import { Artifact } from '../Artifact'

interface NFTRendererProps {
  artifactUri?: string
  artifactContent?: string
  mime?: MimeType
  title?: string
  description?: string
  platform: Platform
}

export const NFTRenderer: FC<NFTRendererProps> = ({
  artifactContent = '',
  artifactUri = '',
  mime = '' as MimeType,
  title = '',
  description = '',
  platform,
}) =>
  match(platform)
    .with(Platform.EightBidou, () => <EightBidou rgb={artifactContent} />)
    .with(Platform.TypedArt, () => (
      <div className="w-full h-full dark:bg-black bg-gray-200 dark:text-white text-black flex flex-col gap-3 justify-center items-center p-5">
        <p className="text-xl text-center whitespace-pre-wrap">{JSON.parse(description)}</p>
      </div>
    ))
    .otherwise(() => <Artifact src={artifactUri} mimeType={mime} title={title} description={description} />)
