import gql from 'graphql-tag'

export const GET_NFT_STATISTICS = gql`
  query getNFTStatistics($pkh: String!, $refetch: Boolean) {
    getNFTStatistics(pkh: $pkh, refetch: $refetch) {
      summary {
        quantity
        estimatedValue
        estimatedUSDValue
      }
      platforms {
        platform
        quantity
        estimatedValue
        estimatedUSDValue
      }
    }
  }
`
