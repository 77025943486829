import { gql } from '@apollo/client'

export const GET_NFT_ATTRIBUTES = gql`
  query getNFTAttributes($pkh: String!, $tokenId: Int!) {
    getNFTAttributes(pkh: $pkh, tokenId: $tokenId) {
      id
      title
      value
      percentage
    }
  }
`
