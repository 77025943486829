import { Nav, NavItem } from '@stakenow/design-system'
import Link from 'next/link'
import { isEmpty, map } from 'ramda'
import React, { FC } from 'react'

import { useActiveLink } from '../../common/activeLink'
import { useTranslation } from '../../common/i18n'
import { NAVIGATION } from './constants'

interface NavItemProps {
  href: string
  name: string
  icon?: JSX.Element
}

const NavLink: FC<NavItemProps> = ({ href, name, icon = <></> }) => {
  const { isParentActive: isActive } = useActiveLink(href)
  const { t } = useTranslation('Navigation')

  const iconElement = !isEmpty(icon) ? (
    <div className={`${isActive ? 'text-orange-600' : null} flex-shrink-0 mr-4`}>{icon}</div>
  ) : null

  return (
    <NavItem active={isActive}>
      <Link href={href}>
        <div className="w-full py-4 px-5">
          {iconElement}
          <p className="text-base font-medium">{t(name)}</p>
        </div>
      </Link>
    </NavItem>
  )
}

const Navigation = () => (
  <Nav>
    {map(({ href, name, icon }: { href: string; name: string; icon: JSX.Element }) => (
      <NavLink key={href} href={href} name={name} icon={icon} />
    ))(NAVIGATION)}
  </Nav>
)

export default Navigation
