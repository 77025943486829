import { PFPContract, Platform } from '../types'

export const MUTEZ = 1000000
export const MUTEZ_DECIMALS = 6

export const ROUTES = {
  HOME: '/',
  DASHBOARD: {
    HOME: '/dashboard',
    NFTS: '/dashboard/nfts',
    NFTS_STATISTICS: '/dashboard/nfts/statistics',
    NFT: '/dashboard/nft/[id]',
    NFTS_COLLECTION: '/dashboard/nfts/collection/[id]',
    NFTS_COLLECTION_ACTIVITY: '/dashboard/nfts/collection/[id]/activity',
    OPERATIONS: '/dashboard/operations',
    VAULTS: '/dashboard/vaults',
    VAULT: '/dashboard/vault/[platform]/[address]',
    POOLS: '/dashboard/pools',
    POOL: '/dashboard/pool/[exchangeId]',
    NO_ACCESS: '/dashboard/no-access',
  },
}

export const PUBLIC_ROUTES = [ROUTES.HOME]
export const PROTECTED_ROUTES = [
  ROUTES.DASHBOARD.HOME,
  ROUTES.DASHBOARD.OPERATIONS,
  ROUTES.DASHBOARD.NFTS,
  ROUTES.DASHBOARD.NFTS_STATISTICS,
  ROUTES.DASHBOARD.NFT,
  ROUTES.DASHBOARD.NFTS_COLLECTION,
  ROUTES.DASHBOARD.NFTS_COLLECTION_ACTIVITY,
  ROUTES.DASHBOARD.VAULTS,
  ROUTES.DASHBOARD.VAULT,
  ROUTES.DASHBOARD.POOLS,
  ROUTES.DASHBOARD.POOL,
]

export const DYNAMIC_ROUTES = [
  ROUTES.DASHBOARD.NFT,
  ROUTES.DASHBOARD.POOL,
  ROUTES.DASHBOARD.VAULT,
  ROUTES.DASHBOARD.NFTS_COLLECTION,
  ROUTES.DASHBOARD.NFTS_COLLECTION_ACTIVITY,
]

export const ALLOWED_BAKERS = [
  'tz1g8vkmcde6sWKaG2NN9WKzCkDM6Rziq194', // StakeNow
  'tz1aRoaRhSpRYvFdyvgWLL6TGyRoGF51wDjM', // Everstake
  'tz1MJx9vhaNRSimcuXPK2rW4fLccQnDAnVKJ', // Airgap
  'tz1abmz7jiCV2GH2u81LRrGgAFFgvQgiDiaf', // Tesselated Geometry
]

export const TZKT_URL = 'https://tzkt.io/'

export const DISCORD_URL = 'https://bit.ly/3rBygWY'

export const ACCESS_NFTS_URL = 'https://objkt.com/asset/KT1G5v7LfnZKRQhifjhdmusEKcVmupEhZ4F3/0'

export const PFP_CONTRACT_URLS = {
  [PFPContract.GOGOS]: 'https://gogos.tez.page',
  [PFPContract.NEONZ]: 'https://www.neonz.xyz',
  [PFPContract.PRJKTNEON]: 'https://www.prjktneon.xyz',
  [PFPContract.KUMULUS]: 'https://www.kumulus-project.com',
  [PFPContract.COMMON_SKELES]: 'https://commonskeles.xyz',
  [PFPContract.TEZZARDS]: 'https://www.tezzardz.xyz',
  [PFPContract.DISTRIBUTED_CONSCIOUSNESS]: 'https://www.memo.tv/works/distributed-consciousness',
  [PFPContract.OTTEZ]: 'https://www.ottez.xyz',
  [PFPContract.OTTEZ_EVOLUTION]: 'https://www.ottez.xyz',
  [PFPContract.OTTEZ_EXTRA]: 'https://www.ottez.xyz',
  [PFPContract.OTTEZ_OYSTERS]: 'https://www.ottez.xyz',
  [PFPContract.ZIGGURATS]: 'https://ziggurats.xyz',
  [PFPContract.BIG_FOOT_MAFIA_CLUB]: 'https://www.bigfootmafiaclub.com/',
  [PFPContract.FROGGOS]: 'https://froggos.xyz',
  [PFPContract.TEZ_APE_GANG]: 'https://objkt.com/asset/::CONTRACT_ADDRESS/::TOKEN_ID',
  [PFPContract.CHOP_SUMO]: 'https://chopsumo.xyz',
  [PFPContract.PAZLAND]: 'https://objkt.com/asset/::CONTRACT_ADDRESS/::TOKEN_ID',
  [PFPContract.CYBER_GECKO_GANG]: 'https://cybergeckogang.xyz',
  [PFPContract.TEZOS_NINJA_TROOPERS]: 'https://objkt.com/asset/::CONTRACT_ADDRESS/::TOKEN_ID',
  [PFPContract.CYBER_KIDS_CLUB]: 'https://cyberkidzclub.xyz',
  [PFPContract.PIXEL_PANDA]: 'https://objkt.com/asset/::CONTRACT_ADDRESS/::TOKEN_ID',
}

export const PLATFORM_URLS = {
  [Platform.HEN]: 'https://teia.art/objkt/::TOKEN_ID',
  [Platform.OBJKT]: 'https://objkt.com/asset/::CONTRACT_ADDRESS/::TOKEN_ID',
  [Platform.PFP]: PFP_CONTRACT_URLS,
  [Platform.FxHash]: 'https://fxhash.xyz/gentk/::TOKEN_ID',
  [Platform.Versum]: 'https://versum.xyz/token/versum/::TOKEN_ID',
  [Platform.InterpopComics]: 'https://minterpop.com/::CONTRACT_ADDRESS/object/::TOKEN_ID',
  [Platform.EightBidou]: 'https://8bidou.com/item/?id=::TOKEN_ID',
  [Platform.TezosDomains]: 'https://tezos.domains/domain/::TOKEN_ID',
  [Platform.Kalamint]: 'https://kalamint.io/::CONTRACT_ADDRESS/token/::TOKEN_ID',
  [Platform.Rarible]: 'https://rarible.com/token/tezos/::CONTRACT_ADDRESS:::TOKEN_ID',
  [Platform.Kolibri]: 'https://kolibri.finance',
  [Platform.TypedArt]: 'https://typed.art/::TOKEN_ID',
  [Platform.ManchesterUnited]: 'https://collectibles.manutd.com',
}

export const FUNGIBLE_TOKEN_CONTRACTS_TO_EXCLUDE = 'KT1AafHA1C1vk959wvHWBispY9Y2f3fxBUUo'
