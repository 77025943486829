import { useReactiveVar } from '@apollo/client'
import { Button, Heading, useClipboard } from '@stakenow/design-system'
import React, { FC } from 'react'

import { makeAccountVar } from '../../../common/graphql'
import { useTranslation } from '../../../common/i18n'
import { useSignin } from '../../../common/signin'
import { useModal } from '../../Modal'

const WalletModal: FC = () => {
  const { signout } = useSignin()
  const { closeModal } = useModal()
  const { isCopied, copyToClipboard } = useClipboard()
  const { pkh } = useReactiveVar(makeAccountVar)

  const { t } = useTranslation('WalletModal')

  const onSignout = () => {
    closeModal()
  }

  return (
    <div className="w-full md:max-w-4xl lg:max-w-7xl">
      <Heading importance="h2">{t('[Heading] wallet')}</Heading>
      <div className="flex flex-col items-stretch justify-stretch gap-y-6">
        <div>
          <button
            type="button"
            className="bg-gray-100 dark:bg-gray-800 text-black dark:text-white rounded-full py-3 px-4 md:py-6 md:px-8 text-center text-xs md:text-xl md:font-bold tracking-wider flex gap-x-1 md:gap-x-3 items-center w-full md:w-auto flex justify-center"
            onClick={() => copyToClipboard(pkh as string)}
          >
            <span className="truncate flex-1">{pkh as string}</span>
            <span className={isCopied ? 'text-green-600' : 'text-gray-500 hover:text-white'}>
              {isCopied ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              )}
            </span>
          </button>
        </div>
        <Button onClick={() => signout(onSignout)}>{t('[Button] disconnect')}</Button>
      </div>
    </div>
  )
}

export default WalletModal
