import { Tab, Tabs } from '@stakenow/design-system'
import Link from 'next/link'
import { map } from 'ramda'
import React, { FC } from 'react'

import { useActiveLink } from '../../../common/activeLink/'
import { useTranslation } from '../../../common/i18n'
import { NFTS_SUBNAVIGATION } from './constants'

interface TabProps {
  href: string
  name: string
}

const NFTsSubnavigation: FC = () => {
  const TabLink: FC<TabProps> = ({ href, name }) => {
    const { t } = useTranslation('NFTsSubnavigation')
    const { isChildActive } = useActiveLink(href)

    return (
      <Tab active={isChildActive}>
        <Link href={href}>{t(name)}</Link>
      </Tab>
    )
  }

  return (
    <div className="mb-5">
      <Tabs>
        {map(({ name, href }: { name: string; href: string }) => <TabLink key={href} href={href} name={name} />)(
          NFTS_SUBNAVIGATION,
        )}
      </Tabs>
    </div>
  )
}

export default NFTsSubnavigation
