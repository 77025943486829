import { Card, Heading, HeadingWithTooltip, Tooltip, formatPercentage, formatUSD } from '@stakenow/design-system'
import { FC } from 'react'

import { useTranslation } from '../../../common/i18n'

interface VaultsTotalsProps {
  outstandingTokensValue: number
  collateralValue: number
  netValue: number
  highestUtilization: number
}

const VaultsTotals: FC<VaultsTotalsProps> = ({
  outstandingTokensValue,
  collateralValue,
  netValue,
  highestUtilization,
}) => {
  const { t } = useTranslation('Vaults')
  return (
    <>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] collateral value')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] collateral value')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{formatUSD(collateralValue)}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] outstanding debt')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] outstanding debt')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{formatUSD(outstandingTokensValue)}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] net value')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] net value')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{formatUSD(netValue)}</h2>
        </div>
      </Card>
      <Card>
        <HeadingWithTooltip
          tooltip={<Tooltip>{t('[Tooltip] highest utilization')}</Tooltip>}
          heading={<Heading importance="h4">{t('[Heading] highest utilization')}</Heading>}
        />
        <div className="flex items-end justify-between">
          <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{formatPercentage(highestUtilization)}</h2>
        </div>
      </Card>
    </>
  )
}

export default VaultsTotals
