import { DocumentNode, NetworkStatus, useQuery } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { isDate, parseISO } from 'date-fns'
import { equals, forEach, is, pathOr, prop } from 'ramda'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { GraphQlOperationName, OrderBy } from '../types'
import { getLastWeeksDate, getTodaysDate, getWeekBeforeStartDateFrom, getYesterdaysDate } from '../utils'
import { formatFilterOptions, formatSelectedSortingOptions } from './infiniteLoading.utils'

const getOperationVariables = (
  operation: GraphQlOperationName,
  nextToken?: number | string,
  orderBy?: OrderBy,
  id?: number,
) => {
  const variablesMap = {
    [GraphQlOperationName.none]: {},
    [GraphQlOperationName.getCollection]: {
      collectionId: id,
    },
    [GraphQlOperationName.getCollectionHistory]: {
      collectionId: id,
    },
    [GraphQlOperationName.getOwned]: formatSelectedSortingOptions(orderBy),
    [GraphQlOperationName.getNFTHistory]: {
      tokenId: id,
    },
    [GraphQlOperationName.getOperations]: {
      start:
        isDate(parseISO(nextToken as any)) && is(String, nextToken)
          ? getWeekBeforeStartDateFrom(nextToken as string)
          : getLastWeeksDate(),
      end:
        isDate(parseISO(nextToken as any)) && is(String, nextToken)
          ? getYesterdaysDate(nextToken as string)
          : getTodaysDate(),
    },
  }

  return variablesMap[operation]
}

export const useInfiniteLoading = (
  graphql: DocumentNode,
  limit?: number,
  id?: number,
  orderBy?: OrderBy,
  filters: Record<string, any>[] = [],
) => {
  const operationName = getOperationName(graphql) || ''

  const {
    loading: isLoading,
    data,
    error,
    fetchMore,
    networkStatus,
    refetch,
  } = useQuery(graphql, {
    variables: {
      limit,
      ...getOperationVariables(operationName as GraphQlOperationName, undefined, orderBy, id),
      ...formatFilterOptions(filters),
    },
    notifyOnNetworkStatusChange: true,
  })

  if (error) {
    forEach((message: string[]) => equals(message)('ExecutionTimeout') && refetch({ refetch: true }))(
      prop('graphQLErrors')(error),
    )
  }

  const { ref, inView } = useInView({
    threshold: 1.0,
  })

  const items = pathOr([], [operationName, 'items'])(data)
  const nextToken = pathOr(null, [operationName, 'nextToken'])(data)

  useEffect(() => {
    if (inView && !equals(networkStatus, NetworkStatus.fetchMore) && nextToken) {
      fetchMore({
        variables: {
          nextToken,
          ...getOperationVariables(operationName as GraphQlOperationName, nextToken, orderBy, id),
          ...formatFilterOptions(filters),
        },
      })
    }
  }, [inView])

  return { items, isLoading, ref }
}
