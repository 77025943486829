import gql from 'graphql-tag'

export const GET_VAULTS = gql`
  query getVaults($pkh: String!) {
    getVaults(pkh: $pkh) {
      vaults {
        platform
        symbol
        apy
        vaults {
          ovenAddress
          ovenOwner
          baker
          balance
          borrowedTokens
          borrowedTokensValue
          collateralValue
          isLiquidated
          liquidationPrice
          outstandingTokens
          outstandingTokensValue
          stabilityFees
          stabilityFeesValue
          utilization
        }
      }
      summary {
        outstandingTokensValue
        collateralValue
        netValue
        highestUtilization
      }
    }
  }
`
