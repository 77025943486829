import { gql } from '@apollo/client'

export const getNFTDetailFragment = (typename: string) => gql`
  fragment NFTDetail on ${typename} {
    id
    quantity
    supply
    collection
    collectionId
    title
    description
    artifactUri
    artifactContent
    displayUri
    thumbnailUri
    mime
    royalties
    mintedOn
    creators
    buyer
    contractId
    boughtFor
    estimatedValue
    platform
    platformId
    platformTokenId
    symbol
    sellOffer
    royaltiesSplit
    minterId
    version
    quantity
  }
`
