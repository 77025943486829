import { load, trackPageview } from 'fathom-client'
import { NextRouter } from 'next/router'

export const init = (router: NextRouter) => {
  load(process.env.NEXT_PUBLIC_FATHOM_SITE_ID || '', {
    url: process.env.NEXT_PUBLIC_FATHOM_SCRIPT_URL,
    includedDomains: [process.env.NEXT_PUBLIC_FATHOM_SITE_URL || ''],
  })

  const onRouteChangeComplete = () => trackPageview()

  router.events.on('routeChangeComplete', onRouteChangeComplete)

  return () => {
    router.events.off('routeChangeComplete', onRouteChangeComplete)
  }
}
