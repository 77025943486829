import { Card, Table, TableBody } from '@stakenow/design-system'
import { equals, map, path, prop } from 'ramda'
import React from 'react'
import { useInView } from 'react-intersection-observer'

import { useTranslation } from '../../../common/i18n'
import { Operation, OperationType } from '../../../common/types'
import { Operation as OperationRow } from '../../Operations/Operation'
import OPERATIONS from './Operations.data.json'

const Operations = () => {
  const { t } = useTranslation('Operations')

  const { ref: refOperationsSection, inView: inViewOperationsSection } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const { ref: refOperationsEnd, inView: inViewOperationsEnd } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <section className="px-6 sm:px-6 lg:px-8 py-20 bg-white dark:bg-gray-800 relative z-10">
      <div className="container mx-auto my-auto relative">
        <div className="grid lg:grid-cols-10 relative z-10 items-center">
          <div
            className={`${
              inViewOperationsSection ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-40'
            } delay-200 order-first transition transform lg:col-span-5 mb-10 lg:mb-0`}
          >
            <div ref={refOperationsSection} />
            <div className="sm:text-center lg:text-left">
              <h1 className="text-3xl tracking-wide font-extrabold text-gray-900 dark:text-white sm:text-4xl md:text-6xl">
                {t('[Heading] transaction history')}
              </h1>
              <p className="mt-3 leading-loose tracking-wide text-base text-gray-500 dark:text-gray-400 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {t('[Content] transaction history')}
              </p>
            </div>
          </div>
          <div
            className={`${
              inViewOperationsEnd ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-40'
            } order-last transition transform duration-500 lg:col-span-4 lg:col-start-7`}
          >
            <Card>
              <div className="flex justify-between">
                <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[Heading] latest transactions')}</h4>
                <p className="text-white rounded-full bg-orange text-xs px-5 py-1">
                  {t('[Button] download tax report')}
                </p>
              </div>
              <ul>
                {map((operation: Operation) =>
                  !equals(OperationType.delegation)(path(['operations', 0, 'type'])(operation)) ? (
                    <li
                      className="border-b px-2 mr-2 border-gray-200 dark:border-gray-600"
                      key={prop('hash')(operation)}
                    >
                      <div className="pointer-events-none">
                        <OperationRow pkh={'PKH' as string} operation={operation} />
                      </div>
                    </li>
                  ) : null,
                )(OPERATIONS)}
              </ul>
            </Card>
          </div>
        </div>
      </div>
      <div ref={refOperationsEnd} className="absolute bottom-0" />
    </section>
  )
}

export default Operations
