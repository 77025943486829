import { InMemoryCache, gql, makeVar } from '@apollo/client'

import { getActiveAccount } from '../beaconWallet/beaconClient'
import { GraphQlOperationName } from '../types'
import { mergeQueryResults } from './utils'

const accountData = {
  pkh: getActiveAccount(),
}

export const makeAccountVar = makeVar(accountData)

export const GET_PKH = gql`
  query GetPKH {
    account @client {
      pkh
    }
  }
`

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        account: {
          read: () => makeAccountVar(),
        },
        getAccount: {
          merge: true,
        },
        getCollection: {
          keyArgs: ['collectionId'],
          merge: mergeQueryResults,
        },
        getCollectionHistory: {
          keyArgs: ['collectionId'],
          merge: mergeQueryResults,
        },
        getOwned: {
          keyArgs: false,
          merge: mergeQueryResults,
        },
        getNFTHistory: {
          keyArgs: ['tokenId'],
          merge: mergeQueryResults,
        },
        getFungibleTokens: {
          keyArgs: ['name'],
          merge: true,
        },
        getOperations: {
          keyArgs: ['hash'],
          merge: mergeQueryResults,
        },
      },
    },
  },
})

export const clearOperationCache = (fieldName: GraphQlOperationName) => cache.evict({ id: 'ROOT_QUERY', fieldName })
