import React, { FC } from 'react'
import { useInView } from 'react-intersection-observer'

import { useTranslation } from '../../../common/i18n'
import { Swap } from './Swap'
import { Transfer } from './Transfer'

const Join: FC = () => {
  const { t } = useTranslation('Manage')

  const { ref: refManageSection, inView: inViewManageSection } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <section className="px-6 sm:px-6 lg:px-8 py-20 bg-white dark:bg-gray-800 relative z-10">
      <div className="container mx-auto my-auto relative">
        <div ref={refManageSection} className="absolute bottom-0 top-0 h-full z-0" />
        <div className="grid lg:grid-cols-2 xl:grid-cols-5 gap-8 relative z-10 items-start">
          <div
            className={`${
              inViewManageSection ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-40'
            } transition transform duration-500 xl:col-span-2`}
          >
            <div className="sm:text-center md:text-left mt-14 mb-10 sm:mb-10 md:mb-20 md:pb-6">
              <h1 className="text-3xl tracking-wide font-extrabold text-gray-900 dark:text-white sm:text-4xl md:text-6xl">
                {t('[Heading] manage your portfolio')}
              </h1>
              <p className="mt-3 leading-loose tracking-wide text-base text-gray-500 dark:text-gray-400 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {t('[Content] manage your portfolio')}
              </p>
            </div>
            <div
              className={`${
                inViewManageSection ? 'opacity-100 translate-y-0 delay-400' : 'opacity-0 translate-y-40'
              } transition transform xl:translate-x-24 md:mt-10`}
            >
              <Transfer />
            </div>
          </div>
          <div
            className={`${
              inViewManageSection ? 'opacity-100 translate-y-0 delay-300' : 'opacity-0 translate-y-40'
            } transition transform duration-500 xl:col-span-2 xl:col-start-4`}
          >
            <div className="transition transform xl:-translate-x-40 relative z-10">
              <Swap />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Join
