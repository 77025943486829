import {
  always,
  compose,
  either,
  evolve,
  filter,
  head,
  ifElse,
  is,
  isEmpty,
  isNil,
  last,
  map,
  mergeAll,
  not,
  objOf,
  pipe,
  reject,
  split,
  toUpper,
  when,
} from 'ramda'

import { OrderBy } from '../types'

export const formatSelectedSortingOptions = (orderBy: OrderBy = OrderBy.default) =>
  !isEmpty(orderBy) ? { orderBy: pipe(split('_'), head)(orderBy), order: pipe(split('_'), last, toUpper)(orderBy) } : {}

export const removePropertiesWithNullValues = (x: Record<string, any>[]) =>
  pipe(reject(either(isNil, isEmpty)), map(when(is(Object), removePropertiesWithNullValues)))(x)

export const formatFilterOptions = pipe(
  removePropertiesWithNullValues,
  map(reject(either(isNil, isEmpty))),
  filter(compose(not, isEmpty)),
  mergeAll,
  ifElse(compose(not, isEmpty), pipe(objOf('filter'), evolve({ filter: JSON.stringify })), always({})),
)
