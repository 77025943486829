import { useQuery } from '@apollo/client'
import {
  Card,
  Heading,
  HeadingWithTooltip,
  LoadingIndicator,
  Tooltip,
  UpDown,
  calculateProfitPercentage,
  formatPercentage,
  formatUSD,
} from '@stakenow/design-system'
import Router from 'next/router'
import { pathOr } from 'ramda'
import React, { FC } from 'react'

import { ROUTES } from '../../../common/constants'
import { useTranslation } from '../../../common/i18n'
import { GET_POOLS_WIDGET } from './PoolsWidget.gql'

const PoolsWidget: FC = () => {
  const { loading: isLoading, data } = useQuery(GET_POOLS_WIDGET)
  const { currentValue, hodlValue, providedValue, weightedAverageApy } = pathOr({}, ['getPools', 'summary'])(data)
  const { t } = useTranslation('PoolsWidget')

  if (isLoading) {
    return <LoadingIndicator />
  }

  return currentValue ? (
    <Card>
      <div className="flex justify-between items-end pb-2">
        <Heading importance="h4">{t('[Heading] pools')}</Heading>
        <button
          type="button"
          className="text-orange text-xs underline"
          onClick={() => Router.push(ROUTES.DASHBOARD.POOLS)}
        >
          {t('[Button] view all')}
        </button>
      </div>
      <div className="grid sm:grid-cols-2 mt-2 gap-5 sm:gap-6">
        <div>
          <h4 className="text-gray-400 dark:text-gray-500 text-xs pb-2 leading-none tracking-wide">
            {t('[Heading] current value')}
          </h4>
          <div className="flex items-end gap-x-4 gap-y-1 flex-wrap">
            <h2 className="text-3xl font-bold whitespace-nowrap">{formatUSD(currentValue)}</h2>
          </div>
        </div>
        <div>
          <h4 className="text-gray-400 dark:text-gray-500 text-xs pb-2 leading-none tracking-wide">
            {t('[Heading] hodl value')}
          </h4>
          <div className="flex items-end gap-x-4 gap-y-1 flex-wrap">
            <h2 className="text-3xl font-bold whitespace-nowrap">{formatUSD(hodlValue)}</h2>
          </div>
        </div>
        <div>
          <h4 className="text-gray-400 dark:text-gray-500 text-xs pb-2 leading-none tracking-wide">
            {t('[Heading] provided value')}
          </h4>
          <h2 className="text-3xl font-bold whitespace-nowrap">{formatUSD(providedValue)}</h2>
        </div>
        <div>
          <h4 className="text-gray-400 dark:text-gray-500 text-xs pb-2 leading-none tracking-wide">
            {t('[Heading] weighted average apy')}
          </h4>
          <div>
            <h2 className="text-3xl font-bold whitespace-nowrap">{formatPercentage(weightedAverageApy)}</h2>
          </div>
        </div>
      </div>
    </Card>
  ) : null
}

export default PoolsWidget
