import {
  Card,
  Heading,
  NFTOwnedCard,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  XTZ,
  formatUSD,
} from '@stakenow/design-system'
import { map, multiply, prop } from 'ramda'
import React, { FC } from 'react'
import { useInView } from 'react-intersection-observer'

import { useTranslation } from '../../../common/i18n'
import { MimeType, Platform } from '../../../common/types'
import { formatAmount } from '../../../common/utils'
import { ipfsToHttps } from '../../../common/utils/utils'
import { NFTRenderer } from '../../NFTRenderer'
import { Wallet } from '../../Wallet'
import HeaderData from './Header.data.json'

const Header: FC = () => {
  const { t } = useTranslation('Header')
  const { tokens, cards } = HeaderData
  const { tokens: fungibleTokens, balances } = prop('fungibleTokens')(HeaderData)
  const { ref: refHeaderSection, inView: inViewHeaderSection } = useInView({
    threshold: 1,
    delay: 500,
    triggerOnce: true,
  })

  const { ref: refHeaderEnd, inView: inViewHeaderEnd } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const { ref: refNFTSection, inView: inViewNFTSection } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const { ref: refNFTEnd, inView: inViewNFTEnd } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const { ref: refTokensSection, inView: inViewTokensSection } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const { ref: refTokensEnd, inView: inViewTokensEnd } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const Token = (index: number) => (
    <img src={ipfsToHttps(tokens[index]) as any} alt="token" className="w-14 h-14 rounded-full" />
  )

  const NFTCardGrid = ({
    title,
    artifactUri,
    mime,
    avatarUri,
    creatorAddress,
    supply,
    estimatedValue,
    price,
    platform,
    artifactContent,
  }: {
    title: string
    artifactUri: string
    mime: MimeType
    avatarUri: string
    creatorAddress: string
    supply: string
    estimatedValue: number
    price: number
    platform: Platform
    artifactContent: string
  }) => {
    return (
      <NFTOwnedCard
        title={title}
        artifact={
          <NFTRenderer
            artifactUri={artifactUri}
            mime={mime}
            title={title}
            platform={platform}
            artifactContent={artifactContent}
          />
        }
        avatarUri={ipfsToHttps(avatarUri)}
        creatorAddress={creatorAddress}
        quantity={1}
        supply={supply}
        estimatedValue={estimatedValue}
        price={price}
        platform={platform}
      />
    )
  }

  return (
    <>
      <section className="relative pt-28 pb-12 px-4 sm:px-6 lg:px-8 min-h-screen flex items-center">
        <div className="container mx-auto px-4 relative z-20">
          <div className="">
            <div className="max-w-5xl mx-auto flex flex-col">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-7xl leading-thight tracking-wide font-bold text-gray-900 dark:text-white sm:text-8xl md:text-9xl">
                  <span className="md:block">{t('[Heading] lost track')}</span>{' '}
                  <span className="md:block md:text-center">{t('[Heading] of your')}</span>{' '}
                  <span className="md:block md:text-right">{t('[Heading] tezos portfolio')}</span>
                </h1>
              </div>
              <div
                className={`${
                  inViewHeaderSection ? 'translate-x-0 opacity-100' : 'translate-x-40 opacity-0'
                } transition transform text-center md:text-center mx-auto mt-20 px-8`}
              >
                <p className="leading-loose tracking-wide text-lg font-light text-gray-500 dark:text-gray-400 sm:mt-5 sm:text-xl sm:max-w-2xl sm:mx-auto md:mb-8 md:text-3xl lg:text-4xl lg:mx-0 align-right mb-6 sm:mb-8 lg:mb-10">
                  {t('[Content] lost track of your tezos portfolio')}
                </p>
                <Wallet />
              </div>
            </div>
          </div>
        </div>
        <div ref={refHeaderSection} className="absolute -bottom-0.5 h-full md:h-px" />
        <div ref={refHeaderEnd} className="absolute -bottom-32 md:-bottom-60" />
      </section>
      <section className="overflow-hidden sm:overflow-visible max-w-screen relative z-10 px-6 sm:px-6 lg:px-8 py-20">
        <div className="container mx-auto my-auto relative z-10">
          <div
            className={`${
              inViewNFTSection
                ? 'opacity-100 translate-y-0 delay-400'
                : inViewNFTEnd
                ? 'opacity-100 translate-y-0 delay-400'
                : 'opacity-0 translate-y-40'
            } transition transform md:col-span-4 xl:col-span-full`}
          >
            <div className="text-center sticky top-0 pt-12 max-w-4xl mx-auto">
              <h1 className="text-3xl tracking-wide font-extrabold text-gray-900 dark:text-white sm:text-4xl md:text-6xl">
                {t('[Heading] all your nfts together')}
              </h1>
              <p className="mt-3 leading-loose tracking-wide text-base text-gray-500 dark:text-gray-400 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {t('[Content] all your nfts together')}
              </p>
            </div>
          </div>
          <div ref={refNFTSection} />
          <div ref={refNFTEnd} />
        </div>
        <div
          className={`${
            inViewNFTSection
              ? inViewNFTEnd
                ? 'opacity-100'
                : inViewNFTSection
                ? 'fixed opacity-100 translate-y-0'
                : ''
              : inViewNFTEnd
              ? ''
              : inViewHeaderEnd
              ? 'fixed opacity-0 translate-y-60 duration-700 top-80'
              : 'fixed opacity-100 top-80'
          } duration-500 delay-0 transition transform md:w-full flex flex-col`}
        >
          <div className={`${inViewTokensEnd && !inViewNFTEnd ? 'opacity-0' : ''} container mx-auto my-12`}>
            <div
              className={`${
                inViewNFTEnd ? 'z-0 opacity-100 max-w-7xl mx-auto' : 'z-0 opacity-40'
              } grid sm:grid-cols-2 md:grid-cols-4 gap-4 transition transform`}
            >
              <div
                className={`${
                  inViewNFTEnd ? 'delay-500' : 'transform scale-75 -rotate-12 filter blur-sm opacity-90 '
                } transition duration-300`}
              >
                {NFTCardGrid(prop(0)(cards))}
              </div>
              <div
                className={`${
                  inViewNFTEnd
                    ? 'delay-500'
                    : 'transform scale-110 relative z-20 translate-y-24 -translate-x-10 filter drop-shadow-2xl'
                } transition duration-300 hidden sm:block`}
              >
                {NFTCardGrid(prop(1)(cards))}
              </div>
              <div
                className={`${
                  inViewNFTEnd
                    ? 'delay-500'
                    : 'transform scale-50 rotate-6 -translate-y-10 translate-x-16 filter blur opacity-80'
                } transition duration-300 hidden md:block`}
              >
                {NFTCardGrid(prop(2)(cards))}
              </div>
              <div
                className={`${
                  inViewNFTEnd
                    ? 'delay-500'
                    : 'transform scale-90 translate-x-32 -translate-y-20 rotate-12 filter blur-xs opacity-95'
                } transition duration-300 hidden md:block`}
              >
                {NFTCardGrid(prop(3)(cards))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center px-6 sm:px-6 lg:px-8 relative py-20">
        <div className="container mx-auto my-auto relative">
          <div className="grid lg:grid-cols-10 relative z-10 items-center">
            <div
              className={`${
                inViewTokensEnd ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-40'
              } order-last lg:order-first transition transform duration-500 lg:col-span-5`}
            >
              <Card>
                <div className="flex items-center justify-between">
                  <Heading importance="h2">{t('[DeFiOverview] balances')}</Heading>
                  <div className="text-right">
                    <Heading importance="h3">
                      <XTZ amount={formatAmount(prop('xtz')(balances))} />
                    </Heading>
                    <span className="text-sm text-gray-600 dark:text-gray-400 font-light">
                      {formatUSD(prop('usd')(balances))}
                    </span>
                  </div>
                </div>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCell extraClasses="pb-0 pl-0">
                        <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[DeFiOverview] asset')}</h4>
                      </TableCell>
                      <TableCell extraClasses="pb-0 text-right">
                        <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[DeFiOverview] balance')}</h4>
                      </TableCell>
                      <TableCell extraClasses="pb-0 text-right">
                        <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[DeFiOverview] price')}</h4>
                      </TableCell>
                      <TableCell extraClasses="pb-0 pr-0 text-right">
                        <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[DeFiOverview] value')}</h4>
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {map(
                      ({
                        name,
                        currentPrice,
                        thumbnailUri,
                        balance,
                        symbol,
                      }: {
                        name: string
                        currentPrice: number
                        thumbnailUri: string
                        balance: number
                        symbol: string
                      }) => (
                        <TableRow key={name}>
                          <TableCell extraClasses="pl-0">
                            <div className="flex items-center">
                              <img src={ipfsToHttps(thumbnailUri)} alt={name} className="w-8 h-8" />
                              <h3 className="text-black dark:text-white text-xl pl-5 font-medium">{symbol}</h3>
                            </div>
                          </TableCell>
                          <TableCell extraClasses="justify-end text-right">
                            {formatAmount(balance)} <span className="hidden md:inline-block">{symbol}</span>
                          </TableCell>
                          <TableCell extraClasses="justify-end text-right">
                            <div className="flex justify-end">
                              <XTZ amount={formatAmount(currentPrice)} />
                            </div>
                          </TableCell>
                          <TableCell extraClasses="justify-end pr-0 text-right">
                            <div className="flex justify-end">
                              <XTZ amount={formatAmount(multiply(balance)(currentPrice))} />
                            </div>
                          </TableCell>
                        </TableRow>
                      ),
                    )(fungibleTokens)}
                  </TableBody>
                </Table>
              </Card>
            </div>
            <div
              className={`${
                inViewTokensSection ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-40'
              } order-first lg:order-last transition transform lg:col-span-4 lg:col-start-7 mb-10 lg:mb-0`}
            >
              <div ref={refTokensSection} />
              <div className="sm:text-center lg:text-left sticky top-0">
                <h1 className="text-3xl tracking-wide font-extrabold text-gray-900 dark:text-white sm:text-4xl md:text-6xl">
                  {t('[Heading] all your tokens in one overview')}
                </h1>
                <p className="mt-3 leading-loose tracking-wide text-base text-gray-500 dark:text-gray-400 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto lg:mt-5 md:text-xl lg:mx-0">
                  {t('[Content] all your tokens in one overview')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div ref={refTokensEnd} className="absolute bottom-0" />
        <div
          className={`${
            inViewTokensEnd
              ? 'absolute w-screen h-screen top-0 translate-y-1/2 sm:translate-y-1/3 translate-x-40'
              : 'fixed w-screen h-screen top-0 left-0'
          } transition transform`}
        >
          <div className={`${inViewTokensEnd ? 'opacity-0 delay-200' : ''} transition-opacity container px-4 mx-auto`}>
            <div
              className={`block transition delay-300 duration-400 ${
                inViewTokensEnd ? 'w-1/2 h-24 relative' : 'w-full h-full'
              }`}
            >
              <div
                className={`${
                  inViewTokensSection
                    ? 'opacity-100'
                    : inViewTokensEnd
                    ? 'opacity-0'
                    : inViewTokensSection
                    ? 'opacity-100'
                    : 'opacity-20'
                } transition-opacity duration-300 absolute top-0 z-10 h-full w-full grid grid-rows-2 grid-cols-4 gap-4 transform -translate-x-40 place-items-center`}
              >
                <div
                  className={`${
                    inViewTokensEnd ? '' : 'transform scale-75 -rotate-12 -translate-x-8'
                  } transition duration-500`}
                >
                  {Token(1)}
                </div>
                <div
                  className={`${
                    inViewTokensEnd
                      ? ''
                      : 'transform scale-110 relative z-10 translate-y-8 -translate-x-10 scale-105 rotate-8 items-end self-end'
                  } transition duration-300`}
                >
                  {Token(2)}
                </div>
                <div
                  className={`${
                    inViewTokensEnd ? '' : 'transform translate-x-10 -translate-y-8 self-start scale-110 rotate-12'
                  } transition duration-300`}
                >
                  {Token(3)}
                </div>
                <div
                  className={`${
                    inViewTokensEnd ? '' : 'transform translate-x-32 translate-y-10 -rotate-12 scale-125'
                  } transition duration-300`}
                >
                  {Token(4)}
                </div>
                <div className={`${inViewTokensEnd ? '' : 'transform scale-75'} transition duration-300`}>
                  {Token(5)}
                </div>
                <div
                  className={`${
                    inViewTokensEnd ? '' : 'transform -translate-x-52 -translate-y-40 rotate-6 scale-50 filter blur-xs'
                  } transition duration-300`}
                >
                  {Token(6)}
                </div>
                <div
                  className={`${
                    inViewTokensEnd ? '' : 'transform -translate-x-4 -translate-y-16 filter blur-xs scale-75'
                  } transition duration-300`}
                >
                  {Token(7)}
                </div>
                <div
                  className={`${
                    inViewTokensEnd ? '' : 'transform -translate-y-20 translate-x-48 rotate-12 scale-90 filter blur-xs'
                  } transition duration-300`}
                >
                  {Token(8)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Header
