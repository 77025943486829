import { propOr } from 'ramda'

import { dAppClient } from '../beaconWallet/beaconClient'
import { ROUTES } from '../constants'
import { graphqlClient, makeAccountVar } from '../graphql'
import { goToUrl } from '../router'
import { REFRESH } from './refresh.gql'
import { getRefreshToken, removeAccessToken, removeRefreshToken, setAccessToken, setRefreshToken } from './signin.utils'

export const requestRefreshToken = () =>
  graphqlClient.mutate({
    mutation: REFRESH,
    variables: {
      refreshToken: getRefreshToken(),
    },
  })

export const setRefreshedTokens = ({ data = {} }) => {
  const { accessToken, refreshToken } = propOr(null, 'refreshAccessToken')(data)
  setAccessToken(accessToken)
  setRefreshToken(refreshToken)

  return accessToken
}

export const logUserOut = () => {
  dAppClient
    .clearActiveAccount()
    .then(() => {
      goToUrl(ROUTES.HOME)
      makeAccountVar({ pkh: null })
      removeAccessToken()
      removeRefreshToken()
      graphqlClient.clearStore()
    })
    .catch(console.log)
}

export const refreshAccessToken: any = () => {
  removeAccessToken()

  return requestRefreshToken()
    .then(setRefreshedTokens)
    .catch(() => logUserOut())
}
