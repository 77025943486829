import gql from 'graphql-tag'

export const GET_POOLS = gql`
  query getPools($pkh: String!) {
    getPools(pkh: $pkh) {
      pools {
        platform
        icon
        pairs {
          pair
          contract
          subsidized
          apy
          currentValue
          hodlValue
          providedValue
        }
      }
      summary {
        currentValue
        providedValue
        hodlValue
        weightedAverageApy
      }
    }
  }
`
