import gql from 'graphql-tag'

export const SIGNIN = gql`
  mutation signin($message: String!, $signature: String!, $pk: String!, $address: String!) {
    signin(message: $message, signature: $signature, pk: $pk, pkh: $address) {
      accessToken
      idToken
      refreshToken
      tokenType
    }
  }
`
