import { Card, Heading, HeadingWithTooltip, Symbol, Tooltip, truncatePKH } from '@stakenow/design-system'
import { prop } from 'ramda'
import { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'
import { Size } from '../../../../common/types'

type Delegator = {
  name: string
  logo?: string
  address: string
}

interface CardDelegateProps {
  delegator?: Delegator
}

const CardDelegate: FC<CardDelegateProps> = ({ delegator }) => {
  const { t } = useTranslation('Vault')
  const name = prop('name')(delegator)
  const logo = prop('logo')(delegator)
  const address = prop('address')(delegator)
  return (
    <Card>
      <HeadingWithTooltip
        tooltip={<Tooltip>{t('[Tooltip] delegation heading')}</Tooltip>}
        heading={<Heading importance="h4">{t('[Heading] delegation')}</Heading>}
      />
      {delegator && (
        <div className="flex items-end justify-between">
          {name && (
            <div className="flex gap-4">
              {logo && <Symbol thumbnailUri={logo} name={name} size={Size.large} />}
              <h2 className="text-2xl lg:text-2xl xl:text-2xl font-bold flex flex-col">
                {name}
                <span className="text-xs font-normal text-gray-400 dark:text-gray-500">({truncatePKH(address)})</span>
              </h2>
              {/* <button
                type="button"
                className={`${prop('address')(delegator) ? 'text-orange text-xs' : 'text-2xl font-bold'} underline pb-1`}
                onClick={() => openModal({ modal: Modal.BakerOverview, data: { contract: ovenAddress } })} // Modal will be implemented in other task
                >
                  {prop('address')(delegator) ? t('[Button] change') : t('[Button] choose')}
              </button> */}
            </div>
          )}
        </div>
      )}
    </Card>
  )
}

export default CardDelegate
