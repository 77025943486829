import { useLazyQuery, useReactiveVar } from '@apollo/client'
import {
  Card,
  Date,
  Heading,
  HeadingWithTooltip,
  LoadingIndicator,
  Tooltip,
  truncatePKH,
} from '@stakenow/design-system'
import { pathOr } from 'ramda'
import React, { FC, useEffect } from 'react'

import { makeAccountVar } from '../../common/graphql'
import { useTranslation } from '../../common/i18n'
import { Modal } from '../../common/modal'
import { useModal } from '../Modal'
import { GET_DELEGATE } from './delegate.gql'

const Delegate: FC = () => {
  const { pkh } = useReactiveVar(makeAccountVar)
  const { openModal } = useModal()
  const [loadDelegate, { loading, data }] = useLazyQuery(GET_DELEGATE)
  const delegate = pathOr(null, ['getAccount', 'delegate'])(data)
  const delegationTime = pathOr(null, ['getAccount', 'delegationTime'])(data)
  const { t } = useTranslation('Delegate')

  useEffect(() => {
    if (pkh) {
      loadDelegate()
    }
  }, [pkh])

  if (loading) {
    return (
      <Card>
        <h4 className="text-gray-400 dark:text-gray-500 text-sm pb-2">{t('[Heading] baker')}</h4>
        <LoadingIndicator />
      </Card>
    )
  }

  const selectBakerButton = (
    <button
      type="button"
      className={`${delegate ? 'text-orange text-xs' : 'text-2xl font-bold'} underline`}
      onClick={() => openModal({ modal: Modal.BakerOverview, data: {} })}
    >
      {delegate ? t('[Button] change') : t('[Button] choose')}
    </button>
  )

  const isDelegated = ({ alias, address }: { alias: string; address: string }) => (
    <>
      <div className="flex flex-wrap items-end justify-between gap-y-1">
        <h2 className="text-3xl font-bold flex flex-wrap items-end gap-y-1 gap-x-3">
          {alias ? (
            <>
              {alias}
              <span className="text-base text-gray-600 dark:text-gray-400 font-normal">({truncatePKH(address)})</span>
            </>
          ) : (
            truncatePKH(address)
          )}
        </h2>
        {selectBakerButton}
      </div>
      <p className="text-sm text-gray-400 dark:text-gray-500 pt-4">
        {delegationTime ? <Date date={delegationTime} /> : null}
      </p>
    </>
  )

  return (
    <Card>
      <HeadingWithTooltip
        tooltip={<Tooltip>{t('[Tooltip] baker')}</Tooltip>}
        heading={<Heading importance="h4">{t('[Heading] baker')}</Heading>}
      />
      {delegate ? isDelegated(delegate) : selectBakerButton}
    </Card>
  )
}

export default Delegate
