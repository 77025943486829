import { mergeDeepRight, reduce } from 'ramda'

import { HeaderTranslation } from '../../Header'
import { JoinTranslation } from '../../Join'
import { ManageTranslation } from '../../Manage'
import { OperationsTranslation } from '../../Operations'
import { PoolOverviewTranslation, PoolsTranslation } from '../../Pools'

const mergeTranslations = reduce(mergeDeepRight, { en: {}, nl: {} })

export const LandingPageTranslations = mergeTranslations([
  HeaderTranslation,
  JoinTranslation,
  ManageTranslation,
  OperationsTranslation,
  PoolsTranslation,
  PoolOverviewTranslation,
])
