import { Card, Heading, HeadingWithTooltip, Tooltip } from '@stakenow/design-system'
import { formatUSD } from '@stakenow/design-system'
import { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'
import { formatAmount } from '../../../../common/utils'

interface CardBorrowProps {
  borrowedTokens: number
  symbol: string
  borrowedTokensValue: number
}

const CardBorrow: FC<CardBorrowProps> = ({ borrowedTokens, symbol, borrowedTokensValue }) => {
  const { t } = useTranslation('Vault')
  return (
    <Card>
      <HeadingWithTooltip
        tooltip={<Tooltip>{t('[Tooltip] outstanding debt')}</Tooltip>}
        heading={<Heading importance="h4">{t('[Heading] outstanding debt')}</Heading>}
      />
      <div className="flex items-end mb-6">
        <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">
          {formatAmount(borrowedTokens)} {symbol}
        </h2>
        <p className="text-sm text-gray-600 dark:text-gray-400 ml-4"> {formatUSD(borrowedTokensValue)}</p>
      </div>
      {/* <div className="flex border-t border-gray-200 dark:border-gray-800 mt">
        <div className="flex gap-6 flex-grow pt-4">
          <button
            type="button"
            onClick={() => openModal({ modal: Modal.default, data: {} })}
            className="text-orange text-xs underline tracking-wide"
          >
            {t('[Button] borrow')}
          </button>
          <button
            type="button"
            onClick={() => openModal({ modal: Modal.default, data: {} })}
            className="text-orange text-xs underline tracking-wide"
          >
            {t('[Button] pay back')}
          </button>
        </div>
      </div> */}
    </Card>
  )
}

export default CardBorrow
