import React, { FC } from 'react'
import { createPortal } from 'react-dom'

import { Size } from '../../common/types'
import { useModal } from './useModal.hook'

const Modal: FC = () => {
  const { isOpen, content, closeModal, ref } = useModal()

  return isOpen && ref
    ? createPortal(
        <div className="fixed z-30 inset-0 overflow-y-auto" role="dialog" aria-modal="true">
          <div className="flex items-center min-h-screen text-center md:block md:px-2 lg:px-4">
            <div
              className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-60 bg-opacity-60 backdrop-filter backdrop-blur-sm transition-opacity block"
              onClick={closeModal}
              aria-hidden="true"
            />
            <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div className="flex text-base text-left transform transition md:inline-block md:px-4 mx-3 my-6 md:mx-0 md:my-8 md:align-middle h-full w-full md:w-auto">
              <div className="w-full relative flex items-center bg-white dark:bg-gray-900 px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-12 rounded-2xl">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8 z-20"
                  onClick={closeModal}
                >
                  &times;
                </button>
                <div className="w-full">{content}</div>
              </div>
            </div>
          </div>
        </div>,
        ref,
      )
    : null
}

export default Modal
