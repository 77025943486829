import { ROUTES } from '../../common/constants'

export const NAVIGATION = [
  {
    href: ROUTES.DASHBOARD.HOME,
    name: '[Navigation] overview',
    icon: '',
  },
  {
    href: ROUTES.DASHBOARD.NFTS,
    name: '[Navigation] nfts',
    icon: '',
  },
  {
    href: ROUTES.DASHBOARD.OPERATIONS,
    name: '[Navigation] operations',
    icon: '',
  },
  {
    href: ROUTES.DASHBOARD.VAULTS,
    name: '[Navigation] vaults',
    icon: '',
  },
  {
    href: ROUTES.DASHBOARD.POOLS,
    name: '[Navigation] pools',
    icon: '',
  },
]
