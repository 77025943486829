import { __, always, concat, equals, ifElse, pipe, prop, replace } from 'ramda'

import { PLATFORM_URLS } from '../../../common/constants'
import { PFPContract, Platform } from '../../../common/types'

export const formatSymbolHeading = concat(__, ' #')

export const formatExternalNFTLink = (contract: string | PFPContract, tokenID: string | number) =>
  pipe(replace('::CONTRACT_ADDRESS', contract), replace('::TOKEN_ID', tokenID))

export const getExternalNFTLink = (contract: string | PFPContract, tokenId: string | number) => (platform: Platform) =>
  pipe(
    ifElse(equals(Platform.PFP), (x: Platform) => prop(contract)(PLATFORM_URLS[x]), always(PLATFORM_URLS[platform])),
    formatExternalNFTLink(contract, tokenId),
  )(platform)
