import {
  Card,
  ExternalLink,
  Heading,
  Symbol,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  VaultRow,
} from '@stakenow/design-system'
import { validateAddress } from '@taquito/utils'
import Router from 'next/router'
import { always, descend, head, ifElse, map, pipe, prop, sort } from 'ramda'
import { FC } from 'react'

import { PLATFORM_URLS, ROUTES } from '../../../common/constants'
import { useTranslation } from '../../../common/i18n'
import { Size } from '../../../common/types'
import { replaceDynamicProperties, verifiedValidAddress } from '../../../common/utils/utils'
import { Vault } from '../types'

interface PlatformProps {
  platform: string
  vaults: Vault[]
}

const Platform: FC<PlatformProps> = ({ platform, vaults }) => {
  const { t } = useTranslation('Vaults')
  return (
    <div className="col-span-full">
      <Card>
        <div className="flex items-center gap-x-4 pb-4">
          <Symbol thumbnailUri={null} name={platform} size={Size.small} />
          {ifElse(
            pipe(head, prop('ovenAddress'), (x: string) => verifiedValidAddress(validateAddress(x))),
            always(<Heading importance="h3">{platform}</Heading>),
            always(
              <ExternalLink
                url={PLATFORM_URLS.Kolibri}
                label={
                  <div className="dark:text-white">
                    <Heading importance="h3">{platform} </Heading>
                  </div>
                }
                size={Size.medium}
              />,
            ),
          )(vaults)}
          <div className="text-right">
            <span className="text-sm text-gray-600 dark:text-gray-400 font-light" />
          </div>
        </div>
        <div className="-mx-3">
          <Table>
            <TableHeader>
              <tr className="hidden md:table-row">
                <TableCell extraClasses="pb-2 pl-3">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] vault')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2 pl-0">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] collateral value')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] outstanding debt')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] net value')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2 pl-0">
                  <h4 className="text-gray-400 dark:text-gray-500 text-sm">{t('[Heading] utilization')}</h4>
                </TableCell>
                <TableCell extraClasses="pb-2 pr-0 text-right">
                  <></>
                </TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {pipe(
                sort(descend(prop('utilization'))),
                map(
                  ({
                    collateralValue,
                    outstandingTokensValue,
                    ovenAddress,
                    utilization,
                  }: {
                    collateralValue: number
                    outstandingTokensValue: number
                    ovenAddress: string
                    utilization: number
                  }) => (
                    <VaultRow
                      key={collateralValue}
                      collateralValue={collateralValue}
                      outstandingValue={outstandingTokensValue}
                      ovenAddress={ovenAddress}
                      utilization={utilization}
                      onClick={() =>
                        Router.push(
                          replaceDynamicProperties(ROUTES.DASHBOARD.VAULT, { platform, address: ovenAddress }),
                        )
                      }
                    />
                  ),
                ),
              )(vaults)}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  )
}
export default Platform
