import { Card, Heading, HeadingWithTooltip, Tooltip, formatUSD, formatXTZ } from '@stakenow/design-system'
import { FC } from 'react'

import { useTranslation } from '../../../../common/i18n'

interface CardCollateralProps {
  balance: number
  collateralValue: number
}

const CardCollateral: FC<CardCollateralProps> = ({ balance, collateralValue }) => {
  const { t } = useTranslation('Vault')
  return (
    <Card>
      <HeadingWithTooltip
        tooltip={<Tooltip>{t('[Tooltip] collateral heading')}</Tooltip>}
        heading={<Heading importance="h4">{t('[Heading] collateral')}</Heading>}
      />
      <div className="flex items-end mb-6">
        <h2 className="text-2xl lg:text-3xl xl:text-3xl font-bold">{formatXTZ(balance)}</h2>
        <p className="text-sm text-gray-600 dark:text-gray-400 ml-4">{formatUSD(collateralValue)}</p>
      </div>
      {/* <div className="flex border-t border-gray-200 dark:border-gray-800 ">
        <div className="flex gap-6 flex-grow pt-4">
          <button
            type="button"
            onClick={() => openModal({ modal: Modal.default, data: {} })}
            className="text-orange text-xs underline tracking-wide"
          >
            {t('[Button] deposit')}
          </button>
          <button
            type="button"
            onClick={() => openModal({ modal: Modal.default, data: {} })}
            className="text-orange text-xs underline tracking-wide"
          >
            {t('[Button] withdraw')}
          </button>
        </div>
      </div> */}
    </Card>
  )
}

export default CardCollateral
