import { useQuery } from '@apollo/client'
import { map, pathOr, pipe, pluck, toPairs } from 'ramda'
import React, { FC } from 'react'

import { GET_NFT_TAG } from './Tags.gql'

interface Tags {
  id: number
}

const Tags: FC<Tags> = ({ id: tokenId }) => {
  const { data } = useQuery(GET_NFT_TAG, { variables: { tokenId: tokenId } })
  const tags = pipe(pathOr([], ['getNFTTags']), pluck('tag'), toPairs)(data)
  return (
    <>
      {map(([index, tag]: [number, string]) => (
        <p key={index} className="text-gray-400 dark:text-gray-500 text-xs">
          {index > 0 && '|'} {tag}
        </p>
      ))(tags)}
    </>
  )
}

export default Tags
