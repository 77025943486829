import gql from 'graphql-tag'

export const GET_OWNED = gql`
  query getOwned(
    $pkh: String!
    $limit: Int
    $nextToken: Int
    $orderBy: String
    $order: String
    $filter: String
    $refetch: Boolean
  ) {
    getOwned(
      pkh: $pkh
      limit: $limit
      nextToken: $nextToken
      orderBy: $orderBy
      order: $order
      filter: $filter
      refetch: $refetch
    ) {
      items {
        id
        quantity
        holderId
        title
        description
        artifactUri
        artifactContent
        collection
        collectionId
        version
        displayUri
        thumbnailUri
        mime
        royalties
        supply
        mintedOn
        creators
        buyer
        contractId
        platform
        platformId
        platformTokenId
        estimatedValue
        floorPrice
        hasRelevantTradeVolume
        boughtFor
        isBuyer
        lastSaleOn
        lastSale
        sellOffer
        estimatedValue
        profitLoss
        symbol
        royaltiesSplit
        minterId
      }
      nextToken
    }
  }
`
