import gql from 'graphql-tag'

export const REFRESH = gql`
  mutation refresh($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`
