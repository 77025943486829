import { useReactiveVar } from '@apollo/client'
import { Wallet } from '@stakenow/design-system'
import React, { FC } from 'react'

import { makeAccountVar } from '../../common/graphql'
import { Modal } from '../../common/modal'
import { useSignin } from '../../common/signin'
import { useModal } from '../Modal'

const BeaconWallet: FC = () => {
  const { error, signin } = useSignin()
  const { pkh } = useReactiveVar(makeAccountVar)
  const { openModal } = useModal()

  if (error) {
    console.log(error)
  }

  return (
    <Wallet
      isConnected={!!pkh}
      address={pkh as string}
      connectButtonOnClick={() => signin()}
      onClick={() => openModal({ modal: Modal.Wallet, data: {} })}
    />
  )
}

export default BeaconWallet
