import { ROUTES } from '../../../common/constants'

export const NFTS_SUBNAVIGATION = [
  {
    href: ROUTES.DASHBOARD.NFTS,
    name: '[Navigation] owned',
  },
  {
    href: ROUTES.DASHBOARD.NFTS_STATISTICS,
    name: '[Navigation] statistics',
  },
]
